import { Outlet, useOutletContext, useLocation } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { layoutCols3, StickySidebar } from '@cais-group/homepage/ui/layout'
import { useMinimumDebouncedState } from '@cais-group/homepage/util/hook/use-minimum-debounced-state'
import {
  trackingLabels,
  useTrackQuickSearch,
  EVENTS,
  getSearchDataToTrack,
} from '@cais-group/homepage/util/tracking'
import { APPS } from '@cais-group/shared/domain/apps'
import { useGetFAQs } from '@cais-group/shared/domain/contentful/faq'
import { useTrack } from '@cais-group/shared/util/segment'

import { Nav } from './nav'
import type { FaqNavProps } from './nav'
import { Search } from './search'

type OutletContext = {
  defaultCategoryId: FaqNavProps['categories'][0]['id']
  faqSearchData: ReturnType<typeof useGetFAQs>
}

export function useFaqCategories() {
  return useOutletContext<OutletContext>()
}

export function FaqsLayout(props: FaqNavProps) {
  const track = useTrack({ app: APPS.HOMEPAGE })

  const location = useLocation()
  const [searchText, setSearchText] = useQueryParam(
    'searchText',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn' }
  )

  const debouncedSearchText = useMinimumDebouncedState(searchText ?? '', 600)
  const faqSearchData = useGetFAQs(debouncedSearchText)

  const { Layout, slots } = layoutCols3()

  const searchQueryKey = '?searchText='
  const onSearchScreen =
    location.search.includes(searchQueryKey) &&
    location.search.length > searchQueryKey.length
  const onIndexPage = location.pathname.endsWith('faqs')

  useTrackQuickSearch(
    {
      items: faqSearchData.pageData?.faqs ?? [],
      search_term: debouncedSearchText,
    },
    () => (debouncedSearchText && !faqSearchData.loading ? true : false),
    track,
    EVENTS.SEARCH_RESULTS_RECEIVED('FAQs')
  )

  const onClearSearch = () => {
    setSearchText('')
    track({
      event: EVENTS.SEARCH_RESULTS_CLEARED('FAQs'),
      data: getSearchDataToTrack({
        items: faqSearchData.pageData?.faqs ?? [],
        search_term: debouncedSearchText,
      }),
    })
  }
  return (
    <Layout
      {...mapTrackingKeysToDataAttributes({
        section: trackingLabels.section.Help,
        sub_section: trackingLabels.subSection.FAQ,
      })}
    >
      <StickySidebar className={slots[0]}>
        <Nav
          categories={props.categories}
          state={onSearchScreen ? 'search' : onIndexPage ? 'index' : 'category'}
        />
      </StickySidebar>
      <div className={`${slots[1]} pb-32`}>
        <Outlet
          context={{ defaultCategoryId: props.categories[0].id, faqSearchData }}
        />
      </div>
      <div className={slots[2]}>
        <Search
          searchText={searchText}
          onSearch={setSearchText}
          onClear={onClearSearch}
        />
      </div>
    </Layout>
  )
}
