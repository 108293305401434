import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import type { TypeVideoDataWithParentPlaylist } from '@cais-group/homepage/domain/contentful'
import { useGetVideo } from '@cais-group/homepage/domain/contentful'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import { APP_URL } from '@cais-group/homepage/util/common'
import {
  GetDataFn,
  WithAugmentedPageTracking,
} from '@cais-group/homepage/util/tracking'
import { ContentfulBreadcrumbs as Breadcrumbs } from '@cais-group/shared/ui/contentful/breadcrumb'
import { VideoPageContainer } from '@cais-group/shared/ui/video/video-page-container'
import { DocumentTitle } from '@cais-group/shared/util/document-title'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'

function findBreadcrumbData(pageData: TypeVideoDataWithParentPlaylist) {
  // Prefer the playlist category and title if they exist
  const playlist = pageData?.linkedFrom?.caisiqVideoPlaylistCollection?.items[0]

  return {
    category: playlist?.category || pageData?.category || '',
    currentPage: playlist?.title || pageData.title || '',
  }
}

export function Videos() {
  const { id } = useParams<{ id: string }>()
  const {
    userProfileState: { userProfile },
  } = useUserProfile()
  const { id: userId } = userProfile || {}
  const { pageData, loading, error, existsWithNoAccess, notFound } =
    useGetVideo(id)

  const getFundProductIds = useCallback(() => {
    if (!pageData) return {}
    return {
      fund_ids: pageData?.fundProductIds?.fundProductIds ?? undefined,
    }
  }, [pageData])

  return (
    <>
      {match({ pageData, error, existsWithNoAccess, notFound })
        .with(
          {
            error: false,
            existsWithNoAccess: false,
            notFound: false,
            pageData: P.not(P.nullish),
          },
          ({ pageData }) => (
            <Breadcrumbs baseUrl={APP_URL} {...findBreadcrumbData(pageData)} />
          )
        )
        .otherwise(() => null)}

      <Main fullWidth>
        {match({ pageData, error, loading, existsWithNoAccess, notFound })
          .with({ error: true, loading: false }, () => (
            <PageErrorSplash.General />
          ))
          .with({ notFound: true, loading: false }, () => (
            <PageErrorSplash.NotReached />
          ))
          .with({ existsWithNoAccess: true, loading: false }, () => (
            <PageErrorSplash.NoAccess />
          ))
          .with({ loading: true, error: false }, () => (
            <LoadingContainer
              state={LoadingState.LOADING}
              type="large"
              coverPage="FULL_SCREEN_WITH_HEADER"
            />
          ))
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title={pageData.title || ''} />
                <WithAugmentedPageTracking
                  getData={getFundProductIds as GetDataFn}
                />
                <VideoPageContainer data={pageData} userId={userId ?? ''} />
              </>
            )
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
