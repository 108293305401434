import cx from 'classnames'
import { debounce } from 'lodash-es'
import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Breadcrumbs,
  BreadcrumbsProps,
} from '@cais-group/equity/atoms/breadcrumbs'
import { Button, ButtonProps } from '@cais-group/equity/atoms/button'
import { Pill } from '@cais-group/equity/atoms/pill'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'

// common transition classes use across the component
const transition = 'transition-all duration-short-2'

type PageHeaderPropsCommon = {
  /** Optional list of buttons shown on the right side (or at the bottom of smaller screens ) */
  actions?: ButtonProps[]
  /** Optional breadcrumbs showing at the top of the title */
  breadcrumbs?: BreadcrumbsProps
  /** Optional tag shown below or at the side the title depending of the screen size or if the header is minified (on scrolling)    */
  statusTag?: StatusTagProps
  /** Allow hiding the back button if not needed */
  backButtonVisibility?: 'visible' | 'invisible' | 'hidden'
  /** Optional callback function to overwrite the default behaviour when the back button is clicked */
  onBack?(): void
  /** Temporary flag to use new layout system, once all screen are using the new layout this property can be removed  */
  applyNewLayout?: boolean
}

type PageHeaderPropsWithTitle = {
  /** @deprecated The main text shown in a h1 tag. Deprecated, use children */
  title: string
  /** Not possible to pass children with title */
  children?: never
} & PageHeaderPropsCommon

type PageHeaderPropsWithChildren = {
  /** The main text shown in a h1 tag. */
  children: ReactNode
  /** Not possible to pass title */
  title?: never
} & PageHeaderPropsCommon

export type PageHeaderProps =
  | PageHeaderPropsWithTitle
  | PageHeaderPropsWithChildren
/**
 * The Page Header component is typically placed on Form pages and performs various actions such as navigation and form submission.
 * It can also be used in dialogs.
 * The header collapses when starting to scroll down and comes back to the initial state when reaching the top while scrolling up.
 *
 * _** Because the header is sticky, it is important the parent container where this component is placed to have a defined height**_
 * _** and the overflow set to auto!!!** (see stories code for an example)_
 */
export const PageHeader = ({
  title,
  children,
  actions = [],
  statusTag,
  breadcrumbs,
  backButtonVisibility = 'visible',
  onBack,
  applyNewLayout,
}: PageHeaderProps) => {
  const myRef = useRef<HTMLElement>(null)

  const navigate = useNavigate()

  const [isCollapsed, setIsCollapsed] = useState(false)
  /**
   * The `topGap` allows to log what is the height of the space between the top of the header component
   * and the top of the parent container (the header may not be the top element, as an example it can have the global nav).
   * This is used to determine when the header should collapse or not (it does only when the header reach the top of the container).
   */
  const [topGap, setTopGap] = useState(0)

  const hasBreadcrumbs = typeof breadcrumbs !== 'undefined'

  useLayoutEffect(() => {
    const header = myRef.current
    const container = header?.parentNode

    const headerRect = header?.getBoundingClientRect()
    const containerRect =
      container instanceof Element ? container?.getBoundingClientRect() : null

    const headerTop = headerRect?.top ?? 0
    const containerTop = containerRect?.top ?? 0

    setTopGap(headerTop - containerTop)
  }, [])

  useLayoutEffect(() => {
    const header = myRef.current
    const container = header?.parentNode

    const handleScroll = debounce(() => {
      const headerRect = header?.getBoundingClientRect()
      const containerScrollTop =
        container instanceof Element ? container?.scrollTop : 0

      const headerHeight = headerRect?.height ?? 0

      if (!isCollapsed && containerScrollTop - topGap > headerHeight) {
        setIsCollapsed(true)
      } else if (isCollapsed && containerScrollTop - topGap <= 0) {
        setIsCollapsed(false)
      }
    }, 10)

    container?.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [hasBreadcrumbs, topGap, isCollapsed])

  return (
    <header
      ref={myRef}
      className={cx(
        'bg-neutral-0 sticky top-0 z-10 flex gap-32 border-0 border-b border-solid border-neutral-200 px-24 md:px-32 lg:px-56',
        transition,
        {
          'py-16': isCollapsed,
          'py-24': !isCollapsed,
        }
      )}
    >
      {backButtonVisibility === 'hidden' ? null : (
        <div
          className={cx('hidden shrink-0 grow-0 items-center', {
            'md:flex md:w-[calc((100%-80px)/6)] xl:w-[207px]': !applyNewLayout, // mimics the old layout until
            'lg:flex lg:w-[191px] xl:w-[287px]': applyNewLayout,
          })}
        >
          {backButtonVisibility === 'invisible' ? null : (
            <Pill
              label="Back"
              color="neutral"
              icon="ArrowBackLrg"
              size="small"
              onClick={onBack ?? (() => navigate(-1))}
            />
          )}
        </div>
      )}

      <div className="flex w-full flex-col justify-center overflow-hidden ">
        {hasBreadcrumbs && (
          <div
            className={cx(
              ' grid [&>*]:mb-4 [&>*]:overflow-hidden',
              transition,
              {
                'grid-rows-[0fr]': isCollapsed,
                'grid-rows-[1fr]': !isCollapsed,
              }
            )}
          >
            <Breadcrumbs {...breadcrumbs} />
          </div>
        )}
        <div className={cx('flex w-full items-center gap-x-16', transition)}>
          <h1
            className={cx('shrink overflow-hidden ', {
              'headline-s-strong md:headline-m-strong': !isCollapsed,
              'body-strong md:headline-s-strong lg:headline-m-strong text-ellipsis whitespace-nowrap':
                isCollapsed,
            })}
            data-testid="page-header-title"
          >
            {children ?? title}
          </h1>
          {statusTag && (
            <div
              className={cx('grid shrink-0 [&>*]:overflow-hidden', transition, {
                'grid-cols-[1fr]': isCollapsed,
                'grid-cols-[0fr] md:grid-cols-[1fr] ': !isCollapsed,
              })}
            >
              <div>
                <StatusTag {...statusTag} />
              </div>
            </div>
          )}
        </div>
        {/* The following status tag (showing bellow the title) is only displayed on mobile screens when the header is not collapsed */}
        {statusTag && (
          <div
            className={cx('grid [&>*]:overflow-hidden', transition, {
              'grid-rows-[0fr]': isCollapsed,
              'grid-rows-[1fr] md:grid-rows-[0fr]': !isCollapsed,
            })}
          >
            <div className=" [&>*]:mt-16">
              <StatusTag {...statusTag} />
            </div>
          </div>
        )}
      </div>
      <div className="bg-neutral-0 shadow-6 lg:shadow-0 fixed bottom-0 left-0 right-0 flex h-[88px] w-full items-center justify-center px-24 md:justify-end lg:relative lg:h-auto lg:w-auto lg:px-0">
        <div className="left-0 flex w-full gap-x-16 md:w-auto">
          {actions.map((action, index) => (
            <Button key={index} {...action} size="regular" grow>
              {action.children}
            </Button>
          ))}
        </div>
      </div>
    </header>
  )
}
