import { ButtonLink } from '@cais-group/equity/atoms/button'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

export type CallToActionProps = Partial<TypeArticleData['callToAction']>

export function CallToAction(props: CallToActionProps) {
  const { title, buttonText, description, destination } = props || {}
  const buttonLink =
    destination?.internalLink || destination?.externalLink || ''

  return (
    <aside className="bg-primary-600 text-neutral-0 flex w-full flex-col justify-center gap-16 p-32 md:flex-row md:items-center md:gap-32">
      <div className="space-y-16">
        <p className="headline-m-strong leading-none">{title}</p>
        <p className="max-w-prose">{description}</p>
      </div>
      {buttonText && buttonLink && (
        <ButtonLink variant="secondary" href={buttonLink}>
          {buttonText}
        </ButtonLink>
      )}
    </aside>
  )
}
