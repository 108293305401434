import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import { useGetGlossary } from '@cais-group/shared/domain/contentful/glossary'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

import { AtoZLayout } from './a-to-z'

export function Glossary() {
  const { pageData, error, loading } = useGetGlossary()

  return (
    <Main>
      {match({ pageData, error, loading })
        .with({ error: true }, () => <PageErrorSplash.General />)
        .with({ loading: true, error: false }, () => (
          <LoadingContainer
            state={LoadingState.LOADING}
            type="large"
            coverPage="FULL_SCREEN_WITH_HEADER"
            label="Loading glossary"
          />
        ))
        .with(
          { error: false, pageData: P.array(P.not(P.nullish)) },
          ({ pageData }) => {
            return (
              <>
                <DocumentTitle title="Glossary" />
                <AtoZLayout list={pageData} prefix="glossary" />
              </>
            )
          }
        )
        .otherwise(() => null)}
    </Main>
  )
}
