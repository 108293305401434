import { DeepNonNullable } from 'ts-essentials'

import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import {
  GetFaQsCategoriesQuery,
  GetFaQsCategoriesQueryVariables,
  FaqCategoryOrder,
  useGetFaQsCategoriesQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export type FAQCategories = DeepNonNullable<
  GetFaQsCategoriesQuery['faqCategoryCollection']
>['items']

export const useGetFAQsCategories = (
  variables?: GetFaQsCategoriesQueryVariables
) => {
  const response = useReactQueryResultAsApiState<
    GetFaQsCategoriesQuery,
    FAQCategories
  >(
    useGetFaQsCategoriesQuery(
      { order: variables?.order || FaqCategoryOrder.SortOrderAsc },
      { refetchOnWindowFocus: false }
    ),
    selectFaQsCategories,
    'Could not load FAQs categories'
  )
  return compileResults(response)
}

const compileResults = (data: FAQCategories | ApiError | ApiStateEnum) => {
  return {
    pageData: isData(data) ? data : null,
    error: isError(data),
    loading: data === ApiStateEnum.LOADING,
  }
}
const selectFaQsCategories = (data: GetFaQsCategoriesQuery) =>
  (data.faqCategoryCollection?.items ?? []) as FAQCategories
