import { useEffect } from 'react'

import {
  useLocationTrackingValues,
  usePageTrack,
} from '@cais-group/shared/util/segment'

export const EVENT_PAGE_VIEWED = 'Page Viewed'

/**
 * Will call Segment's `page` method on every url change manually passing the url, referrer, path, and search properties.
 */
export function usePageTracking() {
  const { app, url, path, search, referrer } = useLocationTrackingValues()
  /**
   * We have to manually keep track of the referrer becuase in SPA's, the referrer is not populated as expected.
   * For more context, see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/single-page-apps/.
   */
  const pageTrack = usePageTrack({ app })

  useEffect(() => {
    const id = setTimeout(() => {
      pageTrack({
        event: EVENT_PAGE_VIEWED,
        data: {
          url,
          referrer,
          path,
        },
      })
      // NOTE: We add a slight delay to allow some time for pages to fetch relevant data if they want
      //      to augment the page event payload. The delay does not affect page rendering so we
      //      can be generous.
    }, 1000)
    return () => clearTimeout(id)
  }, [pageTrack, search, url, referrer, path])
}
