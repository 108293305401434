import cx from 'classnames'

import { SearchInput } from '@cais-group/equity/atoms/text-input'

import { ResultsCount } from './results-count'

const layout = {
  padding: {
    small: '',
    medium: 'pb-24',
    large: 'pb-56',
  },
}

export const HomepageSearchInput = (props: {
  searchText: string
  onSearch: (searchText: string) => void
  count?: number
  size: keyof typeof layout.padding
  controls?: React.ReactNode
}) => {
  const { searchText, onSearch, count, size = 'large', controls } = props
  return (
    <div
      className={cx(
        `flex grid-cols-4 flex-col max-sm:pb-24 md:grid ${layout.padding[size]}`,
        {
          'gap-y-24 md:gap-y-56': controls,
        }
      )}
    >
      <div className="col-span-3">{controls}</div>

      <div className="col-span-1">
        <SearchInput
          size="small"
          value={searchText}
          onChange={(event) => onSearch(event.target.value)}
          onClear={() => onSearch('')}
          placeholder="Search by keyword"
          id="homepage-search-input"
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              onSearch('')
            }
          }}
        />
      </div>

      {count ? <ResultsCount count={count} /> : null}
    </div>
  )
}
