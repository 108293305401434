import { DEFAULT_CMS_CURATED_FIRM_UUID } from '@cais-group/homepage/domain/contentful'
import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  CallToActionFieldsFragment,
  useGetCallToActionByIdAndFirmQuery,
  type GetCallToActionByIdAndFirmQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

type CTAType = CallToActionFieldsFragment

type QueryVariables = {
  id: string
}

export const useGetCallToAction = (variables: QueryVariables) => {
  const { data } = useGetAllowedContentPermissions()

  const curatedResponse = useReactQueryResultAsApiState<
    GetCallToActionByIdAndFirmQuery,
    CTAType
  >(
    useGetCallToActionByIdAndFirmQuery(
      {
        preview: previewService.enabled,
        id: variables.id,
        firms: data.firmIds || [],
      },
      { refetchOnWindowFocus: false }
    ),
    selectCTA,
    'Could not load "getCallToAction query"'
  )

  const defaultResponse = useReactQueryResultAsApiState<
    GetCallToActionByIdAndFirmQuery,
    CTAType
  >(
    useGetCallToActionByIdAndFirmQuery(
      {
        preview: previewService.enabled,
        id: variables.id,
        firms: [DEFAULT_CMS_CURATED_FIRM_UUID],
      },
      { refetchOnWindowFocus: false, enabled: !curatedResponse }
    ),
    selectCTA,
    'Could not load "getCallToAction query"'
  )

  const actualCuratedData = isData(curatedResponse) ? curatedResponse : null
  const hasCuratedData = Boolean(actualCuratedData)

  const loading = hasCuratedData
    ? curatedResponse === ApiStateEnum.LOADING
    : defaultResponse === ApiStateEnum.LOADING
  const error = hasCuratedData
    ? isError(curatedResponse)
    : isError(defaultResponse)

  const actualResponse = hasCuratedData ? curatedResponse : defaultResponse
  return compileResults(actualResponse, loading, error)
}

const compileResults = (
  data: CTAType | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

export const selectCTA = (data: GetCallToActionByIdAndFirmQuery) =>
  data?.callToActionCollection?.items[0] as CTAType
