import { Icon } from '@cais-group/equity/atoms/icon'

import { avatarStyles } from './shared'

export function NotCaisAvatar() {
  return (
    <span
      className={`${avatarStyles} from-primary-400 to-primary-600 grid min-h-[56px] place-items-center bg-gradient-to-r`}
    >
      <Icon color="eq-color-neutral-0" type="User" />
    </span>
  )
}
