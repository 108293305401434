import { match, P } from 'ts-pattern'

import { useGetDisclaimer } from '@cais-group/homepage/domain/contentful'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import { Disclaimer } from '@cais-group/homepage/ui/disclaimer'
import { useGetFAQsCategories } from '@cais-group/shared/domain/contentful/faq'

import { FaqsLayout } from './layout'

export function Faqs() {
  const { pageData, error, loading } = useGetFAQsCategories()
  const disclaimer = useGetDisclaimer({
    id: 'faqs',
  })

  return (
    <>
      <Main>
        {match({ pageData, error, loading })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => <FaqsLayout categories={pageData} />
          )
          .otherwise(() => null)}
      </Main>
      <Disclaimer {...disclaimer} />
    </>
  )
}
