import {
  FILTERS,
  useGetCuratedFilterGroupByLabel,
  makeFiltersFromCuratedFilterGroup,
} from '@cais-group/homepage/domain/contentful'
import { CuratedFilterGroup } from '@cais-group/shared/util/graphql/mfe-contentful'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

type FamilyType = Record<string, string[]>

export const buildTagsAndQueryString = (filter: FamilyType) => {
  const queryString = new URLSearchParams()

  const tags = Object.entries(filter).map(([tagFamily, tags]) => {
    tags.forEach((tag) => {
      queryString.append(tagFamily, tag.toString())
    })
    return {
      tags: {
        AND: [{ tagId_in: tags }],
      },
    }
  })
  return {
    tags,
    queryString: queryString.toString(),
  }
}
export const usePrepareSummitCarousel = () => {
  const { data: filterGroup } = useGetCuratedFilterGroupByLabel('summit')

  if (!filterGroup?.length) {
    return undefined
  }
  const filters = makeFiltersFromCuratedFilterGroup(
    filterGroup as CuratedFilterGroup[]
  )[0]

  if (!filters) {
    return undefined
  }

  const { tags, queryString } = buildTagsAndQueryString(filters.filter)

  const whereFilter = {
    AND: [
      FILTERS.inPersonSummitEvents.is,
      ...tags,
      { displayDate_lte: today() },
    ],
  }
  return {
    whereFilter,
    queryString,
  }
}
