import {
  Divider,
  Main,
  topPaddedContentContainer,
} from '@cais-group/homepage/ui/components'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { DocumentTitle } from '@cais-group/shared/util/document-title'
import type { ExternalContent } from '@cais-group/shared/util/graphql/mfe-contentful'

import { CaisLiveCallToAction } from './cais-live-call-to-action'
import { CaisLiveReplays } from './cais-live-replays'
import { CaisLiveSummitReplays } from './cais-live-summit-replays'
import { CaisLiveUpcoming } from './cais-live-upcoming'
import { usePrepareInPersonEvents } from './hooks/use-prepare-in-person-events'

export function InPerson() {
  const { summitReplays, caisReplays, liveEvents, callToAction } =
    usePrepareInPersonEvents()

  return (
    <>
      <DocumentTitle title="In Person - Events" />
      <div className="pb-24 md:pb-56">
        <ContentDisclaimer
          items={[
            ...(summitReplays.pageData || []),
            ...(caisReplays.pageData?.allResearch || []),
            ...(liveEvents.pageData || []),
            ...((callToAction.pageData
              ? [callToAction.pageData]
              : []) as unknown as ExternalContent[]),
          ]}
          className={topPaddedContentContainer}
        />
      </div>
      <CaisLiveCallToAction />
      <Main>
        <Divider paddingSize="none">
          <CaisLiveUpcoming />
          <CaisLiveReplays />
          <CaisLiveSummitReplays />
        </Divider>
      </Main>
    </>
  )
}
