import { Authors, type AuthorsProps } from './author'
import { TagCollection, type TagsProps } from './tags-collection'

export const Sidebar = ({
  tags,
  authors,
}: {
  tags?: TagsProps
  authors?: AuthorsProps
}) => (
  <aside className="space-y-88">
    {authors && authors.length > 0 ? <Authors authors={authors} /> : null}

    {tags && tags.length > 0 ? <TagCollection tags={tags} /> : null}
  </aside>
)
