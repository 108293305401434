import { Outlet } from 'react-router-dom'

import { PageHeader } from '@cais-group/homepage/ui/page-header'
import { HomepageTabsNavBar } from '@cais-group/homepage/ui/tabs-nav-bar'
import { APP_URL } from '@cais-group/homepage/util/common'

export function HelpLayout() {
  return (
    <>
      <PageHeader title="Help" />
      <HomepageTabsNavBar
        tabs={[
          { label: 'Tutorials', link: `${APP_URL}/help/tutorials` },
          {
            label: 'Glossary',
            link: `${APP_URL}/help/glossary`,
          },
          {
            label: 'FAQs',
            link: `${APP_URL}/help/faqs`,
          },
        ]}
      />
      <Outlet />
    </>
  )
}
