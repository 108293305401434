import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { AToZ } from './constants'
import { Letter } from './letter'
import type { AtoZEntry, AtoZGroups } from './types'

const glossaryItemId = (a: string[]) => `${a.join('-')}`

export function AtoZ(props: AtoZGroups & { prefix?: string }) {
  const containerRef = useRef<HTMLDListElement | null>(null)
  const { id } = useParams()
  const { list = {}, prefix = '' } = props

  useEffect(() => {
    if (id && containerRef.current) {
      const el = containerRef.current.querySelector(
        `#${glossaryItemId([prefix, id])}`
      ) as HTMLDivElement
      if (el) {
        el.focus({ preventScroll: true })
        el.scrollIntoView &&
          el.scrollIntoView({
            behavior: 'smooth',
          })
      }
    }
  }, [id, prefix])

  return (
    <dl ref={containerRef} className="relative space-y-56">
      {AToZ.map((letter) => {
        const values = list[letter]
        return (
          <div
            key={letter}
            id={`${glossaryItemId([prefix, letter])}`}
            className="scroll-m-32 space-y-32"
            tabIndex={-1}
          >
            <Letter key={letter}>{letter}</Letter>
            {values && values.length > 0
              ? values.map(({ id, ...rest }) => (
                  <Entry
                    key={id}
                    {...rest}
                    id={`${glossaryItemId([prefix, id])}`}
                  />
                ))
              : null}
          </div>
        )
      })}
    </dl>
  )
}

function Entry(props: AtoZEntry) {
  const { definition, term, id } = props
  return (
    <div
      id={id}
      className="scroll-mt-88 flex max-w-prose flex-col gap-8"
      tabIndex={-1}
    >
      <dt className="headline-s-strong">{term}</dt>
      <dd className="body text-neutral-600">{definition}</dd>
    </div>
  )
}
