import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import {
  useGetFaQsByCategoryQuery,
  GetFaQsByCategoryQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { FAQType } from './useGetFAQs'

type FAQsByCategory = {
  title: string
  faqs: FAQType[]
}
export const useGetFAQsByCategory = (options: {
  variables: {
    id?: string
  }
  enabled?: boolean
}) => {
  const { variables, enabled } = options
  const response = useReactQueryResultAsApiState<
    GetFaQsByCategoryQuery,
    FAQsByCategory
  >(
    useGetFaQsByCategoryQuery(
      {
        id: variables.id ?? '',
      },
      { enabled: enabled, refetchOnWindowFocus: false }
    ),
    selectFAQs,
    'Could not load FAQs by category'
  )
  return compileResults(response)
}

const compileResults = (data: FAQsByCategory | ApiError | ApiStateEnum) => {
  return {
    pageData: isData(data) ? data : null,
    error: isError(data),
    loading: data === ApiStateEnum.LOADING,
  }
}
const selectFAQs = (data: GetFaQsByCategoryQuery) => {
  const faqCategory = data.faqCategoryCollection?.items[0]
  return {
    title: faqCategory?.title,
    faqs: faqCategory?.faqsCollection?.items ?? [],
  } as FAQsByCategory
}
