import cx from 'classnames'
import React, { type PropsWithChildren } from 'react'

import { Checkbox, CheckboxProps } from '@cais-group/equity/atoms/checkbox'
import { Counter, CounterProps } from '@cais-group/equity/atoms/counter'
import { Icon } from '@cais-group/equity/atoms/icon'
import { type Tracking } from '@cais-group/equity/util/tracking-utils'

import { Disclosure, DisclosureButton } from '../disclosure'

const variants = {
  small: 'small py-12',
  medium: 'body-strong py-16',
  large: 'headline-s-strong py-24',
} as const

export type Variant = keyof typeof variants

export interface CollapsibleSectionProps extends Tracking, PropsWithChildren {
  /**
   * The unique identifier of the collapsible section.
   */
  id?: string
  /**
   * Whether the collapsible section is open initially.
   */
  defaultIsOpen?: boolean
  /**
   * Controls whether the collapsible section is open.
   */
  isOpen?: boolean
  /**
   * Callback function that is called when the collapsible section is toggled.
   */
  onChange?: (isOpen: boolean) => void
  /**
   * Whether the collapsible section button is disabled.
   */
  disabled?: boolean
  /**
   * The title of the collapsible section.
   */
  title: string
  /**
   * The element to us for the title - defaults to `h3`.
   */
  titleAs?: React.ComponentType<PropsWithChildren>
  /**
   * The counter props to be passed to the counter component.
   */
  counter?: CounterProps

  /**
   * The checkbox props to be passed to the checkbox component.
   */
  checkbox?: CheckboxProps

  /**
   * The variant of the collapsible section.
   * @default 'medium'
   */
  variant?: Variant
}

/**
 * Collapsible Sections can be used to group content under a section title.
 * Clicking on the title will toggle between expanding to show the content and collapsing to hide content and only show the title.
 */
export const CollapsibleSection = ({
  children,
  id,
  defaultIsOpen,
  isOpen,
  disabled = false,
  title,
  titleAs: TitleAs = DefaultTitle,
  counter,
  checkbox,
  variant = 'medium',
  onChange,
  tracking,
}: CollapsibleSectionProps) => {
  return (
    <Disclosure
      onChangeOpen={onChange}
      open={isOpen}
      defaultIsOpen={defaultIsOpen}
      className={cx('border-1 group w-full border-solid border-neutral-200', {
        'pointer-events-none border-neutral-200 text-neutral-500': disabled,
        'open:border-primary-500': !disabled,
      })}
      {...(disabled && { 'aria-disabled': disabled })}
      title={title}
      tracking={tracking}
    >
      <DisclosureButton
        role="button" /* this should be implicit, but RTL does not find it :( */
        disabled={disabled}
        id={id}
        className={cx('flex', {
          'pointer-events-none bg-neutral-100': disabled,
          'bg-primary-100': !disabled && checkbox?.checked,
        })}
      >
        <div
          className={cx(
            'border-b-1 border-primary-100 mx-16 flex w-full cursor-pointer items-center justify-between gap-4 self-end',
            {
              [variants[variant]]: true,
              'group-open:border-neutral-200': !disabled && !checkbox?.checked,
            }
          )}
        >
          <div className="flex items-center gap-16">
            {checkbox && (
              <Checkbox
                {...checkbox}
                onClick={(e) => {
                  if (checkbox?.onClick) {
                    // We need to stop propagation to prevent the `DisclosureButton` from toggling the `Disclosure`
                    e.stopPropagation()
                    checkbox.onClick(e)
                  }
                }}
              />
            )}
            <TitleAs>{title}</TitleAs>
          </div>
          <div className="flex items-center gap-4">
            {counter && (
              <Counter
                {...counter}
                // Allow the size to be set by the caller, otherwise inherit the size from the `CollapsibleSection` variant
                size={counter.size ? counter.size : getCounterSize(variant)}
              />
            )}
            <span className="duration-short-2 flex items-center transition-transform group-open:rotate-90">
              <Icon
                type="ArrowForwardLrg"
                size="tiny"
                color="eq-color-neutral-600"
              />
            </span>
          </div>
        </div>
      </DisclosureButton>
      {children}
    </Disclosure>
  )
}

const getCounterSize = (variant: Variant): CounterProps['size'] | undefined => {
  switch (variant) {
    case 'small':
      return 'small'
    case 'medium':
      return 'small'
    case 'large':
      return 'large'
  }
}

const DefaultTitle = ({ children }: PropsWithChildren) => <h3>{children}</h3>

export default CollapsibleSection
