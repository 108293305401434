import React from 'react'
import { useLocation } from 'react-router-dom'

import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import { LOGOUT_ACTION } from '@cais-group/shared/ui/main-nav'
import {
  useGetAnnouncementsQuery,
  type AnnouncementFragment,
  type GetAnnouncementsQueryVariables,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

import { DATE_FORMAT, announcementsService } from './announcements-service'
import {
  isValidUrl,
  selectAnnouncements,
  prepareAnnouncementProps,
} from './helpers'

export type AnnouncementSet = {
  announcementDefault: AnnouncementFragment
  announcementOptionsCollection: AnnouncementFragment[]
  excludedUrls?: string[]
}

export const useAnnouncements = (
  variables: GetAnnouncementsQueryVariables,
  options?: {
    allowSingleDismissalPerSession?: boolean
  }
) => {
  const { userProfileState } = useUserProfile()
  const { userProfile } = userProfileState
  const { allowSingleDismissalPerSession = false } = options || {}
  const [announcement, setAnnouncement] =
    React.useState<AnnouncementFragment | null>(null)

  const allowedUserPermissions = useGetAllowedContentPermissions()

  const location = useLocation()
  const announcements = useGetAnnouncementsQuery(
    {
      id: variables.id,
      date: variables.date || today(DATE_FORMAT),
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => selectAnnouncements(data),
      // prevents default announcement from being shown while permissions are loading or announcements have been dismissed during the session
      enabled:
        !allowedUserPermissions.isLoading && !announcementsService.hasDismissed,
    }
  )

  const getNextAnnouncement = React.useCallback(
    () =>
      isValidUrl(location.pathname, announcements.data?.excludedUrls) &&
      !announcementsService.hasDismissed
        ? announcementsService.announcement
        : null,
    [announcements.data?.excludedUrls, location.pathname]
  )

  React.useEffect(() => {
    const handleLoggingOut = () => {
      if (announcementsService.hasDismissed) {
        announcementsService.clearHasDismissed()
      }
    }
    document.addEventListener(LOGOUT_ACTION, handleLoggingOut)
    if (announcements.data) {
      announcementsService.initiateAnnouncements(
        allowedUserPermissions.data,
        announcements.data,
        userProfile?.id
      )

      setAnnouncement(getNextAnnouncement)
    }
    return () => {
      document.removeEventListener(LOGOUT_ACTION, handleLoggingOut)
    }
  }, [
    allowedUserPermissions.data,
    announcements.data,
    getNextAnnouncement,
    location.pathname,
    userProfile?.id,
  ])

  const dismissAnnouncement = () => {
    if (announcement) {
      announcementsService.setAnnouncementsDismissed({
        announcement: {
          [announcement.sys.id]: {
            dismissed: true,
            dateTimeEnd: announcement.dateTimeEnd,
          },
        },
        allowSingleDismissalPerSession,
      })
      setAnnouncement(getNextAnnouncement)
    }
  }

  return {
    announcement: announcement
      ? prepareAnnouncementProps({
          announcement,
          dismissAnnouncement: !userProfile?.isSwitched
            ? dismissAnnouncement
            : () => {},
          isSwitched: Boolean(userProfile?.isSwitched),
        })
      : null,
  }
}
