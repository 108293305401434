import { match, P } from 'ts-pattern'

import {
  excludeBySysId,
  FeaturedResearchPageType,
} from '@cais-group/homepage/domain/contentful'
import {
  Carousel,
  Divider,
  FeaturedSkeleton,
  Section,
  SkeletonCardList,
  ViewAll,
} from '@cais-group/homepage/ui/components'
import { APP_URL } from '@cais-group/homepage/util/common'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import {
  Card,
  CardProps,
  prepareBasicCardProps,
  preparePressReleaseCardProps,
} from '@cais-group/shared/ui/contentful/card'

import { CarouselSplash } from '../../../components/carousel-no-results'
import { SkeletonHeader } from '../../../components/skeleton.header'

import { Featured } from './featured'

type FeaturedResearchProps = {
  featured: ContentDataType | null
  content: FeaturedResearchPageType
  featureLoading: boolean
  featureError: boolean
}
export function FeaturedResearch(props: FeaturedResearchProps) {
  const viewAllText = 'View All'
  const { featured, content, featureLoading, featureError } = props
  const featuredItemId = featured?.sys.id
  const { bands, press } = content

  const {
    pressData,
    loading: pressLoading,
    error: pressError,
    pressIndexLink,
  } = press

  return (
    <>
      {match({ featured, featureLoading, featureError })
        .with(
          {
            featureLoading: true,
          },
          () => <FeaturedSkeleton />
        )
        .with(
          { featureError: false, featured: P.not(P.nullish) },
          ({ featured }) => <Featured featured={featured} />
        )
        .otherwise(() => null)}
      <Divider>
        {match({ bands })
          .with({ bands: P.not(P.nullish) }, ({ bands }) =>
            bands?.map(
              (
                {
                  label,
                  loading: bandLoading,
                  error: bandError,
                  bandData,
                  filters,
                },
                i
              ) => {
                return match({ bandLoading, bandError, bandData, filters })
                  .with({ bandLoading: true, bandError: false }, () => {
                    return (
                      <SkeletonCardList
                        testId={`research-band-${label}`}
                        key={`research-band-${label}`}
                        skeletonControls={
                          <SkeletonHeader title={label} type="counter" />
                        }
                      />
                    )
                  })
                  .with({ bandLoading: false, bandError: true }, () => (
                    <Section key={`${label}-error`} title={label}>
                      <CarouselSplash.Error />
                    </Section>
                  ))
                  .with(
                    {
                      bandLoading: false,
                      bandError: false,
                      bandData: P.not(P.nullish),
                      filters: P.not(P.nullish),
                    },
                    ({ bandData }) => {
                      const queryString = new URLSearchParams()
                      Object.entries(filters).forEach(([tagFamily, tags]) => {
                        tags.forEach((tag) =>
                          queryString.append(tagFamily, tag.toString())
                        )
                      })
                      return (
                        <Section
                          title={label}
                          count={bandData.length}
                          endAdornment={
                            <ViewAll
                              href={`${APP_URL}/research/all-research?${queryString.toString()}`}
                              text={viewAllText}
                            />
                          }
                          key={label}
                        >
                          <Carousel
                            items={excludeBySysId(bandData, featuredItemId)}
                            itemMinWidth="medium"
                            renderItem={({ item }) => {
                              const props =
                                prepareBasicCardProps<CardProps>(item)

                              return props ? (
                                <Card {...props} testId="browse-content" />
                              ) : null
                            }}
                            testId="browse-content"
                          />
                        </Section>
                      )
                    }
                  )
                  .otherwise(() => null)
              }
            )
          )
          .otherwise(() => null)}

        {match({
          pressData,
          pressLoading,
          pressError,
          pressLength: pressData?.length,
        })
          .with({ pressError: true }, () => (
            <Section>
              <CarouselSplash.Error />
            </Section>
          ))
          .with({ pressLoading: true, pressError: false }, () => (
            <Section>
              <SkeletonCardList
                testId="press-releases"
                key="press-releases-skeleton"
                skeletonControls={
                  <SkeletonHeader title="Press Releases" type="counter" />
                }
              />
            </Section>
          ))
          .with(
            {
              pressData: P.not(P.nullish),
              pressLength: P.number.gt(0),
              pressError: false,
            },
            ({ pressData }) => (
              <Section
                title="Press Releases"
                count={pressData.length}
                endAdornment={
                  <ViewAll href={pressIndexLink} text={viewAllText} />
                }
              >
                <Carousel
                  items={pressData}
                  itemMinWidth="medium"
                  renderItem={({ item }) => {
                    const props = preparePressReleaseCardProps<CardProps>(
                      item,
                      pressIndexLink
                    )

                    return props ? (
                      <Card {...props} testId="browse-content" />
                    ) : null
                  }}
                  testId="press-releases-content"
                />
              </Section>
            )
          )
          .otherwise(() => null)}
      </Divider>
    </>
  )
}
