import { match, P } from 'ts-pattern'

import {
  PageErrorSplash,
  AutoGrid,
  Section,
  SkeletonGrid,
} from '@cais-group/homepage/ui/components'
import { usePagination } from '@cais-group/homepage/util/hook/use-pagination'
import { ContentDataType } from '@cais-group/homepage/util/types'
import {
  Card,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type { CardProps } from '@cais-group/shared/ui/contentful/card'

import { usePrepareInPersonEvents } from './hooks/use-prepare-in-person-events'

const sectionTitle = 'Past CAIS Live Replays'

export function CaisLiveReplays() {
  const { caisReplays } = usePrepareInPersonEvents()
  const { pageData, error, loading } = caisReplays
  const data = pageData?.allResearch ?? []

  const { reducer, ViewMore } = usePagination(data || [], 8)
  const state = reducer.state
  const pastCaisLiveEvents = data?.slice(0, state.take)

  return match({ pastCaisLiveEvents, error, loading })
    .with({ error: true }, () => <PageErrorSplash.Inline />)
    .with({ error: false, loading: true }, () => (
      <section aria-label="Loading CAIS Live event replays">
        <SkeletonGrid testId="cais-summit-replays" />
      </section>
    ))
    .with(
      {
        error: false,
        loading: false,
        pastCaisLiveEvents: P.when(
          (pastCaisLiveEvents): pastCaisLiveEvents is ContentDataType[] =>
            Array.isArray(pastCaisLiveEvents) && pastCaisLiveEvents.length > 0
        ),
      },
      ({ pastCaisLiveEvents }) => {
        return (
          <Section title={sectionTitle} count={pastCaisLiveEvents.length}>
            <AutoGrid>
              {pastCaisLiveEvents.slice(0, state.take).map((item) => {
                const props = item
                  ? prepareBasicCardProps<CardProps>(item)
                  : null
                return props ? (
                  <Card
                    key={props.id}
                    testId="cais-live-replays"
                    {...props}
                    animate
                    style={{ '--delay': '0ms' }}
                  />
                ) : null
              })}
            </AutoGrid>
            <ViewMore.Component reducer={reducer} />
          </Section>
        )
      }
    )
    .otherwise(() => null)
}
