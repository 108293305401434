import { Icon } from '@cais-group/equity/atoms/icon'
import { Link } from '@cais-group/equity/atoms/link'
import type { AuthorFragment } from '@cais-group/shared/util/graphql/mfe-contentful'

import { Avatar, CaisAvatar, NotCaisAvatar } from '../avatar'

export type AuthorProps = AuthorFragment
export function Author(props: AuthorProps) {
  const { authorName, jobTitle, firm, linkedin } = props
  const images = props.imageSet ?? []
  const [image] = images

  return (
    <div className="flex gap-x-24 gap-y-8" data-testid="author">
      <span className="flex-shrink-0 overflow-hidden">
        {image ? (
          <Avatar image={image} />
        ) : authorName === 'CAIS' || firm === 'CAIS' ? (
          <CaisAvatar />
        ) : (
          <NotCaisAvatar />
        )}
      </span>
      <div className="flex flex-col gap-y-4">
        <cite className="not-italic leading-tight">{authorName}</cite>
        {jobTitle && (
          <p className="small text-neutral-600">
            {jobTitle}
            {jobTitle && firm && ' | '}
            {firm}
          </p>
        )}
        {linkedin?.externalLink && (
          <p>
            <Link href={linkedin.externalLink} external>
              <span className="sr-only">{`LinkedIn profile for ${authorName}`}</span>
              <Icon type="LinkedIn" />
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}
