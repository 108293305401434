import { Outlet } from 'react-router-dom'

import { PageHeader } from '@cais-group/homepage/ui/page-header'
import { HomepageTabsNavBar } from '@cais-group/homepage/ui/tabs-nav-bar'
import { APP_URL } from '@cais-group/homepage/util/common'

export function Events() {
  return (
    <>
      <PageHeader title="Events" />
      <HomepageTabsNavBar
        tabs={[
          { label: 'In Person', link: `${APP_URL}/events/in-person` },

          {
            label: 'Webinars',
            link: `${APP_URL}/events/webinars`,
          },
        ]}
      />
      <Outlet />
    </>
  )
}
