import { Controller, useForm } from 'react-hook-form'
import { pickBy } from 'remeda'
import { useQueryParams, ArrayParam } from 'use-query-params'

import { ListItemCheckboxCounter } from '@cais-group/equity/molecules/list-item'
import { PillSelect } from '@cais-group/equity/molecules/pill-select'
import type { OptionType } from '@cais-group/equity/molecules/select'

export type FilterOption = OptionType<{
  label: string
  name?: string
  total?: number
  onClick?: () => void
  isDisabled?: boolean
}>

export type FilterValues = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [F in Filter as F['name']]: (FilterOption & Record<string, any>)[]
}

export type Filter = {
  label: string
  name: string
  options: FilterOption[]
  defaultValues: FilterOption[] | undefined
}

type FilterFormProps = {
  filters: Filter[]
  onUpdate: (filters: Record<string, FilterOption[]>) => void
}

export function useResearchFilterParams(filterNames: string[]) {
  const queryParamsConfig: Record<string, typeof ArrayParam> = {}
  filterNames.forEach((name) => {
    queryParamsConfig[name] = ArrayParam
  })

  const [filterParams, setFiltersParams] = useQueryParams(queryParamsConfig)

  const resetFilter = (filterName?: string) => {
    if (filterName) {
      setFiltersParams({ [filterName]: undefined }, 'replaceIn')
    } else {
      setFiltersParams({}, 'replace')
    }
  }

  const setFilter = (filterName: string, values: string[]) => {
    setFiltersParams({ [filterName]: values }, 'pushIn')
  }

  return {
    filters: pickBy(filterParams, (filterValues) =>
      Array.isArray(filterValues)
    ) as Record<string, string[]>,
    resetFilter,
    setFilter,
  }
}

export function FilterBar(props: FilterFormProps) {
  const { filters = [], onUpdate } = props

  const defaultValues = filters.reduce<FilterValues>(
    (acc, cur) => ({
      ...acc,
      [cur.name]: Array.isArray(cur.defaultValues) ? cur.defaultValues : [],
    }),
    {}
  )
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues,
  })

  const handleReset = (fieldName: string) => {
    if (fieldName) {
      reset((formValues) => ({
        ...formValues,
        [fieldName]: [],
      }))
    } else {
      reset()
    }
    onUpdate(getValues())
  }

  return (
    <form
      className="flex flex-wrap gap-24"
      onSubmit={handleSubmit(props.onUpdate)}
    >
      {filters.map((filter) => (
        <Controller
          key={filter.name}
          name={filter.name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PillSelect<{ label: string }>
              components={{ Option: ListItemCheckboxCounter }}
              handleReset={() => handleReset(filter.name)}
              isMulti
              label={filter.label}
              onChange={onChange}
              options={filter.options}
              value={value.map(({ value }) => value)}
            />
          )}
        />
      ))}
    </form>
  )
}
