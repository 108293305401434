import type {
  CaisiqVideo,
  CaisiqVideoPlaylist,
  ExternalContent,
  GetLandingPageQuery,
  GetPressReleasesQuery,
  PageLanding,
  Link,
  LinkCmsLink,
  GetHomepageLandingPageQuery,
  EventCardFragment,
  Pdf,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import type { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import type { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

export type EventType = EventCardFragment
export type EventStatusKey = 'upcoming' | 'open' | 'almost-full' | 'full'

export type ContentDataType =
  | TypeArticleData
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | WhitepaperData
  | EventType
  | ExternalContent

// This is obviously not necessary but felt like a better naming convention
export type CuratedContentDataType =
  | TypeArticleData
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | WhitepaperData
  | EventType
  | ExternalContent
  | Pdf
  | Link

export type PressReleaseType = NonNullable<
  GetPressReleasesQuery['pressReleases']
>['items'][0]

export type HomeContentType = {
  latest: { carousel: ContentDataType[]; error: boolean }
  events: { carousel: ContentDataType[]; error: boolean }
  webinars: { carousel: ContentDataType[]; error: boolean }
  research: { carousel: ContentDataType[]; error: boolean }
  tutorials: { carousel: ContentDataType[]; error: boolean }
  products: { carousel: ContentDataType[]; error: boolean }
}

export type PageData = NonNullable<
  GetLandingPageQuery['pageCollection']
>['items'][0]

export type HomepageLandingPageQueryData = NonNullable<
  GetHomepageLandingPageQuery['homepageLandingPageCollection']
>['items'][0]

export type LandingPageData = Omit<Exclude<PageData, null>, 'content'> & {
  content: PageLanding
}

/**
  Pages and Links
*/
export type PageTypes = 'CaisiqArticle' | 'Whitepaper' | 'PageResource'

export type SiteConfig = {
  pages: {
    readonly [key in PageTypes]: {
      path: string
      slug?: string
    }
  }
}

export type CTALink =
  | (Partial<Omit<Link, 'cmsLink'>> & {
      cmsLink?: Partial<LinkCmsLink> | null
    })
  | null

export const pillsTabType = {
  Events: 'Events',
  Latest: 'Latest',
  Products: 'Products',
  Webinars: 'Webinars',
  Research: 'Research',
  Tutorials: 'Tutorials',
} as const

const pillsTabLabels = {
  latest: 'latest',
  products: 'products',
  webinars: 'webinars',
  events: 'events',
  research: 'research',
  tutorials: 'tutorials',
}
export const pills: {
  [key in PillTabsTypes]: {
    label: keyof typeof pillsTabLabels
    href?: string
  }
} = {
  Latest: {
    label: 'latest',
  },
  Products: {
    label: 'products',
    href: '/funds-pre-trade',
  },
  Webinars: {
    label: 'webinars',
    href: 'events/webinars',
  },
  Events: {
    label: 'events',
    href: 'events/in-person',
  },
  Research: {
    label: 'research',
    href: 'research/all-research',
  },
  Tutorials: {
    label: 'tutorials',
    href: 'help/tutorials',
  },
}

export type PillTabsTypes = keyof typeof pillsTabType
export const pillLabels = Object.keys(pills) as PillTabsTypes[]
export const pillLabelsArray = pillLabels.map((key) => pills[key].label)
