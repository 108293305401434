import { match, P } from 'ts-pattern'

import {
  AutoGrid,
  Carousel,
  Divider,
  FeaturedSkeleton,
  Section,
  SkeletonCardList,
  SkeletonGrid,
} from '@cais-group/homepage/ui/components'
import { usePagination } from '@cais-group/homepage/util/hook/use-pagination'
import { trackingLabels } from '@cais-group/homepage/util/tracking'
import type {
  ContentDataType,
  EventType,
} from '@cais-group/homepage/util/types'
import {
  Card,
  CardFeatured,
  isEvent,
  prepareBasicCardProps,
  prepareEventCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type {
  CardProps,
  FeaturedCardProps,
} from '@cais-group/shared/ui/contentful/card'

import { HomepageNoResults } from '../../../components/homepage-no-results'
import { SkeletonHeader } from '../../../components/skeleton.header'

type WebinarsProps = {
  featured?: EventType | null
  upcoming: EventType[]
  replays: ContentDataType[]
  debouncedSearchText: string
  loading: boolean
}

/* NOTE
  featured: managed by Marketing in Contentful
  upcoming: ordered date asc from today
  replays: ordered date desc from today
*/

const NoUpcoming = () => (
  <p className="body-strong flex flex-col gap-8 text-neutral-900">
    No upcoming webinars at the moment.
    <span className="body text-neutral-600">
      Check out past webinar replays below.
    </span>
  </p>
)
export function Webinars(props: WebinarsProps) {
  const { featured, replays, upcoming, debouncedSearchText, loading } = props
  const { reducer, pageSize, ViewMore } = usePagination(replays)
  const state = reducer.state
  const featuredCardProps = featured
    ? isEvent(featured)
      ? prepareEventCardProps<FeaturedCardProps>(featured)
      : prepareBasicCardProps<FeaturedCardProps>(featured)
    : null

  const upcomingLength = upcoming?.length
  const replaysLength = replays?.length

  return (
    <>
      {match({ featuredCardProps, loading })
        .with(
          {
            loading: true,
          },
          () => <FeaturedSkeleton />
        )
        .with(
          {
            featuredCardProps: P.not(P.nullish),
          },
          ({ featuredCardProps }) => (
            <CardFeatured
              {...featuredCardProps}
              tracking={{
                sub_section: trackingLabels.subSection.FeaturedContent,
              }}
            />
          )
        )
        .otherwise(() => null)}
      <Divider paddingSize={!featuredCardProps ? 'medium' : 'large'}>
        {match({
          upcoming,
          upcomingLength,
          replays,
          replaysLength,
          debouncedSearchText,
          featuredCardProps,
          loading,
        })
          .with(
            {
              loading: true,
            },
            () => (
              <Section>
                <SkeletonCardList
                  skeletonControls={
                    <SkeletonHeader
                      title={trackingLabels.subSection.UpcomingWebinars}
                      type="counter"
                    />
                  }
                />
              </Section>
            )
          )
          // Show carousel if there are any upcoming
          .with(
            {
              upcoming: P.not(P.nullish),
              upcomingLength: P.when((l) => l > 0),
            },
            ({ upcoming }) => (
              <Section
                title={trackingLabels.subSection.UpcomingWebinars}
                count={upcomingLength}
              >
                <Carousel
                  items={upcoming}
                  itemMinWidth="medium"
                  renderItem={({ item }) => {
                    const props = prepareEventCardProps<CardProps>(item)
                    return props ? <Card {...props} /> : <span />
                  }}
                />
              </Section>
            )
          )
          // Show no upcoming if there are no upcoming by default aka no search
          .with(
            {
              upcoming: P.not(P.nullish),
              upcomingLength: P.when((l) => !l),
              replays: P.not(P.nullish),
              debouncedSearchText: P.when(
                (debouncedSearchText) => !debouncedSearchText
              ),
            },
            () => (
              <Section
                title={trackingLabels.subSection.UpcomingWebinars}
                count={upcomingLength}
              >
                <NoUpcoming />
              </Section>
            )
          )
          // Show no results message if no replays or upcoming on search
          .with(
            {
              replays: P.not(P.nullish),
              upcoming: P.not(P.nullish),
              upcomingLength: P.when((l) => !l),
              replaysLength: P.when((l) => !l),
              featuredCardProps: P.nullish,
              debouncedSearchText: P.when(
                (debouncedSearchText) => !!debouncedSearchText
              ),
            },
            () => <HomepageNoResults searchText={debouncedSearchText} />
          )
          .otherwise(() => null)}
        {/* Show replays if there are any replays. Separate match necessary */}
        {match({ replays, replaysLength, loading })
          .with(
            {
              loading: true,
            },
            () => (
              <Section>
                <SkeletonGrid
                  count={4}
                  skeletonControls={
                    <SkeletonHeader
                      title={trackingLabels.subSection.WebinarReplays}
                      type="counter"
                    />
                  }
                />
              </Section>
            )
          )
          .with(
            {
              replays: P.not(P.nullish),
              replaysLength: P.when((l) => l > 0),
            },
            ({ replays }) => (
              <>
                <Section
                  title={trackingLabels.subSection.WebinarReplays}
                  count={replaysLength}
                >
                  <AutoGrid>
                    {replays.slice(0, state.take).map((item, index) => {
                      const props = prepareBasicCardProps<CardProps>(item)
                      const animationDelay =
                        state.page > 1 &&
                        index - pageSize * (state.page - 1) > -1
                          ? (index % pageSize) * 25 + 1
                          : 0
                      return props ? (
                        <Card
                          key={item.sys.id}
                          {...props}
                          animate={animationDelay > 0}
                          style={{ '--delay': `${animationDelay}ms` }}
                        />
                      ) : (
                        <span />
                      )
                    })}
                  </AutoGrid>
                </Section>

                <ViewMore.Component
                  reducer={reducer}
                  tracking={{
                    sub_section: trackingLabels.subSection.GridOfContent,
                  }}
                />
              </>
            )
          )
          .otherwise(() => null)}
      </Divider>
    </>
  )
}
