import type { AuthorProps } from './author'
import { Author } from './author'

export type AuthorsProps = Array<AuthorProps>

export function Authors({ authors }: { authors: AuthorsProps }) {
  return (
    <div>
      <p className="small flex items-center gap-x-24 border-y border-neutral-200 py-16 tracking-widest text-neutral-600">
        Authored by
      </p>
      <div className="mt-32 space-y-32">
        {authors.map((author) => (
          <Author key={author?.authorName} {...author} />
        ))}
      </div>
    </div>
  )
}
