import { RichText } from '@cais-group/shared/ui/contentful/rich-text'

type RichTextField = Parameters<typeof RichText>[0]['field']

type FootnotesProps = {
  footnotes?: RichTextField
  id?: string
}

export function Footnotes(props: FootnotesProps) {
  const { footnotes, id } = props
  const footnoteId = `footnotes${id ? `-${id}` : ''}`
  return (
    <footer
      className="scroll-mt-128 [&_*]:!small break-words border-t border-neutral-200 py-24 text-neutral-600 [&>ol]:!ml-24 [&>ol]:!mt-0 [&_a]:!font-bold [&_ol]:space-y-4"
      id={footnoteId}
      data-testid={`${footnoteId}-container`}
    >
      <h2 className="sr-only">Footnotes</h2>
      <RichText field={footnotes as RichTextField} />
    </footer>
  )
}
