import { pluralize } from '@cais-group/equity/utilitarian'

export function ResultsCount({ count }: { count: number }) {
  return (
    <p className="body mr-auto self-start">
      {pluralize({
        count,
        singular: 'result',
        plural: 'results',
        includeCount: true,
      })}
    </p>
  )
}
