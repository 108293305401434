import { useState, useEffect } from 'react'

import { useDebouncedState } from '@cais-group/shared/util/hook/use-debounced-state'

export function useMinimumDebouncedState(
  value: string,
  delay: number,
  min = 3
) {
  const [newVersion, setNewVersion] = useState<string>('')
  const [previousVersion, setPreviousVersion] = useState<string>('')
  const debouncedSearchText = useDebouncedState(value, delay)

  useEffect(() => {
    if (debouncedSearchText.length >= min) {
      setPreviousVersion(debouncedSearchText)
      setNewVersion(debouncedSearchText)
    }

    if (debouncedSearchText.length < min && debouncedSearchText.length > 0) {
      setNewVersion(previousVersion)
    }

    if (debouncedSearchText.length === 0) {
      setNewVersion('')
      setPreviousVersion('')
    }
  }, [value, delay, min, previousVersion, debouncedSearchText])

  return newVersion
}
