import { ReactNode } from 'react'

import { Select } from '@cais-group/equity/molecules/select'
import type {
  MultiSelectType,
  SelectProps,
  SingleSelectType,
} from '@cais-group/equity/molecules/select'

import { generateInputComponent, Root } from './components'
import { filterOptionsFn } from './utils'

const noop = () => {}

export type PillSelectProps<T> = Omit<
  SelectProps<T>,
  'isSearchable' | 'placeholder'
> & {
  label: ReactNode
  handleReset?: () => void
}

export const PillSelect = <T,>(props: PillSelectProps<T>) => {
  const {
    components,
    handleReset = noop,
    isMulti = true,
    label,
    onChange,
    value,
    ...select
  } = props

  return (
    <Select<T>
      isMulti={isMulti as typeof isMulti extends true ? true : false}
      onChange={
        onChange as typeof isMulti extends true
          ? MultiSelectType<T>['onChange']
          : SingleSelectType<T>['onChange']
      }
      value={
        value as typeof isMulti extends true
          ? MultiSelectType<T>['value']
          : SingleSelectType<T>['value']
      }
      inputChildren={typeof label === 'string' ? undefined : label}
      placeholder={typeof label === 'string' ? label : undefined}
      filterOptionsFn={filterOptionsFn}
      showSelectedValues={false}
      components={{
        Input: generateInputComponent(
          typeof label === 'string' ? label : undefined
        ),
        Root,
        ToggleButton: null,
        ...components,
      }}
      menuActions={[
        {
          size: 'small',
          children: 'Clear',
          variant: 'tertiary',
          color: 'error',
          type: 'reset',
          onClick: (event: React.MouseEvent<HTMLButtonElement>): void => {
            event.preventDefault()
            handleReset()
          },
        },
        {
          size: 'small',
          children: 'Apply',
          variant: 'tertiary',
          type: 'submit',
        },
      ]}
      {...select}
    />
  )
}
