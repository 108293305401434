import type { Node } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'
import type { ReactNode } from 'react'

export const introduction = (children: ReactNode) => (
  <p className="headline-s">{children}</p>
)

export const Introduction = ({ children }: { children: ReactNode }) => (
  <p className="headline-s">{children}</p>
)

export const richTextIntroduction = {
  [BLOCKS.PARAGRAPH]: (_: Node, children: ReactNode) => (
    <Introduction>{children}</Introduction>
  ),
}
