import { Navigate, Route, Routes } from 'react-router-dom'

import { Events, InPerson } from '@cais-group/homepage/feature/events'
import { Glossary } from '@cais-group/homepage/feature/glossary'
import { LandingPage } from '@cais-group/homepage/feature/landing-page'
import { PageErrorSplash } from '@cais-group/homepage/ui/components'

import { Articles } from './screens/articles'
import { Articles as ArticlesBff } from './screens/articles-bff'
import { Faqs } from './screens/help/faqs'
import { FaqCategory } from './screens/help/faqs/category'
import { HelpLayout } from './screens/help/layout'
import { TutorialsIndex as Tutorials } from './screens/help/tutorials'
import { HomeIndex as Home } from './screens/home'
import { WebinarsIndex as Webinars } from './screens/learn/webinars'
import { DisclosurePageContainer, DISCLOSURE_PAGE_SLUG } from './screens/legal'
import { AllResearchIndex as Research } from './screens/research'
import { FeaturedResearchIndex } from './screens/research/featured'
import { ResearchLayout } from './screens/research/layout'
import { PageResourcesContainer } from './screens/resources'
import { Videos } from './screens/videos'
import { Whitepapers } from './screens/whitepapers'

const PageErrorSplashNotFound = PageErrorSplash.NotFound

// NOTE: use <Navigate> in research so that the global navs Insights > Research for both pages and highlights the correct in-page tab
export const Router = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="articles/:slug" element={<Articles />} />
      <Route path="articles-bff/:slug" element={<ArticlesBff />} />
      <Route
        path="resources/:parentSlug/:childSlug"
        element={<PageResourcesContainer />}
      />
      <Route
        path={DISCLOSURE_PAGE_SLUG}
        element={<DisclosurePageContainer />}
      />
      <Route path="events" element={<Events />}>
        <Route path="in-person" element={<InPerson />} />
        <Route path="webinars" element={<Webinars />} />
        <Route index element={<Navigate to="in-person" replace={true} />} />
      </Route>
      <Route path="webinars" element={<Webinars />} />
      <Route path="help" element={<HelpLayout />}>
        <Route path="glossary/:id?" element={<Glossary />} />
        <Route path="tutorials" element={<Tutorials />} />
        <Route path="faqs" element={<Faqs />}>
          <Route path=":categoryId?/:faqId?" element={<FaqCategory />} />
        </Route>
        <Route index element={<PageErrorSplashNotFound />} />
      </Route>
      <Route path="research" element={<ResearchLayout />}>
        <Route path="featured" element={<FeaturedResearchIndex />} />
        <Route path="all-research" element={<Research />} />
        <Route index element={<Navigate to="featured" replace={true} />} />
      </Route>
      <Route path="videos/:playlistId?/:id" element={<Videos />} />
      <Route path="whitepapers/:slug" element={<Whitepapers />} />
      <Route path="*" element={<LandingPage />} />
    </Routes>
  )
}
