import * as ReactDOM from 'react-dom/client'

import { APPS, APP_IDS } from '@cais-group/shared/domain/apps'
import { AppWrapper } from '@cais-group/shared/ui/app-wrappers'

import HomepageApp from './app/app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AppWrapper appName={APPS.HOMEPAGE} appBasePath={APP_IDS.HOMEPAGE}>
    <HomepageApp />
  </AppWrapper>
)
