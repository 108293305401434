import type { PropsWithChildren } from 'react'
import { useLayoutEffect, useRef, useState } from 'react'

export const StickySidebar = (
  props: PropsWithChildren<{ className?: string }>
) => {
  const layoutRef = useRef<HTMLDivElement>(null)
  const [offsetTop, setOffsetTop] = useState<number>()
  const { className = '' } = props

  useLayoutEffect(() => {
    if (layoutRef && layoutRef.current) {
      setOffsetTop(layoutRef.current.offsetTop)
    }
  }, [])

  return (
    <div
      data-sticky
      className={`top-32 space-y-8 overflow-y-auto md:sticky md:self-start ${className}`}
      style={{
        maxHeight: `calc(100vh - ${offsetTop}px)`,
      }}
      ref={layoutRef}
    >
      {props.children}
    </div>
  )
}
