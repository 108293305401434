import cx from 'classnames'
import { NavLink } from 'react-router-dom'

import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { FAQCategories } from '@cais-group/shared/domain/contentful/faq'

export type FaqNavProps = {
  categories: FAQCategories
  state?: 'index' | 'category' | 'search'
}

export function Nav(props: FaqNavProps) {
  const { categories, state = 'category' } = props
  return (
    <nav aria-label="FAQs">
      <ul className="flex flex-wrap gap-x-8 gap-y-4 overflow-y-auto md:block">
        {categories.map((category, index) => (
          <li
            key={category.id}
            className={cx(
              'flex gap-8 [&:last-child>span]:hidden',
              {
                'text-brand-600': state === 'index' && index === 0,
              },
              { 'text-neutral-600': state !== 'index' || index > 0 }
            )}
          >
            <NavLink
              to={category.id || ''}
              className={cx(
                'body-strong hover:text-brand-600 py-4 text-inherit',
                {
                  'aria-[current=page]:text-brand-600': state !== 'search',
                }
              )}
              {...mapTrackingKeysToDataAttributes({
                click_type: 'Tab Navigation',
                item_name: category.title,
              })}
            >
              {category.title}
            </NavLink>
            <span className="bg-brand-600 w-[1px] md:hidden" />
          </li>
        ))}
      </ul>
    </nav>
  )
}
