import { camelCase, isEmpty } from 'lodash-es'
import { useCallback, useState } from 'react'
import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  useGetResearch,
  useGetFilterGroupById,
  useGetAllResearchTagCounts,
} from '@cais-group/homepage/domain/contentful'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import {
  FilterBar,
  FilterOption,
  FilterValues,
  useResearchFilterParams,
} from '@cais-group/homepage/ui/filter-bar'
import type { Filter } from '@cais-group/homepage/ui/filter-bar'
import { useMinimumDebouncedState } from '@cais-group/homepage/util/hook/use-minimum-debounced-state'
import { DocumentTitle } from '@cais-group/shared/util/document-title'
import { TagFamily } from '@cais-group/shared/util/graphql/mfe-contentful'

import { HomepageSearchInput } from '../../components/homepage-search-input'

import { AllResearch } from './research'

type SelectedFilters = Record<string, string[]>
type FilterCounts = Record<string, number>

function makeFilters(
  tagGroups: TagFamily[],
  selectedFiltersMap: SelectedFilters,
  allTagCounts: FilterCounts,
  activeTagCounts: FilterCounts
): Filter[] {
  return tagGroups.map((filterGroup) => {
    const filterName = camelCase(filterGroup.name ?? '')

    const selectedFilters = selectedFiltersMap[filterName] ?? []
    const defaultValues: FilterOption[] = []

    const filterOptions = filterGroup.tagsCollection?.items
      .map((tag) => {
        const optionLabel = camelCase(tag?.label ?? '')
        const optionValue = tag?.tagId ?? ''
        const totalCount = allTagCounts[optionValue]
        const activeCount = activeTagCounts[optionValue]

        const isDisabled = !activeTagCounts[optionValue] && totalCount > 0

        const filterOption: FilterOption = {
          isDisabled: isDisabled,
          label: tag?.label ?? '',
          name: optionLabel,
          total: activeCount,
          value: optionValue,
        }

        if (selectedFilters.includes(optionValue)) {
          defaultValues.push({ label: optionLabel, value: optionValue })
        }

        return filterOption
      })
      .filter((option) => Boolean(allTagCounts?.[option.value]))

    return {
      label: filterGroup.name ?? '',
      name: filterName,
      options: filterOptions ?? [],
      defaultValues,
    }
  })
}

const FILTERS_PAGE_LIMIT = 100
const FIVE_MINUTES = 1000 * 60 * 5

function useFilterTagCounts(
  searchText: string,
  selectedFilters: SelectedFilters
) {
  const allTagCounts = useGetAllResearchTagCounts(
    {
      selectedFilters: {},
      searchText: '',
      limit: FILTERS_PAGE_LIMIT,
    },
    {
      staleTime: FIVE_MINUTES,
      cacheTime: FIVE_MINUTES,
    }
  )

  const activeTagCounts = useGetAllResearchTagCounts({
    selectedFilters,
    searchText: searchText,
    limit: FILTERS_PAGE_LIMIT,
  })

  return {
    allTagCounts: allTagCounts.pageData,
    activeTagCounts: activeTagCounts.pageData,
    loading: allTagCounts.loading || activeTagCounts.loading,
    error: allTagCounts.error || activeTagCounts.error,
  }
}

export function AllResearchIndex() {
  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useMinimumDebouncedState(searchText, 600)

  const filterGroup = useGetFilterGroupById('homepage/all-research')

  const researchFilters = useResearchFilterParams(
    filterGroup?.data?.map((filterFamily) =>
      camelCase(filterFamily?.name ?? '')
    ) ?? []
  )

  const handleFilterUpdate = useCallback(
    (filters: FilterValues) => {
      for (const [filterName, filterValues] of Object.entries(filters)) {
        researchFilters.setFilter(
          filterName,
          filterValues.map((o) => o.value)
        )
      }
    },
    [researchFilters]
  )

  const researchPage = useGetResearch({
    selectedFilters: researchFilters.filters,
    searchText: debouncedSearchText,
  })

  const filterTagCounts = useFilterTagCounts(
    debouncedSearchText,
    researchFilters.filters
  )

  const count =
    debouncedSearchText || !isEmpty(researchFilters.filters)
      ? researchPage?.pageData?.allResearch?.length
      : 0
  return (
    <>
      {match({
        loading:
          !filterGroup ||
          (filterGroup.isLoading &&
            !researchPage.error &&
            !filterGroup.error &&
            !filterTagCounts.error),
      })
        .with({ loading: true }, () => (
          <LoadingContainer
            state={LoadingState.LOADING}
            type="large"
            coverPage="FULL_SCREEN_WITH_HEADER"
          />
        ))
        .otherwise(() => (
          <Main>
            <HomepageSearchInput
              searchText={searchText}
              onSearch={setSearchText}
              size={count ? 'medium' : 'large'}
              count={count}
              controls={
                // TODO HP - 125 - we may want to look at this in mobile view as when more than one is clicked, the layout changes
                <FilterBar
                  filters={makeFilters(
                    filterGroup.data as TagFamily[],
                    researchFilters.filters,
                    filterTagCounts.allTagCounts ?? {},
                    filterTagCounts.activeTagCounts ?? {}
                  )}
                  onUpdate={handleFilterUpdate}
                />
              }
            />
            {match({
              pageData: researchPage.pageData,
              error: Boolean(researchPage.error),
              loading: researchPage.loading,
            })
              .with({ error: true }, () => <PageErrorSplash.General />)
              .with(
                { error: false, pageData: P.not(P.nullish) },
                ({ pageData }) => (
                  <>
                    <DocumentTitle title="All Research" />
                    <AllResearch
                      items={pageData.allResearch || []}
                      searchText={debouncedSearchText}
                      loading={researchPage.loading}
                    />
                  </>
                )
              )
              .otherwise(() => null)}
          </Main>
        ))}
    </>
  )
}
