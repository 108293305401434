import { Markdown } from '@cais-group/equity/atoms/markdown'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'

import { ComponentSectionProps } from '../types'

export const SectionHeader = ({
  title,
  componentSectionDescription,
}: {
  title?: ComponentSectionProps['title']
  componentSectionDescription?:
    | ComponentSectionProps['componentSectionDescription']
    | string
}) => {
  const isMarkdown = typeof componentSectionDescription === 'string'

  let description = null

  if (componentSectionDescription) {
    if (isMarkdown) {
      description = <Markdown>{componentSectionDescription}</Markdown>
    } else {
      description = <RichText field={componentSectionDescription} />
    }
  }

  return title || description ? (
    <header className="max-w-screen-lg space-y-24 pb-24">
      <h2 className="headline-s-strong">{title}</h2>
      {description}
    </header>
  ) : null
}
