import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useGetResourcePage } from '@cais-group/homepage/domain/contentful'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import { APP_URL } from '@cais-group/homepage/util/common'
import { PageResource } from '@cais-group/shared/ui/contentful/article'
import { ContentfulBreadcrumbs as Breadcrumbs } from '@cais-group/shared/ui/contentful/breadcrumb'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

export function PageResourcesContainer() {
  const { parentSlug, childSlug } = useParams()

  const { pageData, loading, error } = useGetResourcePage({
    slug: `${parentSlug}/${childSlug}` || '',
  })

  return (
    <>
      {match({ pageData, error })
        .with(
          {
            error: false,
            pageData: P.not(P.nullish),
          },
          ({ pageData }) => (
            <Breadcrumbs
              baseUrl={APP_URL}
              category="resources_structured_investments"
              currentPage={pageData.title || ''}
            />
          )
        )
        .otherwise(() => null)}

      <Main fullWidth>
        {match({ pageData, error, loading })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with({ loading: true, error: false }, () => (
            <LoadingContainer
              state={LoadingState.LOADING}
              type="large"
              coverPage="FULL_SCREEN_WITH_HEADER"
            />
          ))
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title={pageData.title || ''} />
                <PageResource {...pageData} urlPrefix={APP_URL} />
              </>
            )
          )
          .otherwise(() => (
            <PageErrorSplash.NotFound />
          ))}
      </Main>
    </>
  )
}
