import type { PropsWithChildren } from 'react'

const layout = {
  padding: {
    none: '',
    medium: 'pt-24',
    large: 'pt-56',
  },
}

export type DividerProps = PropsWithChildren<{
  paddingSize?: keyof typeof layout.padding
}>

export function Divider(props: DividerProps) {
  return (
    <div
      className={`@container/main-column space-y-56 ${
        layout.padding[props.paddingSize || 'large']
      }`}
    >
      {props.children}
    </div>
  )
}
