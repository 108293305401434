import type { OptionType } from '@cais-group/equity/molecules/select'

type FilterOptionsInput<T> = {
  options: OptionType<T>[]
}

export function filterOptionsFn<T>(
  props: FilterOptionsInput<T>
): OptionType<T>[] {
  return props.options
}
