import { Icon } from '@cais-group/equity/atoms/icon'
import type { AllColor } from '@cais-group/equity/particles/colors'
import type { IconType } from '@cais-group/equity/particles/icons'
import { CloudinaryImage } from '@cais-group/shared/ui/cloudinary-image'
import type { AnnouncementFragment } from '@cais-group/shared/util/graphql/mfe-contentful'

import { prepareImageProps } from '../helpers'

export const Adornment = ({
  announcement,
  color,
}: {
  announcement: (AnnouncementFragment & { icon?: IconType }) | null
  color: AllColor
}) => {
  if (!announcement) {
    return null
  }

  if (announcement.icon) {
    return <Icon type={announcement.icon} color={color} />
  }

  const image = announcement.image && prepareImageProps(announcement.image[0])
  if (image) {
    return (
      <CloudinaryImage
        src={image.src}
        layout="fixed"
        width={image.width}
        aspectRatio={image.aspect_ratio}
      />
    )
  }

  return null
}
