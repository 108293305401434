import cx from 'classnames'

import { Fallback } from '@cais-group/approved/ui/fallback'
import { Icon } from '@cais-group/equity/atoms/icon'
import { colors } from '@cais-group/equity/particles/colors'

export function HomepageNoResults(props: {
  searchText?: string
  addPadding?: boolean
}) {
  const { searchText, addPadding } = props
  return (
    <div
      className={cx(
        'bg-neutral-0 flex h-full flex-col items-center justify-center p-0 md:p-56',
        {
          // Padding needs to be adjusted when there is a filter bar
          'md:p-88': addPadding,
        }
      )}
    >
      <Fallback
        title={searchText && `No results for "${searchText}"`}
        text="Try modifying your search criteria"
        backgroundColor={colors['eq-color-neutral-0']}
        icon={() => (
          <Icon type="Search" color="eq-color-neutral-400" size="small" />
        )}
      />
    </div>
  )
}
