import type { LinkProps } from '@cais-group/equity/atoms/link'
import type { CTALink, PageTypes } from '@cais-group/homepage/util/types'

import { APP_URL, SITE_CONFIG } from '../constants'

export function prepareLinkProps(
  link?: CTALink
): (LinkProps & { text: string }) | undefined {
  if (!link) return undefined

  let href = APP_URL
  const cmsLink = link?.cmsLink
  const internalLink = link?.internalLink
  const externalLink = link?.externalLink

  if (cmsLink?.__typename) {
    const pageType = cmsLink.__typename as PageTypes
    const basePath = SITE_CONFIG.pages[pageType].path
    href = `${href}${basePath}/${cmsLink.slug}`
  } else if (internalLink) {
    href = `${href}${internalLink}`
  } else if (externalLink) {
    href = externalLink
  }

  return {
    href: href.startsWith('//') ? href.replace('//', '/') : href,
    openInNewWindow: Boolean(link?.openInNewWindow),
    text: link?.text || '',
  }
}
