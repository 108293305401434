import { NavLink, useParams } from 'react-router-dom'

import { Icon } from '@cais-group/equity/atoms/icon'
import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { trackingLabels } from '@cais-group/homepage/util/tracking'

import { AToZ, AtoZNavGroups, Prefix } from './constants'
import type { AtoZGroups } from './types'
import { useDisclosureToggle } from './use-disclosure-toggle'

/**
 * Note
 * The design means that equity/labs/disclosure component cannot be used at the moment
 * TODO HP-? consider reviewing so that the Equity Disclosure component can be used
 */

export function AtoZNav<T extends AtoZGroups>(
  props: T & { prefix?: string; relativeUrl?: boolean }
) {
  const { ids, handleToggle } = useDisclosureToggle()
  const { id } = useParams()
  const { prefix = Prefix } = props

  return (
    <>
      <nav
        {...mapTrackingKeysToDataAttributes({
          sub_section: trackingLabels.subSection.AtoZNavigation,
        })}
        className="divide-x-1 flex flex-wrap gap-16 md:hidden"
        aria-label={`A to Z navigation${
          prefix !== Prefix ? ` for ${prefix}` : ''
        }`}
      >
        {AtoZNavGroups.map((entry) => {
          const entryText = `${entry.start} - ${entry.stop}`
          return (
            <NavLink
              end
              key={entry.start}
              to={id ? `../${entry.start}` : entry.start}
              relative={id ? 'path' : 'route'}
              className="body-strong hover:text-brand-500 text-brand-600 pl-16 first:pl-0 hover:underline"
              {...mapTrackingKeysToDataAttributes({
                click_type: 'Tab Navigation',
                item_name: entryText,
              })}
            >
              {entryText}
            </NavLink>
          )
        })}
      </nav>
      <ol
        className="hidden space-y-8 md:flex md:flex-col"
        data-testid={`atoz-nav-${prefix}`}
        {...mapTrackingKeysToDataAttributes({
          sub_section: trackingLabels.subSection.AtoZNavigation,
        })}
      >
        {AToZ.map((letter) => {
          const values = props.list[letter]
          return (
            <li key={letter}>
              {values && values.length > 0 ? (
                <details
                  data-testid={`${prefix}-${letter}`}
                  key={`${prefix}-${letter}`}
                  className="group"
                  onToggle={() => handleToggle(letter)}
                  {...mapTrackingKeysToDataAttributes({
                    click_type: 'Information Expansion',
                    item_name: `${letter}: Information ${
                      ids.includes(letter) ? 'Collapsed' : 'Expanded'
                    }`,
                  })}
                >
                  <summary className="small-strong group-open:text-brand-600 flex cursor-pointer list-none items-center text-neutral-600 [&::marker]:hidden group-open:[&>svg]:rotate-90 group-open:[&>svg]:transition-transform">
                    <Icon
                      type="KeyboardArrowRight"
                      color="eq-color-neutral-300"
                    />
                    {letter}
                  </summary>
                  <ol className="space-y-8 pb-16 pl-24 pt-8">
                    {values.map((value) => (
                      <li key={value.id} className="flex">
                        <NavLink
                          to={id ? `../${value.id}` : value.id}
                          relative={id ? 'path' : 'route'}
                          className="small aria-[current=page]:text-brand-600 hover:text-brand-600 text-neutral-600"
                          end
                          state={{ preventScrollToTop: true }}
                          {...mapTrackingKeysToDataAttributes({
                            click_type: 'Tab Navigation',
                            item_name: value.term,
                          })}
                        >
                          {value.term}
                        </NavLink>
                      </li>
                    ))}
                  </ol>
                </details>
              ) : (
                <span className="small-strong pl-24 text-neutral-300">
                  {letter}
                </span>
              )}
            </li>
          )
        })}
      </ol>
    </>
  )
}
