import { useState } from 'react'

const initial: string[] = []

export const useDisclosureToggle = (init = initial) => {
  const [ids, setIds] = useState(init)

  function handleToggle(thisId: string) {
    if (ids.includes(thisId)) {
      setIds(ids.filter((id) => id !== thisId))
    } else {
      setIds([...ids, thisId])
    }
  }

  return {
    ids,
    handleToggle,
  }
}
