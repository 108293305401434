import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import {
  useGetDisclaimer,
  useGetFeaturedResearch,
  useGetFeaturedResearchContent,
  useGetFilterGroupById,
  useGetResearch,
} from '@cais-group/homepage/domain/contentful'
import { topPaddedContentContainer } from '@cais-group/homepage/ui/components'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { Disclaimer } from '@cais-group/homepage/ui/disclaimer'
import { PageHeader } from '@cais-group/homepage/ui/page-header'
import { HomepageTabsNavBar } from '@cais-group/homepage/ui/tabs-nav-bar'
import { APP_URL } from '@cais-group/homepage/util/common'
import type { ContentDataType } from '@cais-group/homepage/util/types'

export function ResearchLayout() {
  const disclaimer = useGetDisclaimer({
    id: 'research',
  })

  const featuredResearchData = useGetFeaturedResearch()
  const allResearchData = useGetResearch({
    selectedFilters: {},
  })
  const content = useGetFeaturedResearchContent()

  const allContentItems = useMemo(
    () => [
      ...content.bands.flatMap((band) => band.bandData ?? []),
      ...(allResearchData?.pageData?.allResearch ?? []),
      featuredResearchData?.pageData?.featured ?? ({} as ContentDataType),
    ],
    [content.bands, allResearchData, featuredResearchData]
  )

  // TODO - this is a temporary fix because the loading state on the all-research page causing a flicker when the filter group is loading
  useGetFilterGroupById('homepage/all-research')
  return (
    <>
      <PageHeader title="Research" />
      <HomepageTabsNavBar
        tabs={[
          {
            label: 'Featured',
            link: `${APP_URL}/research/featured`,
          },
          {
            label: 'All Research',
            link: `${APP_URL}/research/all-research`,
          },
        ]}
      />
      <ContentDisclaimer
        items={allContentItems}
        className={topPaddedContentContainer}
      />
      <Outlet />
      <Disclaimer {...disclaimer} />
    </>
  )
}
