import type { ForwardedRef, PropsWithChildren } from 'react'
import { forwardRef } from 'react'

type RootProps = PropsWithChildren<{ isOpen?: boolean }>

export const Root = forwardRef<HTMLDivElement, RootProps>(
  (props: RootProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, isOpen, ...validProps } = props
    return (
      <div ref={ref} className="inline-block" {...validProps}>
        {children}
      </div>
    )
  }
)
