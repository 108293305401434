import { match, P } from 'ts-pattern'

import { PageErrorSplash } from '@cais-group/homepage/ui/components'
import { Skeleton, SkeletonAnimate } from '@cais-group/homepage/ui/skeleton'

import {
  UpcomingLiveEvents,
  UpcomingEventsContainer,
} from './components/upcoming-live-events'
import { usePrepareInPersonEvents } from './hooks/use-prepare-in-person-events'

export function CaisLiveUpcoming() {
  const { liveEvents } = usePrepareInPersonEvents()
  const { pageData, error, loading } = liveEvents

  return match({ pageData, error, loading })
    .with({ error: true }, () => <PageErrorSplash.Inline />)
    .with({ error: false, loading: true }, () => (
      <section aria-label="Loading upcoming CAIS Live events">
        <SkeletonAnimate>
          <UpcomingEventsContainer
            image={
              <div className="h-full w-full">
                <Skeleton height="100%" width="100%" variant="rectangular" />
              </div>
            }
          >
            <div className="space-y-8 [&>:first-child]:mb-24">
              <Skeleton height={32} width="55%" variant="rectangular" />
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="flex justify-between">
                  <Skeleton height={16} width="65%" variant="text" />
                  <Skeleton height={16} width="10%" variant="text" />
                </div>
              ))}
            </div>
          </UpcomingEventsContainer>
        </SkeletonAnimate>
      </section>
    ))
    .with(
      { error: false, pageData: P.array(P.not(P.nullish)) },
      ({ pageData }) => {
        return <UpcomingLiveEvents events={pageData} />
      }
    )
    .otherwise(() => null)
}
