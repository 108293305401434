import { CloudinaryImage } from '@cais-group/shared/ui/cloudinary-image'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'
import type { TypeCloudinaryImage } from '@cais-group/shared/util/type/cloudinary-image'

import { avatarStyles, DEFAULT_AVATAR_SIZE } from './shared'

type AvatarProps = {
  image?: Partial<TypeCloudinaryImage>
  width?: number
}

export function Avatar(props: AvatarProps) {
  const { image, width = DEFAULT_AVATAR_SIZE } = props

  if (image?.public_id) {
    return (
      <CloudinaryImage
        src={image.public_id}
        width={width}
        layout="constrained"
        aspectRatio={1}
        className={avatarStyles}
        alt={image?.context?.alt || ''}
      />
    )
  }

  if (image?.url) {
    return (
      <ContentfulImage
        src={image.url}
        width={width}
        layout="constrained"
        aspectRatio={1}
        className={avatarStyles}
        alt=""
      />
    )
  }
  return null
}
