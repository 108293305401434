import { iconTypes } from '@cais-group/equity/particles/icons'
import { Carousel } from '@cais-group/homepage/ui/components'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import {
  Card,
  CardProps,
  TextCard,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'

import { DEFAULT_BACKGROUND, DEFAULT_LAYOUT } from '../constants'
import { TextCardProps, prepareTextComponentCardProps } from '../helper'
import { ComponentSectionProps } from '../types'

import { CollapsibleSectionContainer } from './collapsible-section-container'
import { SectionHeader } from './section-header'
import { TextContainer } from './text-container'

export function ComponentSection(props: ComponentSectionProps) {
  const { componentSectionDescription, itemsCollection, title, componentId } =
    props

  const items = itemsCollection?.items
  return (
    <>
      <SectionHeader
        title={title}
        componentSectionDescription={componentSectionDescription}
      />
      {items
        ? (() => {
            switch (componentId) {
              case 'Accordions':
                return <CollapsibleSectionContainer items={items} />
              case '2-col':
                return (
                  <TextContainer
                    items={items}
                    options={{
                      layout: DEFAULT_LAYOUT,
                      backgroundColor: DEFAULT_BACKGROUND,
                    }}
                  />
                )
              // case 'Card':
              //   return (
              //     <TextContainer
              //       items={itemsCollection?.items}
              //       options={{
              //         layout: cardContainerOptions || DEFAULT_LAYOUT,
              //         backgroundColor: DEFAULT_BACKGROUND,
              //       }}
              //     />
              //   )
              case 'Carousel': {
                return (
                  <Carousel
                    items={items}
                    // TODO HP-431 - Is there a better way to handle setting size of card?
                    itemMinWidth={items.length > 3 ? 'medium' : 'large'}
                    renderItem={({ item }) => {
                      if (item?.__typename === 'ComponentText') {
                        const props = prepareTextComponentCardProps({
                          ...item,
                          endAdornment: iconTypes.ArrowRight,
                        } as TextCardProps)
                        return <TextCard {...props} variant="text" />
                      } else {
                        const props = item
                          ? prepareBasicCardProps<CardProps>(
                              item as ContentDataType
                            )
                          : null
                        return props ? (
                          <Card {...props} date={undefined} />
                        ) : null
                      }
                    }}
                    testId="landing-page"
                  />
                )
              }
              case '1-col':
                return (
                  <TextContainer
                    items={items}
                    options={{
                      layout: '1col',
                      backgroundColor: DEFAULT_BACKGROUND,
                    }}
                  />
                )
              default:
                return null
            }
          })()
        : null}
    </>
  )
}
