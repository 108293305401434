import { forwardRef } from 'react'

import { Pill } from '@cais-group/equity/atoms/pill'
import type { InputProps } from '@cais-group/equity/molecules/select'

export const generateInputComponent = (staticLabel: string = '') =>
  forwardRef<HTMLInputElement, InputProps>(
    ({ selectedOptionsLength, placeholder = '', isOpen, children }, ref) => {
      const label = children
        ? children
        : selectedOptionsLength
        ? `${placeholder || staticLabel} • ${selectedOptionsLength}`
        : placeholder || staticLabel

      return (
        <Pill
          ariaLabel={`${placeholder || staticLabel}-toggle`}
          // @ts-ignore
          ref={ref}
          label={label}
          state={selectedOptionsLength && !children ? 'active' : 'regular'}
          selected={isOpen}
          type="dropdown"
        />
      )
    }
  )
