import { useTrack, useTrackState } from '@cais-group/shared/util/segment'

import { UseTrackStateToTrack } from './types'

export const getSearchDataToTrack = <
  T extends { items: Array<{ id: string }>; search_term: string }
>(
  state: T
) => ({
  search: {
    search_term: state.search_term,
    first_ten_results: state.items.slice(0, 10).map(({ id }) => id),
    results_count: state.items.length,
  },
})

export const useTrackQuickSearch = (
  state: UseTrackStateToTrack,
  shouldTrack: () => boolean,
  track: ReturnType<typeof useTrack>,
  event: string
) => {
  useTrackState({
    state,
    defaultState: state,
    shouldTrack,
    event: event,
    getData: getSearchDataToTrack,
    track,
  })
}
