import { useEffect, useRef } from 'react'

import { logError } from '@cais-group/shared/util/logging'
import { useSegment } from '@cais-group/shared/util/segment'

/**
 * A helper hook that augments the page event payload with additional data obtained from the provided function.
 * NOTE: If the passed function throws an error, the additional data will be missing from the payload.
 */
export function useAugmentPageEventPayload(
  getAdditionalData: () => Record<string, unknown>
) {
  const segment = useSegment()
  const invokedRef = useRef(false)

  useEffect(() => {
    segment?.addSourceMiddleware(({ payload, next }) => {
      let additionalData: Record<string, unknown> = {}
      try {
        additionalData = getAdditionalData()
      } catch (e) {
        logError({
          message: 'Failed to get additional data for page event payload',
          error: e,
        })
      }
      if (
        payload.obj.type === 'page' &&
        Object.values(additionalData).length > 0 &&
        invokedRef.current === false
      ) {
        if (payload.obj.properties) {
          payload.obj.properties = {
            ...payload.obj.properties,
            ...additionalData,
          }
        }
        invokedRef.current = true
        next(payload)
      } else {
        next(payload)
      }
    })

    return () => {
      // Remove added middleware when hook unmounts.
      segment?.addSourceMiddleware(({ payload, next }) => next(payload))
    }
  }, [getAdditionalData, segment])
}
