import { INLINES } from '@contentful/rich-text-types'
import { MouseEventHandler } from 'react'

import { ButtonLink } from '@cais-group/equity/atoms/button'
import { isExternalLink } from '@cais-group/equity/atoms/link'
import { IconType } from '@cais-group/equity/particles/icons'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { ComponentText } from '@cais-group/shared/util/graphql/mfe-contentful'

type SplitRichText = {
  text: ComponentText['text']
  className: string
}[]
export type RichTextDescriptionProps = {
  text: ComponentText['text']
  endAdornment?: IconType
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

export type TextCardProps = RichTextDescriptionProps & {
  title?: ComponentText['title']
  sys?: { id: string }
}

export const prepareTextComponentCardProps = (item: TextCardProps) => {
  const { title, text, sys, ...rest } = item

  return {
    title: title || '',
    description: prepareRichTextDescription({
      text,
      ...rest,
    }),
    testId: sys?.id,
  }
}

export const prepareRichTextDescription = (props: RichTextDescriptionProps) => {
  const { text, endAdornment, onClick } = props
  if (!text) {
    return null
  }
  const hasLinks = text?.json?.content?.some(
    (paragraph: { content: { nodeType: string }[] }) =>
      paragraph?.content?.some(
        (node: { nodeType: string }) => node.nodeType === 'hyperlink'
      )
  )
  const splitRichText = hasLinks
    ? (text?.json.content?.reduce(
        (acc: SplitRichText, curr: SplitRichText, index: number) => {
          if (index < text.json?.content?.length - 2) {
            acc[0].text?.json.content.push(curr)
            if (text.links.entries.hyperlink[index]) {
              acc[0].text?.links.entries.hyperlink.push(
                text.links.entries.hyperlink[index]
              )
            }
            if (text.links.entries.inline[index]) {
              acc[0].text?.links.entries.inline.push(
                text.links.entries.inline[index]
              )
            }
          } else {
            acc[1].text?.json.content.push(curr)
            if (text.links.entries.hyperlink[index]) {
              acc[1].text?.links.entries.hyperlink.push(
                text.links.entries.hyperlink[index]
              )
            }
            if (text.links.entries.inline[index]) {
              acc[1].text?.links.entries.inline.push(
                text.links.entries.inline[index]
              )
            }
          }
          return acc
        },
        [
          {
            className: 'md:space-y-24 space-y-16',
            text: {
              json: {
                content: [],
              },
              links: {
                entries: {
                  hyperlink: [],
                  inline: [],
                },
              },
            },
          },

          {
            className: 'mt-auto',
            text: {
              json: {
                content: [],
              },
              links: {
                entries: {
                  hyperlink: [],
                  inline: [],
                },
              },
            },
          },
        ]
      ) as SplitRichText)
    : [{ text: text, className: 'md:space-y-24 space-y-16' }]

  return splitRichText.map((desc, index) => (
    <div key={index} className={desc.className}>
      <RichText
        field={desc.text}
        renderNode={{
          [INLINES.HYPERLINK]: (node, children) => {
            const href = node.data['uri']
            const externalLink = isExternalLink(href)

            return (
              <ButtonLink
                variant="tertiary"
                endAdornment={
                  endAdornment
                    ? endAdornment
                    : externalLink
                    ? 'Launch'
                    : 'ArrowRight'
                }
                href={href}
                onClick={onClick}
              >
                {children}
              </ButtonLink>
            )
          },
        }}
      />
    </div>
  ))
}
