import { RecoilRoot } from 'recoil'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { Announcement } from '@cais-group/equity/labs/announcement'
import { Footer } from '@cais-group/equity/molecules/footer'
import { useEquityTracking } from '@cais-group/equity/util/tracking-utils'
import { usePageTracking } from '@cais-group/homepage/util/tracking'
import { APP_IDS } from '@cais-group/shared/domain/apps'
import { useAnnouncements } from '@cais-group/shared/domain/contentful/announcements'
import {
  previewService,
  usePreviewService,
} from '@cais-group/shared/util/contentful/preview-service'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'
import {
  useTrackItemClicked,
  initUrlToSectionMap,
} from '@cais-group/shared/util/segment'

import { PreviewBannerContainer } from './components/previewBannerContainer'
import { ScrollToTop } from './components/scroll-to-top'
import { Router } from './router'
import { userSettingsService } from './screens/home'

import '../styles.css'

const AppRoutes = () => {
  const mapUrlToSection = initUrlToSectionMap(APP_IDS.HOMEPAGE)
  const trackClick = useTrackItemClicked(mapUrlToSection)

  usePreviewService()
  usePageTracking()
  useEquityTracking(trackClick)

  const { userProfileState } = useUserProfile()
  userSettingsService.initiateUser(userProfileState.userProfile)
  previewService.setResolver(userSettingsService)

  const isAdmin = userSettingsService.hasRole('portal:ROLE_CAISIQ_ADMIN')

  const { announcement } = useAnnouncements(
    {
      id: 'homepage',
    },
    {
      allowSingleDismissalPerSession: true,
    }
  )
  return (
    <RecoilRoot>
      <ScrollToTop />
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        {isAdmin && <PreviewBannerContainer />}
        {announcement ? <Announcement {...announcement} /> : null}
        <Router />
      </QueryParamProvider>
    </RecoilRoot>
  )
}

export default function HomepageApp() {
  return (
    <div className="bg-neutral-0 flex min-h-[calc(100vh-theme(constants.mainMenu.height)px)] flex-col">
      <AppRoutes />
      <Footer />
    </div>
  )
}
