import { Maybe } from '@cais-group/shared/util/graphql'

import { useAugmentPageEventPayload } from './use-segment-middleware'

export type GetDataFn = () => { fund_ids: Maybe<Maybe<string>[]> | undefined }

export interface WithAugmentedPageTrackingProps {
  getData: GetDataFn
}

export function WithAugmentedPageTracking(
  props: WithAugmentedPageTrackingProps
) {
  useAugmentPageEventPayload(props.getData)
  return null
}
