import { BLOCKS } from '@contentful/rich-text-types'

import {
  CloudinaryImage,
  cloudinaryUrl,
} from '@cais-group/shared/ui/cloudinary-image'
import {
  FrontMatter,
  prepareFrontMatterProps,
} from '@cais-group/shared/ui/contentful/common/front-matter'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'
import { PageResource as PageResourceType } from '@cais-group/shared/util/graphql/mfe-contentful'
import { TypeCloudinaryImage } from '@cais-group/shared/util/type/cloudinary-image'

import { ArticleLayout } from './components/layout'

export function PageResource(props: PageResourceType & { urlPrefix: string }) {
  const { body, hero: imageSet, introduction, urlPrefix } = props

  const hero = imageSet[0] as TypeCloudinaryImage

  const isCloudinaryUrl = Boolean(cloudinaryUrl(hero?.public_id))

  return (
    <ArticleLayout
      hero={
        isCloudinaryUrl ? (
          <CloudinaryImage
            src={hero.public_id}
            layout="fullWidth"
            aspectRatio={4}
            alt={hero?.context?.alt || 'hero-image'}
            className="h-full max-h-[480px] w-full"
          />
        ) : (
          <ContentfulImage
            src={hero.url}
            layout="fullWidth"
            alt={hero?.context?.alt || 'hero-image'}
            priority
            background="auto"
            className="h-full max-h-[480px] w-full"
          />
        )
      }
      header={
        <FrontMatter
          {...prepareFrontMatterProps<PageResourceType>(props)}
          eyebrow="PDF"
        />
      }
      body={
        <>
          {introduction?.json && (
            <span
              data-testid="page-introduction"
              className="page-resource-intro block max-w-full space-y-32"
            >
              <RichText
                field={introduction}
                urlPrefix={urlPrefix}
                renderNode={{
                  [BLOCKS.HEADING_1]: (_node, children) => (
                    <h1 className="headline-s">{children}</h1>
                  ),
                }}
              />
            </span>
          )}
          {body?.json && (
            <span
              data-testid="page-body"
              className="page-resource-body block max-w-full space-y-32"
            >
              <RichText field={body} urlPrefix={urlPrefix} />
            </span>
          )}
        </>
      }
    />
  )
}
