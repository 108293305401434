import type {
  Color,
  TailwindEquityColor,
} from '@cais-group/equity/particles/colors'

import type { AnnouncementVariant } from './types'

export const announcementTypeToBackgroundColorMap: Record<
  AnnouncementVariant,
  TailwindEquityColor
> = {
  primary: 'bg-brand-200',
}

export const announcementTypeToIconColorMap: Record<
  AnnouncementVariant,
  Color
> = {
  primary: 'eq-color-brand-600',
}
