type FaqPermalinkProps = {
  thisFaqId: string
  categoryId?: string
  isOpen: boolean
}

export function faqPermalink(props: FaqPermalinkProps) {
  const { categoryId, isOpen, thisFaqId } = props

  let path = ''

  if (!thisFaqId) {
    return path
  }

  const windowPathnameParts = window.location.pathname.split(`/${categoryId}`)

  if (isOpen && windowPathnameParts.length > 1) {
    path = `/${categoryId}`
  } else {
    path = `/${categoryId}/${thisFaqId}`
  }

  return `${windowPathnameParts[0]}${path}`.replace('//', '/')
}
