import { contentContainer } from '@cais-group/homepage/ui/components'

export type DashboardHeaderProps = {
  userName?: string
  welcomeMessage?: string | null
}

export function DashboardHeader(props: DashboardHeaderProps) {
  const { userName, welcomeMessage } = props

  return (
    <header
      role="banner"
      className={`text-brand-600-c bg-brand-800 break-all pb-16 md:pb-32 ${
        welcomeMessage ? 'pt-24' : 'pt-8 md:pt-32'
      }`}
    >
      <h1 className={`${contentContainer} break-all`}>
        <span
          className={`body-strong line-clamp-1 ${
            welcomeMessage ? 'opacity-75' : 'opacity-0 after:content-["-"]'
          }`}
          aria-hidden={!welcomeMessage} // Note: attempt to avoid layout shift
        >
          {welcomeMessage}
        </span>
        {userName ? (
          <span className="headline-s-strong md:headline-m-strong line-clamp-2">
            Welcome, {userName}
          </span>
        ) : null}
      </h1>
    </header>
  )
}
