import { Accordion } from '@cais-group/shared/ui/accordion'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import type { CaisiqArticleDropdown } from '@cais-group/shared/util/type/article-data'

type AccordionsProps = {
  accordions: CaisiqArticleDropdown[]
}

export function Accordions({ accordions }: AccordionsProps) {
  return (
    <div>
      {accordions.map((accordion, i) => (
        <Accordion
          key={`accordion-${i}`}
          summaryFlexDirection="row"
          noPadding
          testId="guide"
          children={<RichText field={accordion?.body} />}
          summary={<RichText field={accordion?.title} />}
          className="[&_:is(.MuiAccordionSummary-content,.MuiAccordionSummary-expandIconWrapper)]:text-primary-600 [&_.MuiAccordionSummary-content>p]:body-strong [&_.MuiAccordionDetails-root>:is(ol,ul)]:space-y-24 [&_.MuiAccordionDetails-root]:space-y-24"
        />
      ))}
    </div>
  )
}
