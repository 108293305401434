import { useLocation } from 'react-router-dom'

import {
  useGetResearch,
  FILTERS,
  CAROUSEL_ITEM_LIMIT,
} from '@cais-group/homepage/domain/contentful'
import { useGetCallToAction } from '@cais-group/shared/domain/contentful/call-to-action'
import { useGetEvents } from '@cais-group/shared/domain/contentful/events'
import { useGetAllVideos } from '@cais-group/shared/domain/contentful/video'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

import { usePrepareSummitCarousel } from './usePrepareSummitCarousel'

const OVER_FETCH_LIMIT = 2 * CAROUSEL_ITEM_LIMIT

export const usePrepareInPersonEvents = () => {
  const { pathname } = useLocation()
  const callToAction = useGetCallToAction({
    id: pathname,
  })

  const liveEvents = useGetEvents(
    {
      where: {
        eventType: 'In-Person',
        dateTime_gte: today(),
      },
      sortOrder: 'dateTime_ASC',
      limit: 20,
    },
    { queryKey: 'cais-live-events' }
  )
  const { whereFilter, queryString } = usePrepareSummitCarousel() ?? {}
  const summitReplays = useGetAllVideos(
    {
      where: whereFilter,
      sortOrder: 'displayDate_DESC',
      limit: OVER_FETCH_LIMIT,
    },
    { queryKey: 'cais-live-summit-replays', enabled: Boolean(whereFilter) }
  )
  const caisReplays = useGetResearch(
    {
      selectedFilters: {},
      defaultFilters: {
        ...FILTERS.inPersonEvents.is,
      },
      searchText: '',
      limit: 24,
    },
    {
      queryKey: ['cais-live-replays'],
    }
  )

  return {
    liveEvents,
    summitReplays: {
      ...summitReplays,
      queryString,
    },
    caisReplays,
    callToAction,
  }
}
