import { layoutCols3, StickySidebar } from '@cais-group/homepage/ui/layout'

import { Prefix } from './constants'
import { AtoZ } from './list'
import { AtoZNav } from './nav'
import { sortAndGroup } from './sort'
import type { AtoZEntry } from './types'

export type AtoZLayoutProps = {
  list: AtoZEntry[]
  prefix?: string
}

export function AtoZLayout(props: AtoZLayoutProps) {
  const { Layout, slots } = layoutCols3()
  const { list, prefix = Prefix } = props
  const sortedList = sortAndGroup(list)

  return (
    <Layout testId={prefix}>
      <StickySidebar className={slots[0]}>
        <AtoZNav list={sortedList} prefix={prefix} />
      </StickySidebar>
      <div className={`${slots[1]} pb-32`}>
        <AtoZ list={sortedList} prefix={prefix} />
      </div>
    </Layout>
  )
}
