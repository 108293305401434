import {
  FIRMS_COLLECTION_LIMIT,
  TAGS_COLLECTION_LIMIT,
  filterAllowedPermissionData,
} from '@cais-group/homepage/domain/contentful'
import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import type { EventType } from '@cais-group/homepage/util/types'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
} from '@cais-group/shared/domain/contentful/api'
import {
  GetEventsQuery,
  EventFilter,
  EventOrder,
  useGetEventsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

type SortOrder = keyof {
  [K in EventOrder as `${K}`]: EventOrder
}

type Where = Partial<EventFilter>

type QueryVariables = {
  limit?: number
  sortOrder?: SortOrder
  where?: Where
}
type QueryOptions = { enabled?: boolean; queryKey?: string }

export const useGetEvents = (
  variables?: QueryVariables,
  options?: QueryOptions
) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()

  const sortOrder = variables?.sortOrder || 'dateTime_DESC'

  const queryParams = {
    preview: false,
    limit: variables?.limit || 20,
    where: variables?.where,
    order: sortOrder as EventOrder,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
    tagsLimit: TAGS_COLLECTION_LIMIT,
  }

  const response = useReactQueryResultAsApiState<GetEventsQuery, EventType[]>(
    useGetEventsQuery(queryParams, {
      enabled: options?.enabled || true,
      queryKey: [options?.queryKey || 'all-events'],
      refetchOnWindowFocus: false,
    }),
    (data) =>
      filterAllowedPermissionData(
        selectEvents(data),
        allowedUserPermissions.data
      ),
    'Could not load getEventsQuery'
  )
  const loading = response === ApiStateEnum.LOADING
  const error = isError(response)

  return compileResults(response, loading, error)
}

const compileResults = (
  data: EventType[] | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

export const selectEvents = (data: GetEventsQuery) => {
  return (data.eventCollection?.items ?? []) as EventType[]
}
