import { Markdown } from '@cais-group/equity/atoms/markdown'
import { FileDownload } from '@cais-group/equity/labs/file-download'
import { capitalizeFirstLetter } from '@cais-group/equity/utilitarian'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { trackingLabels } from '@cais-group/homepage/util/tracking'
import {
  FrontMatter,
  prepareFrontMatterProps,
} from '@cais-group/shared/ui/contentful/common/front-matter'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { Footnotes } from './components/footnotes'
import { ArticleLayout } from './components/layout'
import { Sidebar } from './components/sidebar'

export function Whitepaper(props: WhitepaperData) {
  const {
    body,
    hero,
    introduction,
    footnotes,
    pdfsCollection,
    urlPrefix,
    topicsCollection,
    authorsCollection,
    category,
  } = props

  return (
    <ArticleLayout
      hero={
        hero?.url ? (
          <ContentfulImage
            priority
            background="auto"
            layout="fullWidth"
            src={hero.url}
            className="h-full max-h-[480px]"
          />
        ) : null
      }
      header={
        <FrontMatter
          {...prepareFrontMatterProps<WhitepaperData>(props)}
          eyebrow="PDF"
        />
      }
      body={
        <>
          <ContentDisclaimer items={[props]} />
          {pdfsCollection && pdfsCollection.items.length > 0 ? (
            <div className="!mb-56 space-y-16">
              {pdfsCollection.items.map((pdf) => {
                return (
                  <FileDownload
                    key={pdf?.sys.id}
                    source={pdf?.pdf?.url || ''}
                    title={pdf?.title || ''}
                    tracking={{
                      section: trackingLabels.section.Insights,
                      sub_section: capitalizeFirstLetter(category || ''),
                    }}
                  />
                )
              })}
            </div>
          ) : null}
          {introduction && (
            <Markdown
              options={{
                overrides: {
                  p: {
                    props: {
                      className: 'headline-s',
                    },
                  },
                },
              }}
            >
              {introduction}
            </Markdown>
          )}
          {body?.json && <RichText field={body} urlPrefix={urlPrefix} />}
        </>
      }
      footer={
        footnotes && footnotes.json && <Footnotes footnotes={footnotes} />
      }
      sidebar={
        <Sidebar
          tags={topicsCollection?.items}
          authors={authorsCollection?.items}
        />
      }
    />
  )
}
