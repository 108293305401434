/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Identity and Access Management (IAM) API
 * API for Identity and Access Management within CAIS
 * OpenAPI spec version: v1
 */
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstance, ErrorType } from '@cais-group/shared/util/fetch'

import type {
  AcceptTermsAndConditionsInputV1,
  AttributesRequestV1,
  BulkRefreshRequestV1,
  BulkRefreshResponseV1,
  CapabilityIdsBody,
  CapabilityV1,
  ConnectionTypeRequestV1,
  CreatePolicyRequestV1,
  CreateTermsAndConditionsRequestV1,
  DataSimulationRequestV1,
  DeleteTestDataRequestV1,
  ErrorResponse,
  FeatureFlagDefinitionResponseV1,
  FeatureFlagDefinitionSearchRequestV1,
  FeatureFlagDefinitionWithSummaryResponseV1,
  FeatureFlagsForAudiencesRequestV1,
  FeatureFlagsForAudiencesResponseV1,
  FirmFeatureFlagSearchRequestV1,
  FirmRequestV1,
  FirmRoleChangeRequestV1,
  FirmRoleChangeResultV1,
  FirmRoleRecertificationRequestV1,
  FirmRoleRecertificationResultV1,
  FirmRoleRequestV1,
  FirmSearchRequestV1,
  FirmStatsV1,
  FirmV1,
  GetFirmByNameV1Params,
  GetResourcesPermissionsV1200,
  GetResourcesPermissionsV1Params,
  GetUserByExternalIdOrEmailV1Params,
  GetUserRegionsReportV1Params,
  GetUserRegionsV1Params,
  IdNameTupleV1,
  ImportUsersConfigCreateOrUpdateRequestV1,
  ImportUsersConfigResponseV1,
  MigrateAllFirmsV1Params,
  MigrateCaisUsersV1Params,
  MigrateFirmV1Params,
  MigrateTeamV1Params,
  MigrateUserV1Params,
  MobileValidationResponseV1,
  NestedHierarchyEdgeRequestV1,
  NodeEntityV1,
  PagedFeatureFlagDefinitionsV1,
  PagedFirmFeatureFlagsV1,
  PagedFirmsV1,
  PagedRoleWorkflowItemsV1,
  PagedRolesV1,
  PagedServiceIdentitiesV1,
  PagedTeamFeatureFlagsV1,
  PagedTeamsV1,
  PagedUserFeatureFlagsV1,
  PagedUsersV1,
  PatchRequestBodyBody,
  PermissionV1,
  PolicyResultV1,
  PolicyV1,
  RefresherStatusV1,
  RegionResultsReportItemV1,
  RegionsV1,
  RoleChangeSubmissionActionInputV1,
  RoleChangeSubmissionV1,
  RoleEnablementRequestV1,
  RoleIdsBody,
  RoleRecertificationActionInputV1,
  RoleRecertificationV1,
  RoleSearchRequestV1,
  RoleV1,
  RoleWorkflowItemSearchRequestV1,
  SaveEntityPolicyRequestsBody,
  SearchFirmsNamesV1Params,
  ServiceIdentityCreateResponseV1,
  ServiceIdentityRequestV1,
  ServiceIdentityRotateSecretResponseV1,
  ServiceIdentitySearchRequestV1,
  ServiceIdentityV1,
  SimulationV1,
  SsoProviderRequestV1,
  SsoProviderV1,
  SsoUserResolutionRequestV1,
  StartSwitchUserResponseV1,
  TeamFeatureFlagSearchRequestV1,
  TeamRequestV1,
  TeamSearchRequestV1,
  TeamStatsV1,
  TeamV1,
  TermsAndConditionsV1,
  UUIDListV1,
  UpdateFirmByExternalIdV1Params,
  UpdateTeamByExternalIdV1Params,
  UpdateUserByExternalIdV1Params,
  UploadFirmRolesCsvV1Body,
  UploadUserRolesCsvV1Body,
  UserAuthDetailsV1,
  UserFeatureFlagSearchRequestV1,
  UserPasswordLoginAccessResponseV1,
  UserProfileV1,
  UserRequestV1,
  UserSearchRequestV1,
  UserSsoMappingsBody,
  UserStatusV1,
  UserTermsAndConditionsV1,
  UserV1,
} from './api.schemas'

/**
 * **Permission:** `iam:users:read`

Get user by externalId or email
 * @summary Get User by External ID or Email
 */
export const getUserByExternalIdOrEmailV1 = (
  params?: GetUserByExternalIdOrEmailV1Params,
  signal?: AbortSignal
) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/users`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetUserByExternalIdOrEmailV1QueryKey = (
  params?: GetUserByExternalIdOrEmailV1Params
) => {
  return [`/iam/v1/users`, ...(params ? [params] : [])] as const
}

export const getGetUserByExternalIdOrEmailV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetUserByExternalIdOrEmailV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserByExternalIdOrEmailV1QueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>
  > = ({ signal }) => getUserByExternalIdOrEmailV1(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserByExternalIdOrEmailV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>
>
export type GetUserByExternalIdOrEmailV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User by External ID or Email
 */
export const useGetUserByExternalIdOrEmailV1 = <
  TData = Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetUserByExternalIdOrEmailV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserByExternalIdOrEmailV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserByExternalIdOrEmailV1QueryOptions(
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * *** Permission:** `iam:users:write`

Create or update a user by external ID
 * @summary Create or Update User by External ID
 */
export const updateUserByExternalIdV1 = (
  userRequestV1: UserRequestV1,
  params?: UpdateUserByExternalIdV1Params
) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/users`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: userRequestV1,
    params,
  })
}

export const getUpdateUserByExternalIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserByExternalIdV1>>,
    TError,
    { data: UserRequestV1; params?: UpdateUserByExternalIdV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserByExternalIdV1>>,
  TError,
  { data: UserRequestV1; params?: UpdateUserByExternalIdV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserByExternalIdV1>>,
    { data: UserRequestV1; params?: UpdateUserByExternalIdV1Params }
  > = (props) => {
    const { data, params } = props ?? {}

    return updateUserByExternalIdV1(data, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateUserByExternalIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserByExternalIdV1>>
>
export type UpdateUserByExternalIdV1MutationBody = UserRequestV1
export type UpdateUserByExternalIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create or Update User by External ID
 */
export const useUpdateUserByExternalIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserByExternalIdV1>>,
    TError,
    { data: UserRequestV1; params?: UpdateUserByExternalIdV1Params },
    TContext
  >
}) => {
  const mutationOptions = getUpdateUserByExternalIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Create a new user - Users can be immediately put into teams or firms by including parents
 * @summary Create User
 */
export const createUserV1 = (userRequestV1: UserRequestV1) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userRequestV1,
  })
}

export const getCreateUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserV1>>,
    TError,
    { data: UserRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserV1>>,
  TError,
  { data: UserRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserV1>>,
    { data: UserRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createUserV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserV1>>
>
export type CreateUserV1MutationBody = UserRequestV1
export type CreateUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create User
 */
export const useCreateUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserV1>>,
    TError,
    { data: UserRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Return all users or a subset of users using searching and filtering in a sorted and paged list
 * @summary Search Users
 */
export const searchUsersV1 = (userSearchRequestV1: UserSearchRequestV1) => {
  return fetchInstance<PagedUsersV1>({
    url: `/iam/v1/users/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userSearchRequestV1,
  })
}

export const getSearchUsersV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUsersV1>>,
    TError,
    { data: UserSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchUsersV1>>,
  TError,
  { data: UserSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchUsersV1>>,
    { data: UserSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchUsersV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchUsersV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchUsersV1>>
>
export type SearchUsersV1MutationBody = UserSearchRequestV1
export type SearchUsersV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Users
 */
export const useSearchUsersV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUsersV1>>,
    TError,
    { data: UserSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchUsersV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Get a specific user by their userId
 * @summary Get User by ID
 */
export const getUserByIdV1 = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/users/${userId}`,
    method: 'GET',
    signal,
  })
}

export const getGetUserByIdV1QueryKey = (userId: string) => {
  return [`/iam/v1/users/${userId}`] as const
}

export const getGetUserByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdV1QueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserByIdV1>>> = ({
    signal,
  }) => getUserByIdV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserByIdV1>>
>
export type GetUserByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User by ID
 */
export const useGetUserByIdV1 = <
  TData = Awaited<ReturnType<typeof getUserByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserByIdV1QueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:users:write`

Update a user
 * @summary Update User
 */
export const updateUserV1 = (userId: string, userRequestV1: UserRequestV1) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/users/${userId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: userRequestV1,
  })
}

export const getUpdateUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserV1>>,
    TError,
    { userId: string; data: UserRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserV1>>,
  TError,
  { userId: string; data: UserRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserV1>>,
    { userId: string; data: UserRequestV1 }
  > = (props) => {
    const { userId, data } = props ?? {}

    return updateUserV1(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserV1>>
>
export type UpdateUserV1MutationBody = UserRequestV1
export type UpdateUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update User
 */
export const useUpdateUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserV1>>,
    TError,
    { userId: string; data: UserRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Delete the user with the provided userId
 * @summary Delete User by ID
 */
export const deleteUserByIdV1 = (userId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}`,
    method: 'DELETE',
  })
}

export const getDeleteUserByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserByIdV1>>,
    TError,
    { userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserByIdV1>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserByIdV1>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return deleteUserByIdV1(userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteUserByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserByIdV1>>
>

export type DeleteUserByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete User by ID
 */
export const useDeleteUserByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserByIdV1>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteUserByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Perform a partial update on a user
 * @summary Patch Update User
 */
export const patchUpdateUserV1 = (
  userId: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/users/${userId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getPatchUpdateUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateUserV1>>,
    TError,
    { userId: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUpdateUserV1>>,
  TError,
  { userId: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUpdateUserV1>>,
    { userId: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { userId, data } = props ?? {}

    return patchUpdateUserV1(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchUpdateUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUpdateUserV1>>
>
export type PatchUpdateUserV1MutationBody = PatchRequestBodyBody
export type PatchUpdateUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Patch Update User
 */
export const usePatchUpdateUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateUserV1>>,
    TError,
    { userId: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchUpdateUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Get user's regions
 * @summary Get User Regions
 */
export const getUserRegionsV1 = (
  userId: string,
  params: GetUserRegionsV1Params,
  signal?: AbortSignal
) => {
  return fetchInstance<RegionsV1>({
    url: `/iam/v1/users/${userId}/regions`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetUserRegionsV1QueryKey = (
  userId: string,
  params: GetUserRegionsV1Params
) => {
  return [
    `/iam/v1/users/${userId}/regions`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetUserRegionsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserRegionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  params: GetUserRegionsV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserRegionsV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserRegionsV1QueryKey(userId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserRegionsV1>>
  > = ({ signal }) => getUserRegionsV1(userId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserRegionsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserRegionsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserRegionsV1>>
>
export type GetUserRegionsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Regions
 */
export const useGetUserRegionsV1 = <
  TData = Awaited<ReturnType<typeof getUserRegionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  params: GetUserRegionsV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserRegionsV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserRegionsV1QueryOptions(userId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Diagnostic report for user's regions
 * @summary Get User Regions Report
 */
export const getUserRegionsReportV1 = (
  userId: string,
  params: GetUserRegionsReportV1Params,
  signal?: AbortSignal
) => {
  return fetchInstance<RegionResultsReportItemV1[]>({
    url: `/iam/v1/users/${userId}/regions/report`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetUserRegionsReportV1QueryKey = (
  userId: string,
  params: GetUserRegionsReportV1Params
) => {
  return [
    `/iam/v1/users/${userId}/regions/report`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetUserRegionsReportV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserRegionsReportV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  params: GetUserRegionsReportV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserRegionsReportV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserRegionsReportV1QueryKey(userId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserRegionsReportV1>>
  > = ({ signal }) => getUserRegionsReportV1(userId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserRegionsReportV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserRegionsReportV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserRegionsReportV1>>
>
export type GetUserRegionsReportV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Regions Report
 */
export const useGetUserRegionsReportV1 = <
  TData = Awaited<ReturnType<typeof getUserRegionsReportV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  params: GetUserRegionsReportV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserRegionsReportV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserRegionsReportV1QueryOptions(
    userId,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:users:write`

Reset a user to initial state
 * @summary Reset User
 */
export const resetUserV1 = (userId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}/reset`,
    method: 'PUT',
  })
}

export const getResetUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserV1>>,
    TError,
    { userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetUserV1>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetUserV1>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return resetUserV1(userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResetUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof resetUserV1>>
>

export type ResetUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Reset User
 */
export const useResetUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserV1>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const mutationOptions = getResetUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Replace all roles for a user with a new roles list
 * @summary Replace User Roles
 */
export const replaceUserRolesV1 = (
  userId: string,
  roleIdsBody: RoleIdsBody
) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}/roles`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: roleIdsBody,
  })
}

export const getReplaceUserRolesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceUserRolesV1>>,
    TError,
    { userId: string; data: RoleIdsBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof replaceUserRolesV1>>,
  TError,
  { userId: string; data: RoleIdsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replaceUserRolesV1>>,
    { userId: string; data: RoleIdsBody }
  > = (props) => {
    const { userId, data } = props ?? {}

    return replaceUserRolesV1(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ReplaceUserRolesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof replaceUserRolesV1>>
>
export type ReplaceUserRolesV1MutationBody = RoleIdsBody
export type ReplaceUserRolesV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Replace User Roles
 */
export const useReplaceUserRolesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceUserRolesV1>>,
    TError,
    { userId: string; data: RoleIdsBody },
    TContext
  >
}) => {
  const mutationOptions = getReplaceUserRolesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Update the existing list of user roles
 * @summary Update User Roles
 */
export const updateUserRolesV1 = (
  userId: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}/roles`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getUpdateUserRolesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserRolesV1>>,
    TError,
    { userId: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserRolesV1>>,
  TError,
  { userId: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserRolesV1>>,
    { userId: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { userId, data } = props ?? {}

    return updateUserRolesV1(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateUserRolesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserRolesV1>>
>
export type UpdateUserRolesV1MutationBody = PatchRequestBodyBody
export type UpdateUserRolesV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update User Roles
 */
export const useUpdateUserRolesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserRolesV1>>,
    TError,
    { userId: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions = getUpdateUserRolesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Replace all external SSO identity mappings for a user with a new list
 * @summary Replace User SSO Mappings
 */
export const replaceUserSsoMappingsV1 = (
  userId: string,
  userSsoMappingsBody: UserSsoMappingsBody
) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}/sso-mappings`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: userSsoMappingsBody,
  })
}

export const getReplaceUserSsoMappingsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceUserSsoMappingsV1>>,
    TError,
    { userId: string; data: UserSsoMappingsBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof replaceUserSsoMappingsV1>>,
  TError,
  { userId: string; data: UserSsoMappingsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replaceUserSsoMappingsV1>>,
    { userId: string; data: UserSsoMappingsBody }
  > = (props) => {
    const { userId, data } = props ?? {}

    return replaceUserSsoMappingsV1(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ReplaceUserSsoMappingsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof replaceUserSsoMappingsV1>>
>
export type ReplaceUserSsoMappingsV1MutationBody = UserSsoMappingsBody
export type ReplaceUserSsoMappingsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Replace User SSO Mappings
 */
export const useReplaceUserSsoMappingsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceUserSsoMappingsV1>>,
    TError,
    { userId: string; data: UserSsoMappingsBody },
    TContext
  >
}) => {
  const mutationOptions = getReplaceUserSsoMappingsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Get the password login access status for a user
 * @summary Get User Password Login Access
 */
export const getUserPasswordLoginAccessV1 = (
  userId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<UserPasswordLoginAccessResponseV1>({
    url: `/iam/v1/users/${userId}/password-login-access`,
    method: 'GET',
    signal,
  })
}

export const getGetUserPasswordLoginAccessV1QueryKey = (userId: string) => {
  return [`/iam/v1/users/${userId}/password-login-access`] as const
}

export const getGetUserPasswordLoginAccessV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserPasswordLoginAccessV1QueryKey(userId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>
  > = ({ signal }) => getUserPasswordLoginAccessV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserPasswordLoginAccessV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>
>
export type GetUserPasswordLoginAccessV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Password Login Access
 */
export const useGetUserPasswordLoginAccessV1 = <
  TData = Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserPasswordLoginAccessV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserPasswordLoginAccessV1QueryOptions(
    userId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:users:write`

Resend the welcome email for a pending user
 * @summary Resend Invitation Email
 */
export const resendInvitationEmailV1 = (userId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}/welcome-email/resend`,
    method: 'POST',
  })
}

export const getResendInvitationEmailV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resendInvitationEmailV1>>,
    TError,
    { userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof resendInvitationEmailV1>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resendInvitationEmailV1>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return resendInvitationEmailV1(userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResendInvitationEmailV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof resendInvitationEmailV1>>
>

export type ResendInvitationEmailV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Resend Invitation Email
 */
export const useResendInvitationEmailV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resendInvitationEmailV1>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const mutationOptions = getResendInvitationEmailV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Fetch the profile of a specific user
 * @summary Get User Profile
 */
export const getUserProfileV1 = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<UserProfileV1>({
    url: `/iam/v1/users/${userId}/profile`,
    method: 'GET',
    signal,
  })
}

export const getGetUserProfileV1QueryKey = (userId: string) => {
  return [`/iam/v1/users/${userId}/profile`] as const
}

export const getGetUserProfileV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserProfileV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserProfileV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserProfileV1QueryKey(userId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserProfileV1>>
  > = ({ signal }) => getUserProfileV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserProfileV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserProfileV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserProfileV1>>
>
export type GetUserProfileV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Profile
 */
export const useGetUserProfileV1 = <
  TData = Awaited<ReturnType<typeof getUserProfileV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserProfileV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserProfileV1QueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Validate the mobile phone of a specific user
 * @summary Validate Mobile Phone by ID
 */
export const validateMobilePhoneByIdV1 = (userId: string, phone: string) => {
  return fetchInstance<MobileValidationResponseV1>({
    url: `/iam/v1/users/${userId}/mobile/${phone}`,
    method: 'POST',
  })
}

export const getValidateMobilePhoneByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateMobilePhoneByIdV1>>,
    TError,
    { userId: string; phone: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateMobilePhoneByIdV1>>,
  TError,
  { userId: string; phone: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateMobilePhoneByIdV1>>,
    { userId: string; phone: string }
  > = (props) => {
    const { userId, phone } = props ?? {}

    return validateMobilePhoneByIdV1(userId, phone)
  }

  return { mutationFn, ...mutationOptions }
}

export type ValidateMobilePhoneByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof validateMobilePhoneByIdV1>>
>

export type ValidateMobilePhoneByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Validate Mobile Phone by ID
 */
export const useValidateMobilePhoneByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateMobilePhoneByIdV1>>,
    TError,
    { userId: string; phone: string },
    TContext
  >
}) => {
  const mutationOptions = getValidateMobilePhoneByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:read`

Fetch teams of a specific user
 * @summary Get User Teams by ID
 */
export const getUserTeamsByIdV1 = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<TeamV1[]>({
    url: `/iam/v1/users/${userId}/teams`,
    method: 'GET',
    signal,
  })
}

export const getGetUserTeamsByIdV1QueryKey = (userId: string) => {
  return [`/iam/v1/users/${userId}/teams`] as const
}

export const getGetUserTeamsByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTeamsByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserTeamsByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserTeamsByIdV1QueryKey(userId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserTeamsByIdV1>>
  > = ({ signal }) => getUserTeamsByIdV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTeamsByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserTeamsByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserTeamsByIdV1>>
>
export type GetUserTeamsByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Teams by ID
 */
export const useGetUserTeamsByIdV1 = <
  TData = Awaited<ReturnType<typeof getUserTeamsByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserTeamsByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserTeamsByIdV1QueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Fetch firms of a specific user
 * @summary Get User Firms by ID
 */
export const getUserFirmsByIdV1 = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<FirmV1[]>({
    url: `/iam/v1/users/${userId}/firms`,
    method: 'GET',
    signal,
  })
}

export const getGetUserFirmsByIdV1QueryKey = (userId: string) => {
  return [`/iam/v1/users/${userId}/firms`] as const
}

export const getGetUserFirmsByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFirmsByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserFirmsByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserFirmsByIdV1QueryKey(userId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserFirmsByIdV1>>
  > = ({ signal }) => getUserFirmsByIdV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFirmsByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserFirmsByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFirmsByIdV1>>
>
export type GetUserFirmsByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Firms by ID
 */
export const useGetUserFirmsByIdV1 = <
  TData = Awaited<ReturnType<typeof getUserFirmsByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserFirmsByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFirmsByIdV1QueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:users:read`

gets user's parents
 * @summary Get User Parents
 */
export const getParentsV1 = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<NodeEntityV1[]>({
    url: `/iam/v1/users/${userId}/parents`,
    method: 'GET',
    signal,
  })
}

export const getGetParentsV1QueryKey = (userId: string) => {
  return [`/iam/v1/users/${userId}/parents`] as const
}

export const getGetParentsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getParentsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getParentsV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetParentsV1QueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getParentsV1>>> = ({
    signal,
  }) => getParentsV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getParentsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetParentsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getParentsV1>>
>
export type GetParentsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Parents
 */
export const useGetParentsV1 = <
  TData = Awaited<ReturnType<typeof getParentsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getParentsV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetParentsV1QueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:users:write`

updates user's parents
 * @summary Replace User Parents
 */
export const replaceParentsV1 = (
  userId: string,
  nestedHierarchyEdgeRequestV1: NestedHierarchyEdgeRequestV1[]
) => {
  return fetchInstance<void>({
    url: `/iam/v1/users/${userId}/parents`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: nestedHierarchyEdgeRequestV1,
  })
}

export const getReplaceParentsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceParentsV1>>,
    TError,
    { userId: string; data: NestedHierarchyEdgeRequestV1[] },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof replaceParentsV1>>,
  TError,
  { userId: string; data: NestedHierarchyEdgeRequestV1[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replaceParentsV1>>,
    { userId: string; data: NestedHierarchyEdgeRequestV1[] }
  > = (props) => {
    const { userId, data } = props ?? {}

    return replaceParentsV1(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ReplaceParentsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof replaceParentsV1>>
>
export type ReplaceParentsV1MutationBody = NestedHierarchyEdgeRequestV1[]
export type ReplaceParentsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Replace User Parents
 */
export const useReplaceParentsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceParentsV1>>,
    TError,
    { userId: string; data: NestedHierarchyEdgeRequestV1[] },
    TContext
  >
}) => {
  const mutationOptions = getReplaceParentsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get the terms and conditions for the user that is making the call
 * @summary Get Terms and Conditions
 */
export const getTermsAndConditionsV1 = (signal?: AbortSignal) => {
  return fetchInstance<UserTermsAndConditionsV1[]>({
    url: `/iam/v1/logged-in-user/terms`,
    method: 'GET',
    signal,
  })
}

export const getGetTermsAndConditionsV1QueryKey = () => {
  return [`/iam/v1/logged-in-user/terms`] as const
}

export const getGetTermsAndConditionsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getTermsAndConditionsV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTermsAndConditionsV1>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetTermsAndConditionsV1QueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTermsAndConditionsV1>>
  > = ({ signal }) => getTermsAndConditionsV1(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTermsAndConditionsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTermsAndConditionsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getTermsAndConditionsV1>>
>
export type GetTermsAndConditionsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Terms and Conditions
 */
export const useGetTermsAndConditionsV1 = <
  TData = Awaited<ReturnType<typeof getTermsAndConditionsV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTermsAndConditionsV1>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTermsAndConditionsV1QueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Accept terms and conditions for a user
 * @summary Accept Terms and Conditions
 */
export const acceptTermsAndConditionsV1 = (
  acceptTermsAndConditionsInputV1: AcceptTermsAndConditionsInputV1
) => {
  return fetchInstance<UserTermsAndConditionsV1>({
    url: `/iam/v1/logged-in-user/terms`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: acceptTermsAndConditionsInputV1,
  })
}

export const getAcceptTermsAndConditionsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptTermsAndConditionsV1>>,
    TError,
    { data: AcceptTermsAndConditionsInputV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptTermsAndConditionsV1>>,
  TError,
  { data: AcceptTermsAndConditionsInputV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptTermsAndConditionsV1>>,
    { data: AcceptTermsAndConditionsInputV1 }
  > = (props) => {
    const { data } = props ?? {}

    return acceptTermsAndConditionsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AcceptTermsAndConditionsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptTermsAndConditionsV1>>
>
export type AcceptTermsAndConditionsV1MutationBody =
  AcceptTermsAndConditionsInputV1
export type AcceptTermsAndConditionsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Accept Terms and Conditions
 */
export const useAcceptTermsAndConditionsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptTermsAndConditionsV1>>,
    TError,
    { data: AcceptTermsAndConditionsInputV1 },
    TContext
  >
}) => {
  const mutationOptions = getAcceptTermsAndConditionsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Fetch the profile of the calling user
 * @summary Get Logged In User Profile
 */
export const getLoggedInUserProfileV1 = (signal?: AbortSignal) => {
  return fetchInstance<UserProfileV1>({
    url: `/iam/v1/logged-in-user/profile`,
    method: 'GET',
    signal,
  })
}

export const getGetLoggedInUserProfileV1QueryKey = () => {
  return [`/iam/v1/logged-in-user/profile`] as const
}

export const getGetLoggedInUserProfileV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getLoggedInUserProfileV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLoggedInUserProfileV1>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetLoggedInUserProfileV1QueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLoggedInUserProfileV1>>
  > = ({ signal }) => getLoggedInUserProfileV1(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLoggedInUserProfileV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetLoggedInUserProfileV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getLoggedInUserProfileV1>>
>
export type GetLoggedInUserProfileV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Logged In User Profile
 */
export const useGetLoggedInUserProfileV1 = <
  TData = Awaited<ReturnType<typeof getLoggedInUserProfileV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLoggedInUserProfileV1>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLoggedInUserProfileV1QueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search for role workflow items where the logged in user is an actor
 * @summary Get Role Workflow Items
 */
export const getRoleWorkflowItemsV1 = (
  roleWorkflowItemSearchRequestV1: RoleWorkflowItemSearchRequestV1
) => {
  return fetchInstance<PagedRoleWorkflowItemsV1>({
    url: `/iam/v1/logged-in-user/role-workflow-items`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleWorkflowItemSearchRequestV1,
  })
}

export const getGetRoleWorkflowItemsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getRoleWorkflowItemsV1>>,
    TError,
    { data: RoleWorkflowItemSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getRoleWorkflowItemsV1>>,
  TError,
  { data: RoleWorkflowItemSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getRoleWorkflowItemsV1>>,
    { data: RoleWorkflowItemSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getRoleWorkflowItemsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetRoleWorkflowItemsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof getRoleWorkflowItemsV1>>
>
export type GetRoleWorkflowItemsV1MutationBody = RoleWorkflowItemSearchRequestV1
export type GetRoleWorkflowItemsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Get Role Workflow Items
 */
export const useGetRoleWorkflowItemsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getRoleWorkflowItemsV1>>,
    TError,
    { data: RoleWorkflowItemSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetRoleWorkflowItemsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:switch-user`        

Switch to a impersonate another user
 * @summary Switch User
 */
export const switchUserV1 = (switchUserId: string) => {
  return fetchInstance<StartSwitchUserResponseV1>({
    url: `/iam/v1/logged-in-user/switch/${switchUserId}`,
    method: 'POST',
  })
}

export const getSwitchUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof switchUserV1>>,
    TError,
    { switchUserId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof switchUserV1>>,
  TError,
  { switchUserId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof switchUserV1>>,
    { switchUserId: string }
  > = (props) => {
    const { switchUserId } = props ?? {}

    return switchUserV1(switchUserId)
  }

  return { mutationFn, ...mutationOptions }
}

export type SwitchUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof switchUserV1>>
>

export type SwitchUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Switch User
 */
export const useSwitchUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof switchUserV1>>,
    TError,
    { switchUserId: string },
    TContext
  >
}) => {
  const mutationOptions = getSwitchUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Remove any switch user state that may exist
 * @summary Unswitch User
 */
export const unSwitchUserV1 = () => {
  return fetchInstance<void>({
    url: `/iam/v1/logged-in-user/switch`,
    method: 'DELETE',
  })
}

export const getUnSwitchUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unSwitchUserV1>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof unSwitchUserV1>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unSwitchUserV1>>,
    void
  > = () => {
    return unSwitchUserV1()
  }

  return { mutationFn, ...mutationOptions }
}

export type UnSwitchUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof unSwitchUserV1>>
>

export type UnSwitchUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Unswitch User
 */
export const useUnSwitchUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unSwitchUserV1>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions = getUnSwitchUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * *** Permission:** `iam:service-identities:write`

Create a new service identity
 * @summary Create Service Identity
 */
export const createServiceIdentityV1 = (
  serviceIdentityRequestV1: ServiceIdentityRequestV1
) => {
  return fetchInstance<ServiceIdentityCreateResponseV1>({
    url: `/iam/v1/service-identities`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: serviceIdentityRequestV1,
  })
}

export const getCreateServiceIdentityV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createServiceIdentityV1>>,
    TError,
    { data: ServiceIdentityRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createServiceIdentityV1>>,
  TError,
  { data: ServiceIdentityRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createServiceIdentityV1>>,
    { data: ServiceIdentityRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createServiceIdentityV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateServiceIdentityV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createServiceIdentityV1>>
>
export type CreateServiceIdentityV1MutationBody = ServiceIdentityRequestV1
export type CreateServiceIdentityV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Service Identity
 */
export const useCreateServiceIdentityV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createServiceIdentityV1>>,
    TError,
    { data: ServiceIdentityRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateServiceIdentityV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:service-identities:read`

Return all service identity or a subset of Service Identities using searching and filtering in a sorted and paged list
 * @summary Search Service Identities
 */
export const searchServiceIdentitiesV1 = (
  serviceIdentitySearchRequestV1: ServiceIdentitySearchRequestV1
) => {
  return fetchInstance<PagedServiceIdentitiesV1>({
    url: `/iam/v1/service-identities/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: serviceIdentitySearchRequestV1,
  })
}

export const getSearchServiceIdentitiesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchServiceIdentitiesV1>>,
    TError,
    { data: ServiceIdentitySearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchServiceIdentitiesV1>>,
  TError,
  { data: ServiceIdentitySearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchServiceIdentitiesV1>>,
    { data: ServiceIdentitySearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchServiceIdentitiesV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchServiceIdentitiesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchServiceIdentitiesV1>>
>
export type SearchServiceIdentitiesV1MutationBody =
  ServiceIdentitySearchRequestV1
export type SearchServiceIdentitiesV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Service Identities
 */
export const useSearchServiceIdentitiesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchServiceIdentitiesV1>>,
    TError,
    { data: ServiceIdentitySearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchServiceIdentitiesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:service-identities:read`

Get a specific service identity by its id
 * @summary Get Service Identity by ID
 */
export const getServiceIdentityByIdV1 = (
  serviceIdentityId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ServiceIdentityV1>({
    url: `/iam/v1/service-identities/${serviceIdentityId}`,
    method: 'GET',
    signal,
  })
}

export const getGetServiceIdentityByIdV1QueryKey = (
  serviceIdentityId: string
) => {
  return [`/iam/v1/service-identities/${serviceIdentityId}`] as const
}

export const getGetServiceIdentityByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getServiceIdentityByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  serviceIdentityId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getServiceIdentityByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetServiceIdentityByIdV1QueryKey(serviceIdentityId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getServiceIdentityByIdV1>>
  > = ({ signal }) => getServiceIdentityByIdV1(serviceIdentityId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!serviceIdentityId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getServiceIdentityByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetServiceIdentityByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getServiceIdentityByIdV1>>
>
export type GetServiceIdentityByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Service Identity by ID
 */
export const useGetServiceIdentityByIdV1 = <
  TData = Awaited<ReturnType<typeof getServiceIdentityByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  serviceIdentityId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getServiceIdentityByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetServiceIdentityByIdV1QueryOptions(
    serviceIdentityId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:service-identities:write`

Update a service identity
 * @summary Update Service Identity
 */
export const updateServiceIdentityV1 = (
  serviceIdentityId: string,
  serviceIdentityRequestV1: ServiceIdentityRequestV1
) => {
  return fetchInstance<ServiceIdentityV1>({
    url: `/iam/v1/service-identities/${serviceIdentityId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: serviceIdentityRequestV1,
  })
}

export const getUpdateServiceIdentityV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiceIdentityV1>>,
    TError,
    { serviceIdentityId: string; data: ServiceIdentityRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateServiceIdentityV1>>,
  TError,
  { serviceIdentityId: string; data: ServiceIdentityRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateServiceIdentityV1>>,
    { serviceIdentityId: string; data: ServiceIdentityRequestV1 }
  > = (props) => {
    const { serviceIdentityId, data } = props ?? {}

    return updateServiceIdentityV1(serviceIdentityId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateServiceIdentityV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateServiceIdentityV1>>
>
export type UpdateServiceIdentityV1MutationBody = ServiceIdentityRequestV1
export type UpdateServiceIdentityV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update Service Identity
 */
export const useUpdateServiceIdentityV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiceIdentityV1>>,
    TError,
    { serviceIdentityId: string; data: ServiceIdentityRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateServiceIdentityV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:service-identities:write`

Delete the service identity with the provided serviceIdentityId
 * @summary Delete Service Identity by ID
 */
export const deleteServiceIdentityByIdV1 = (serviceIdentityId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/service-identities/${serviceIdentityId}`,
    method: 'DELETE',
  })
}

export const getDeleteServiceIdentityByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteServiceIdentityByIdV1>>,
    TError,
    { serviceIdentityId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteServiceIdentityByIdV1>>,
  TError,
  { serviceIdentityId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteServiceIdentityByIdV1>>,
    { serviceIdentityId: string }
  > = (props) => {
    const { serviceIdentityId } = props ?? {}

    return deleteServiceIdentityByIdV1(serviceIdentityId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteServiceIdentityByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteServiceIdentityByIdV1>>
>

export type DeleteServiceIdentityByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Service Identity by ID
 */
export const useDeleteServiceIdentityByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteServiceIdentityByIdV1>>,
    TError,
    { serviceIdentityId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteServiceIdentityByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:service-identities:write`

Rotate secret for a service identity
 * @summary Rotate Secret for Service Identity by ID
 */
export const rotateSecretForServiceIdentityByIdV1 = (
  serviceIdentityId: string
) => {
  return fetchInstance<ServiceIdentityRotateSecretResponseV1>({
    url: `/iam/v1/service-identities/${serviceIdentityId}/rotate-secret`,
    method: 'POST',
  })
}

export const getRotateSecretForServiceIdentityByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rotateSecretForServiceIdentityByIdV1>>,
    TError,
    { serviceIdentityId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof rotateSecretForServiceIdentityByIdV1>>,
  TError,
  { serviceIdentityId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rotateSecretForServiceIdentityByIdV1>>,
    { serviceIdentityId: string }
  > = (props) => {
    const { serviceIdentityId } = props ?? {}

    return rotateSecretForServiceIdentityByIdV1(serviceIdentityId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RotateSecretForServiceIdentityByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof rotateSecretForServiceIdentityByIdV1>>
>

export type RotateSecretForServiceIdentityByIdV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Rotate Secret for Service Identity by ID
 */
export const useRotateSecretForServiceIdentityByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rotateSecretForServiceIdentityByIdV1>>,
    TError,
    { serviceIdentityId: string },
    TContext
  >
}) => {
  const mutationOptions =
    getRotateSecretForServiceIdentityByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Create or update a team by external ID
 * @summary Update Team by External ID
 */
export const updateTeamByExternalIdV1 = (
  teamRequestV1: TeamRequestV1,
  params?: UpdateTeamByExternalIdV1Params
) => {
  return fetchInstance<TeamV1>({
    url: `/iam/v1/teams`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: teamRequestV1,
    params,
  })
}

export const getUpdateTeamByExternalIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamByExternalIdV1>>,
    TError,
    { data: TeamRequestV1; params?: UpdateTeamByExternalIdV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTeamByExternalIdV1>>,
  TError,
  { data: TeamRequestV1; params?: UpdateTeamByExternalIdV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTeamByExternalIdV1>>,
    { data: TeamRequestV1; params?: UpdateTeamByExternalIdV1Params }
  > = (props) => {
    const { data, params } = props ?? {}

    return updateTeamByExternalIdV1(data, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateTeamByExternalIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTeamByExternalIdV1>>
>
export type UpdateTeamByExternalIdV1MutationBody = TeamRequestV1
export type UpdateTeamByExternalIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update Team by External ID
 */
export const useUpdateTeamByExternalIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamByExternalIdV1>>,
    TError,
    { data: TeamRequestV1; params?: UpdateTeamByExternalIdV1Params },
    TContext
  >
}) => {
  const mutationOptions = getUpdateTeamByExternalIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Create a new team with parent
 * @summary Create Team
 */
export const createTeamV1 = (teamRequestV1: TeamRequestV1) => {
  return fetchInstance<TeamV1>({
    url: `/iam/v1/teams`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: teamRequestV1,
  })
}

export const getCreateTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeamV1>>,
    TError,
    { data: TeamRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTeamV1>>,
  TError,
  { data: TeamRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTeamV1>>,
    { data: TeamRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createTeamV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createTeamV1>>
>
export type CreateTeamV1MutationBody = TeamRequestV1
export type CreateTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Team
 */
export const useCreateTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeamV1>>,
    TError,
    { data: TeamRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Add a user to a team
 * @summary Add User to Team
 */
export const addUserToTeamV1 = (
  teamId: string,
  userId: string,
  attributesRequestV1: AttributesRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/teams/${teamId}/users/${userId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: attributesRequestV1,
  })
}

export const getAddUserToTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToTeamV1>>,
    TError,
    { teamId: string; userId: string; data: AttributesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addUserToTeamV1>>,
  TError,
  { teamId: string; userId: string; data: AttributesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUserToTeamV1>>,
    { teamId: string; userId: string; data: AttributesRequestV1 }
  > = (props) => {
    const { teamId, userId, data } = props ?? {}

    return addUserToTeamV1(teamId, userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddUserToTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addUserToTeamV1>>
>
export type AddUserToTeamV1MutationBody = AttributesRequestV1
export type AddUserToTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add User to Team
 */
export const useAddUserToTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToTeamV1>>,
    TError,
    { teamId: string; userId: string; data: AttributesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getAddUserToTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Remove a user from a team
 * @summary Remove User from Team
 */
export const removeUserFromTeamV1 = (teamId: string, userId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/teams/${teamId}/users/${userId}`,
    method: 'DELETE',
  })
}

export const getRemoveUserFromTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFromTeamV1>>,
    TError,
    { teamId: string; userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeUserFromTeamV1>>,
  TError,
  { teamId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeUserFromTeamV1>>,
    { teamId: string; userId: string }
  > = (props) => {
    const { teamId, userId } = props ?? {}

    return removeUserFromTeamV1(teamId, userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemoveUserFromTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof removeUserFromTeamV1>>
>

export type RemoveUserFromTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove User from Team
 */
export const useRemoveUserFromTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFromTeamV1>>,
    TError,
    { teamId: string; userId: string },
    TContext
  >
}) => {
  const mutationOptions = getRemoveUserFromTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Return all users or a subset of users in a team using searching and filtering in a sorted and paged list
 * @summary Search Users in Team
 */
export const searchUsersInTeamV1 = (
  teamId: string,
  userSearchRequestV1: UserSearchRequestV1
) => {
  return fetchInstance<PagedUsersV1>({
    url: `/iam/v1/teams/${teamId}/users/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userSearchRequestV1,
  })
}

export const getSearchUsersInTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUsersInTeamV1>>,
    TError,
    { teamId: string; data: UserSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchUsersInTeamV1>>,
  TError,
  { teamId: string; data: UserSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchUsersInTeamV1>>,
    { teamId: string; data: UserSearchRequestV1 }
  > = (props) => {
    const { teamId, data } = props ?? {}

    return searchUsersInTeamV1(teamId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchUsersInTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchUsersInTeamV1>>
>
export type SearchUsersInTeamV1MutationBody = UserSearchRequestV1
export type SearchUsersInTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Users in Team
 */
export const useSearchUsersInTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUsersInTeamV1>>,
    TError,
    { teamId: string; data: UserSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchUsersInTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:read`

Get a team by its ID
 * @summary Get Team by ID
 */
export const getTeamByIdV1 = (teamId: string, signal?: AbortSignal) => {
  return fetchInstance<TeamV1>({
    url: `/iam/v1/teams/${teamId}`,
    method: 'GET',
    signal,
  })
}

export const getGetTeamByIdV1QueryKey = (teamId: string) => {
  return [`/iam/v1/teams/${teamId}`] as const
}

export const getGetTeamByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getTeamByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  teamId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTeamByIdV1QueryKey(teamId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamByIdV1>>> = ({
    signal,
  }) => getTeamByIdV1(teamId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!teamId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeamByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTeamByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamByIdV1>>
>
export type GetTeamByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Team by ID
 */
export const useGetTeamByIdV1 = <
  TData = Awaited<ReturnType<typeof getTeamByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  teamId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamByIdV1QueryOptions(teamId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:teams:write`

Update a team
 * @summary Update Team
 */
export const updateTeamV1 = (teamId: string, teamRequestV1: TeamRequestV1) => {
  return fetchInstance<TeamV1>({
    url: `/iam/v1/teams/${teamId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: teamRequestV1,
  })
}

export const getUpdateTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamV1>>,
    TError,
    { teamId: string; data: TeamRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTeamV1>>,
  TError,
  { teamId: string; data: TeamRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTeamV1>>,
    { teamId: string; data: TeamRequestV1 }
  > = (props) => {
    const { teamId, data } = props ?? {}

    return updateTeamV1(teamId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTeamV1>>
>
export type UpdateTeamV1MutationBody = TeamRequestV1
export type UpdateTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update Team
 */
export const useUpdateTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamV1>>,
    TError,
    { teamId: string; data: TeamRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:delete`

Delete a team by its ID
 * @summary Delete Team by ID
 */
export const deleteTeamByIdV1 = (teamId: string) => {
  return fetchInstance<number>({
    url: `/iam/v1/teams/${teamId}`,
    method: 'DELETE',
  })
}

export const getDeleteTeamByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTeamByIdV1>>,
    TError,
    { teamId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTeamByIdV1>>,
  TError,
  { teamId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTeamByIdV1>>,
    { teamId: string }
  > = (props) => {
    const { teamId } = props ?? {}

    return deleteTeamByIdV1(teamId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteTeamByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTeamByIdV1>>
>

export type DeleteTeamByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Team by ID
 */
export const useDeleteTeamByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTeamByIdV1>>,
    TError,
    { teamId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteTeamByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Partially update a team via JsonPatch syntax
| https://jsonpatch.com/
 * @summary Patch Update Team
 */
export const patchUpdateTeamV1 = (
  teamId: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstance<TeamV1>({
    url: `/iam/v1/teams/${teamId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getPatchUpdateTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateTeamV1>>,
    TError,
    { teamId: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUpdateTeamV1>>,
  TError,
  { teamId: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUpdateTeamV1>>,
    { teamId: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { teamId, data } = props ?? {}

    return patchUpdateTeamV1(teamId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchUpdateTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUpdateTeamV1>>
>
export type PatchUpdateTeamV1MutationBody = PatchRequestBodyBody
export type PatchUpdateTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Patch Update Team
 */
export const usePatchUpdateTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateTeamV1>>,
    TError,
    { teamId: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchUpdateTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Get all users that are in a given team
 * @summary Get Users in Team
 */
export const getUsersInTeamV1 = (teamId: string, signal?: AbortSignal) => {
  return fetchInstance<UserV1[]>({
    url: `/iam/v1/teams/${teamId}/users`,
    method: 'GET',
    signal,
  })
}

export const getGetUsersInTeamV1QueryKey = (teamId: string) => {
  return [`/iam/v1/teams/${teamId}/users`] as const
}

export const getGetUsersInTeamV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersInTeamV1>>,
  TError = ErrorType<ErrorResponse>
>(
  teamId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsersInTeamV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersInTeamV1QueryKey(teamId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUsersInTeamV1>>
  > = ({ signal }) => getUsersInTeamV1(teamId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!teamId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersInTeamV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUsersInTeamV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersInTeamV1>>
>
export type GetUsersInTeamV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Users in Team
 */
export const useGetUsersInTeamV1 = <
  TData = Awaited<ReturnType<typeof getUsersInTeamV1>>,
  TError = ErrorType<ErrorResponse>
>(
  teamId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsersInTeamV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersInTeamV1QueryOptions(teamId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:teams:read`

Get team statistics - user count, etc
 * @summary Load Teams Stats
 */
export const loadTeamsStatsV1 = (uUIDListV1: UUIDListV1) => {
  return fetchInstance<TeamStatsV1[]>({
    url: `/iam/v1/teams/stats`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: uUIDListV1,
  })
}

export const getLoadTeamsStatsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loadTeamsStatsV1>>,
    TError,
    { data: UUIDListV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof loadTeamsStatsV1>>,
  TError,
  { data: UUIDListV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loadTeamsStatsV1>>,
    { data: UUIDListV1 }
  > = (props) => {
    const { data } = props ?? {}

    return loadTeamsStatsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type LoadTeamsStatsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof loadTeamsStatsV1>>
>
export type LoadTeamsStatsV1MutationBody = UUIDListV1
export type LoadTeamsStatsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Load Teams Stats
 */
export const useLoadTeamsStatsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loadTeamsStatsV1>>,
    TError,
    { data: UUIDListV1 },
    TContext
  >
}) => {
  const mutationOptions = getLoadTeamsStatsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get Import Users Config
 * @summary Get Import Users Config
 */
export const getImportUsersConfigV1 = (
  firmId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ImportUsersConfigResponseV1>({
    url: `/iam/v1/firms/${firmId}/import-users-config`,
    method: 'GET',
    signal,
  })
}

export const getGetImportUsersConfigV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/import-users-config`] as const
}

export const getGetImportUsersConfigV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getImportUsersConfigV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getImportUsersConfigV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetImportUsersConfigV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getImportUsersConfigV1>>
  > = ({ signal }) => getImportUsersConfigV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getImportUsersConfigV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetImportUsersConfigV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getImportUsersConfigV1>>
>
export type GetImportUsersConfigV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Import Users Config
 */
export const useGetImportUsersConfigV1 = <
  TData = Awaited<ReturnType<typeof getImportUsersConfigV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getImportUsersConfigV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetImportUsersConfigV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create or Update Import Users Config
 * @summary Create or Update Import Users Config
 */
export const createOrUpdateImportUsersConfigV1 = (
  firmId: string,
  importUsersConfigCreateOrUpdateRequestV1: ImportUsersConfigCreateOrUpdateRequestV1
) => {
  return fetchInstance<ImportUsersConfigResponseV1>({
    url: `/iam/v1/firms/${firmId}/import-users-config`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: importUsersConfigCreateOrUpdateRequestV1,
  })
}

export const getCreateOrUpdateImportUsersConfigV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrUpdateImportUsersConfigV1>>,
    TError,
    { firmId: string; data: ImportUsersConfigCreateOrUpdateRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrUpdateImportUsersConfigV1>>,
  TError,
  { firmId: string; data: ImportUsersConfigCreateOrUpdateRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrUpdateImportUsersConfigV1>>,
    { firmId: string; data: ImportUsersConfigCreateOrUpdateRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createOrUpdateImportUsersConfigV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateOrUpdateImportUsersConfigV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrUpdateImportUsersConfigV1>>
>
export type CreateOrUpdateImportUsersConfigV1MutationBody =
  ImportUsersConfigCreateOrUpdateRequestV1
export type CreateOrUpdateImportUsersConfigV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Create or Update Import Users Config
 */
export const useCreateOrUpdateImportUsersConfigV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrUpdateImportUsersConfigV1>>,
    TError,
    { firmId: string; data: ImportUsersConfigCreateOrUpdateRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getCreateOrUpdateImportUsersConfigV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete Import Users Config
 * @summary Delete Import Users Config
 */
export const deleteImportUsersConfigV1 = (firmId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/import-users-config`,
    method: 'DELETE',
  })
}

export const getDeleteImportUsersConfigV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteImportUsersConfigV1>>,
    TError,
    { firmId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteImportUsersConfigV1>>,
  TError,
  { firmId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteImportUsersConfigV1>>,
    { firmId: string }
  > = (props) => {
    const { firmId } = props ?? {}

    return deleteImportUsersConfigV1(firmId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteImportUsersConfigV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteImportUsersConfigV1>>
>

export type DeleteImportUsersConfigV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Import Users Config
 */
export const useDeleteImportUsersConfigV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteImportUsersConfigV1>>,
    TError,
    { firmId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteImportUsersConfigV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Get firm statistics - user count, etc
 * @summary Load Firms Stats
 */
export const loadFirmsStatsV1 = (uUIDListV1: UUIDListV1) => {
  return fetchInstance<FirmStatsV1[]>({
    url: `/iam/v1/firms/stats`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: uUIDListV1,
  })
}

export const getLoadFirmsStatsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loadFirmsStatsV1>>,
    TError,
    { data: UUIDListV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof loadFirmsStatsV1>>,
  TError,
  { data: UUIDListV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loadFirmsStatsV1>>,
    { data: UUIDListV1 }
  > = (props) => {
    const { data } = props ?? {}

    return loadFirmsStatsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type LoadFirmsStatsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof loadFirmsStatsV1>>
>
export type LoadFirmsStatsV1MutationBody = UUIDListV1
export type LoadFirmsStatsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Load Firms Stats
 */
export const useLoadFirmsStatsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loadFirmsStatsV1>>,
    TError,
    { data: UUIDListV1 },
    TContext
  >
}) => {
  const mutationOptions = getLoadFirmsStatsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Get firm by name
 * @summary Get Firm by Name
 */
export const getFirmByNameV1 = (
  params?: GetFirmByNameV1Params,
  signal?: AbortSignal
) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/firms`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetFirmByNameV1QueryKey = (params?: GetFirmByNameV1Params) => {
  return [`/iam/v1/firms`, ...(params ? [params] : [])] as const
}

export const getGetFirmByNameV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmByNameV1>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetFirmByNameV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmByNameV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFirmByNameV1QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFirmByNameV1>>> = ({
    signal,
  }) => getFirmByNameV1(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmByNameV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmByNameV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmByNameV1>>
>
export type GetFirmByNameV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Firm by Name
 */
export const useGetFirmByNameV1 = <
  TData = Awaited<ReturnType<typeof getFirmByNameV1>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetFirmByNameV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmByNameV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmByNameV1QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:write`

Create or update a firm by external ID
 * @summary Update Firm by External ID
 */
export const updateFirmByExternalIdV1 = (
  firmRequestV1: FirmRequestV1,
  params?: UpdateFirmByExternalIdV1Params
) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/firms`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: firmRequestV1,
    params,
  })
}

export const getUpdateFirmByExternalIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmByExternalIdV1>>,
    TError,
    { data: FirmRequestV1; params?: UpdateFirmByExternalIdV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFirmByExternalIdV1>>,
  TError,
  { data: FirmRequestV1; params?: UpdateFirmByExternalIdV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFirmByExternalIdV1>>,
    { data: FirmRequestV1; params?: UpdateFirmByExternalIdV1Params }
  > = (props) => {
    const { data, params } = props ?? {}

    return updateFirmByExternalIdV1(data, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFirmByExternalIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFirmByExternalIdV1>>
>
export type UpdateFirmByExternalIdV1MutationBody = FirmRequestV1
export type UpdateFirmByExternalIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update Firm by External ID
 */
export const useUpdateFirmByExternalIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmByExternalIdV1>>,
    TError,
    { data: FirmRequestV1; params?: UpdateFirmByExternalIdV1Params },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFirmByExternalIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:write`

Create a new firm
 * @summary Create Firm
 */
export const createFirmV1 = (firmRequestV1: FirmRequestV1) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/firms`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmRequestV1,
  })
}

export const getCreateFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFirmV1>>,
    TError,
    { data: FirmRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFirmV1>>,
  TError,
  { data: FirmRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFirmV1>>,
    { data: FirmRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createFirmV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createFirmV1>>
>
export type CreateFirmV1MutationBody = FirmRequestV1
export type CreateFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Firm
 */
export const useCreateFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFirmV1>>,
    TError,
    { data: FirmRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Add a user to a firm
 * @summary Add User to Firm
 */
export const addUserToFirmV1 = (
  firmId: string,
  userId: string,
  attributesRequestV1: AttributesRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/users/${userId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: attributesRequestV1,
  })
}

export const getAddUserToFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToFirmV1>>,
    TError,
    { firmId: string; userId: string; data: AttributesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addUserToFirmV1>>,
  TError,
  { firmId: string; userId: string; data: AttributesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUserToFirmV1>>,
    { firmId: string; userId: string; data: AttributesRequestV1 }
  > = (props) => {
    const { firmId, userId, data } = props ?? {}

    return addUserToFirmV1(firmId, userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddUserToFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addUserToFirmV1>>
>
export type AddUserToFirmV1MutationBody = AttributesRequestV1
export type AddUserToFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add User to Firm
 */
export const useAddUserToFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToFirmV1>>,
    TError,
    { firmId: string; userId: string; data: AttributesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getAddUserToFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Remove a user from a firm
 * @summary Remove User from Firm
 */
export const removeUserFromFirmV1 = (firmId: string, userId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/users/${userId}`,
    method: 'DELETE',
  })
}

export const getRemoveUserFromFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFromFirmV1>>,
    TError,
    { firmId: string; userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeUserFromFirmV1>>,
  TError,
  { firmId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeUserFromFirmV1>>,
    { firmId: string; userId: string }
  > = (props) => {
    const { firmId, userId } = props ?? {}

    return removeUserFromFirmV1(firmId, userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemoveUserFromFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof removeUserFromFirmV1>>
>

export type RemoveUserFromFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove User from Firm
 */
export const useRemoveUserFromFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFromFirmV1>>,
    TError,
    { firmId: string; userId: string },
    TContext
  >
}) => {
  const mutationOptions = getRemoveUserFromFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Add a team to a firm
 * @summary Add Team to Firm
 */
export const addTeamToFirmV1 = (
  firmId: string,
  teamId: string,
  attributesRequestV1: AttributesRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/teams/${teamId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: attributesRequestV1,
  })
}

export const getAddTeamToFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTeamToFirmV1>>,
    TError,
    { firmId: string; teamId: string; data: AttributesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addTeamToFirmV1>>,
  TError,
  { firmId: string; teamId: string; data: AttributesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addTeamToFirmV1>>,
    { firmId: string; teamId: string; data: AttributesRequestV1 }
  > = (props) => {
    const { firmId, teamId, data } = props ?? {}

    return addTeamToFirmV1(firmId, teamId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddTeamToFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addTeamToFirmV1>>
>
export type AddTeamToFirmV1MutationBody = AttributesRequestV1
export type AddTeamToFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add Team to Firm
 */
export const useAddTeamToFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTeamToFirmV1>>,
    TError,
    { firmId: string; teamId: string; data: AttributesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getAddTeamToFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Remove a team from a firm
 * @summary Remove Team from Firm
 */
export const removeTeamFromFirmV1 = (firmId: string, teamId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/teams/${teamId}`,
    method: 'DELETE',
  })
}

export const getRemoveTeamFromFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeTeamFromFirmV1>>,
    TError,
    { firmId: string; teamId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeTeamFromFirmV1>>,
  TError,
  { firmId: string; teamId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeTeamFromFirmV1>>,
    { firmId: string; teamId: string }
  > = (props) => {
    const { firmId, teamId } = props ?? {}

    return removeTeamFromFirmV1(firmId, teamId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemoveTeamFromFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof removeTeamFromFirmV1>>
>

export type RemoveTeamFromFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove Team from Firm
 */
export const useRemoveTeamFromFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeTeamFromFirmV1>>,
    TError,
    { firmId: string; teamId: string },
    TContext
  >
}) => {
  const mutationOptions = getRemoveTeamFromFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:read`

Get team statistics - user count, etc
 * @summary Load Teams Stats by Firm
 */
export const loadTeamsStatsByFirmV1 = (
  firmId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<TeamStatsV1[]>({
    url: `/iam/v1/firms/${firmId}/teams/stats`,
    method: 'GET',
    signal,
  })
}

export const getLoadTeamsStatsByFirmV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/teams/stats`] as const
}

export const getLoadTeamsStatsByFirmV1QueryOptions = <
  TData = Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getLoadTeamsStatsByFirmV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>
  > = ({ signal }) => loadTeamsStatsByFirmV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type LoadTeamsStatsByFirmV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>
>
export type LoadTeamsStatsByFirmV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Load Teams Stats by Firm
 */
export const useLoadTeamsStatsByFirmV1 = <
  TData = Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof loadTeamsStatsByFirmV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLoadTeamsStatsByFirmV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:write`

Add a firm to a firm
 * @summary Add Firm to Firm
 */
export const addFirmToFirmV1 = (
  firmId: string,
  childFirmId: string,
  attributesRequestV1: AttributesRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/firms/${childFirmId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: attributesRequestV1,
  })
}

export const getAddFirmToFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addFirmToFirmV1>>,
    TError,
    { firmId: string; childFirmId: string; data: AttributesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addFirmToFirmV1>>,
  TError,
  { firmId: string; childFirmId: string; data: AttributesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addFirmToFirmV1>>,
    { firmId: string; childFirmId: string; data: AttributesRequestV1 }
  > = (props) => {
    const { firmId, childFirmId, data } = props ?? {}

    return addFirmToFirmV1(firmId, childFirmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddFirmToFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addFirmToFirmV1>>
>
export type AddFirmToFirmV1MutationBody = AttributesRequestV1
export type AddFirmToFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add Firm to Firm
 */
export const useAddFirmToFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addFirmToFirmV1>>,
    TError,
    { firmId: string; childFirmId: string; data: AttributesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getAddFirmToFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:write`

Remove a firm from a firm
 * @summary Remove Firm from Firm
 */
export const removeFirmFromFirmV1 = (firmId: string, childFirmId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/firms/${childFirmId}`,
    method: 'DELETE',
  })
}

export const getRemoveFirmFromFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeFirmFromFirmV1>>,
    TError,
    { firmId: string; childFirmId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeFirmFromFirmV1>>,
  TError,
  { firmId: string; childFirmId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeFirmFromFirmV1>>,
    { firmId: string; childFirmId: string }
  > = (props) => {
    const { firmId, childFirmId } = props ?? {}

    return removeFirmFromFirmV1(firmId, childFirmId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemoveFirmFromFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof removeFirmFromFirmV1>>
>

export type RemoveFirmFromFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove Firm from Firm
 */
export const useRemoveFirmFromFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeFirmFromFirmV1>>,
    TError,
    { firmId: string; childFirmId: string },
    TContext
  >
}) => {
  const mutationOptions = getRemoveFirmFromFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:read`

Return all users or a subset of users in a firm using searching and filtering in a sorted and paged list
 * @summary Search Users in Firm
 */
export const searchUsersInFirmV1 = (
  firmId: string,
  userSearchRequestV1: UserSearchRequestV1
) => {
  return fetchInstance<PagedUsersV1>({
    url: `/iam/v1/firms/${firmId}/users/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userSearchRequestV1,
  })
}

export const getSearchUsersInFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUsersInFirmV1>>,
    TError,
    { firmId: string; data: UserSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchUsersInFirmV1>>,
  TError,
  { firmId: string; data: UserSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchUsersInFirmV1>>,
    { firmId: string; data: UserSearchRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return searchUsersInFirmV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchUsersInFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchUsersInFirmV1>>
>
export type SearchUsersInFirmV1MutationBody = UserSearchRequestV1
export type SearchUsersInFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Users in Firm
 */
export const useSearchUsersInFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUsersInFirmV1>>,
    TError,
    { firmId: string; data: UserSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchUsersInFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:read`

Find all teams in a firm
 * @summary Get Teams in Firm
 */
export const getTeamsInFirmV1 = (firmId: string) => {
  return fetchInstance<TeamV1[]>({
    url: `/iam/v1/firms/${firmId}/teams`,
    method: 'GET',
  })
}

export const getGetTeamsInFirmV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/teams`] as const
}

export const getGetTeamsInFirmV1InfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeamsInFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTeamsInFirmV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTeamsInFirmV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTeamsInFirmV1>>
  > = () => getTeamsInFirmV1(firmId)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getTeamsInFirmV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTeamsInFirmV1InfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamsInFirmV1>>
>
export type GetTeamsInFirmV1InfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Teams in Firm
 */
export const useGetTeamsInFirmV1Infinite = <
  TData = Awaited<ReturnType<typeof getTeamsInFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTeamsInFirmV1>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamsInFirmV1InfiniteQueryOptions(firmId, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetTeamsInFirmV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getTeamsInFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamsInFirmV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTeamsInFirmV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTeamsInFirmV1>>
  > = () => getTeamsInFirmV1(firmId)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeamsInFirmV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTeamsInFirmV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamsInFirmV1>>
>
export type GetTeamsInFirmV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Teams in Firm
 */
export const useGetTeamsInFirmV1 = <
  TData = Awaited<ReturnType<typeof getTeamsInFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamsInFirmV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamsInFirmV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:teams:read`

Search teams
 * @summary Search Teams
 */
export const searchTeamsV1 = (teamSearchRequestV1: TeamSearchRequestV1) => {
  return fetchInstance<PagedTeamsV1>({
    url: `/iam/v1/teams/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: teamSearchRequestV1,
  })
}

export const getSearchTeamsV1QueryKey = (
  teamSearchRequestV1: TeamSearchRequestV1
) => {
  return [`/iam/v1/teams/search`, teamSearchRequestV1] as const
}

export const getSearchTeamsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof searchTeamsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  teamSearchRequestV1: TeamSearchRequestV1,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTeamsV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchTeamsV1QueryKey(teamSearchRequestV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchTeamsV1>>
  > = () => searchTeamsV1(teamSearchRequestV1)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchTeamsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchTeamsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof searchTeamsV1>>
>
export type SearchTeamsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Search Teams
 */
export const useSearchTeamsV1 = <
  TData = Awaited<ReturnType<typeof searchTeamsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  teamSearchRequestV1: TeamSearchRequestV1,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTeamsV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchTeamsV1QueryOptions(
    teamSearchRequestV1,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:teams:read`

Search teams in a firm
 * @summary Search Teams in Firm
 */
export const searchTeamsInFirmV1 = (
  firmId: string,
  teamSearchRequestV1: TeamSearchRequestV1
) => {
  return fetchInstance<PagedTeamsV1>({
    url: `/iam/v1/firms/${firmId}/teams/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: teamSearchRequestV1,
  })
}

export const getSearchTeamsInFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTeamsInFirmV1>>,
    TError,
    { firmId: string; data: TeamSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchTeamsInFirmV1>>,
  TError,
  { firmId: string; data: TeamSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchTeamsInFirmV1>>,
    { firmId: string; data: TeamSearchRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return searchTeamsInFirmV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchTeamsInFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchTeamsInFirmV1>>
>
export type SearchTeamsInFirmV1MutationBody = TeamSearchRequestV1
export type SearchTeamsInFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Teams in Firm
 */
export const useSearchTeamsInFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTeamsInFirmV1>>,
    TError,
    { firmId: string; data: TeamSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchTeamsInFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Find all firms in a firm
 * @summary Get Firms in Firm
 */
export const getFirmsInFirmV1 = (firmId: string, signal?: AbortSignal) => {
  return fetchInstance<FirmV1[]>({
    url: `/iam/v1/firms/${firmId}/firms`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmsInFirmV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/firms`] as const
}

export const getGetFirmsInFirmV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmsInFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmsInFirmV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFirmsInFirmV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFirmsInFirmV1>>
  > = ({ signal }) => getFirmsInFirmV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmsInFirmV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmsInFirmV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmsInFirmV1>>
>
export type GetFirmsInFirmV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Firms in Firm
 */
export const useGetFirmsInFirmV1 = <
  TData = Awaited<ReturnType<typeof getFirmsInFirmV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmsInFirmV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmsInFirmV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Find all roles assignable to firm users
 * @summary Get User Assignable Roles
 */
export const getUserAssignableRolesV1 = (
  firmId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<RoleV1[]>({
    url: `/iam/v1/firms/${firmId}/user-assignable-roles`,
    method: 'GET',
    signal,
  })
}

export const getGetUserAssignableRolesV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/user-assignable-roles`] as const
}

export const getGetUserAssignableRolesV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAssignableRolesV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserAssignableRolesV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserAssignableRolesV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserAssignableRolesV1>>
  > = ({ signal }) => getUserAssignableRolesV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAssignableRolesV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserAssignableRolesV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAssignableRolesV1>>
>
export type GetUserAssignableRolesV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Assignable Roles
 */
export const useGetUserAssignableRolesV1 = <
  TData = Awaited<ReturnType<typeof getUserAssignableRolesV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserAssignableRolesV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAssignableRolesV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Find all roles for a firm
 * @summary Get Firm Roles
 */
export const getFirmRolesV1 = (firmId: string, signal?: AbortSignal) => {
  return fetchInstance<RoleV1[]>({
    url: `/iam/v1/firms/${firmId}/roles`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmRolesV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/roles`] as const
}

export const getGetFirmRolesV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmRolesV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRolesV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFirmRolesV1QueryKey(firmId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFirmRolesV1>>> = ({
    signal,
  }) => getFirmRolesV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmRolesV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmRolesV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmRolesV1>>
>
export type GetFirmRolesV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Firm Roles
 */
export const useGetFirmRolesV1 = <
  TData = Awaited<ReturnType<typeof getFirmRolesV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRolesV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmRolesV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:roles:write`

Create a new firm-specific role
 * @summary Create Firm Role
 */
export const createFirmRoleV1 = (
  firmId: string,
  firmRoleRequestV1: FirmRoleRequestV1
) => {
  return fetchInstance<RoleV1>({
    url: `/iam/v1/firms/${firmId}/roles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmRoleRequestV1,
  })
}

export const getCreateFirmRoleV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFirmRoleV1>>,
    TError,
    { firmId: string; data: FirmRoleRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFirmRoleV1>>,
  TError,
  { firmId: string; data: FirmRoleRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFirmRoleV1>>,
    { firmId: string; data: FirmRoleRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createFirmRoleV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFirmRoleV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createFirmRoleV1>>
>
export type CreateFirmRoleV1MutationBody = FirmRoleRequestV1
export type CreateFirmRoleV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Firm Role
 */
export const useCreateFirmRoleV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFirmRoleV1>>,
    TError,
    { firmId: string; data: FirmRoleRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateFirmRoleV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Search roles for a firm
 * @summary Search Firm Roles
 */
export const searchFirmRolesV1 = (
  firmId: string,
  roleSearchRequestV1: RoleSearchRequestV1
) => {
  return fetchInstance<PagedRolesV1>({
    url: `/iam/v1/firms/${firmId}/roles/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleSearchRequestV1,
  })
}

export const getSearchFirmRolesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFirmRolesV1>>,
    TError,
    { firmId: string; data: RoleSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFirmRolesV1>>,
  TError,
  { firmId: string; data: RoleSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFirmRolesV1>>,
    { firmId: string; data: RoleSearchRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return searchFirmRolesV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFirmRolesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFirmRolesV1>>
>
export type SearchFirmRolesV1MutationBody = RoleSearchRequestV1
export type SearchFirmRolesV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Firm Roles
 */
export const useSearchFirmRolesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFirmRolesV1>>,
    TError,
    { firmId: string; data: RoleSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchFirmRolesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:roles:write`

Activate or deactivate a firm-specific role or roles
 * @summary Enable or Disable a Firm Role Or Roles
 */
export const firmRoleEnablement = (
  firmId: string,
  roleEnablementRequestV1: RoleEnablementRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/roles/enablement`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleEnablementRequestV1,
  })
}

export const getFirmRoleEnablementMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof firmRoleEnablement>>,
    TError,
    { firmId: string; data: RoleEnablementRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof firmRoleEnablement>>,
  TError,
  { firmId: string; data: RoleEnablementRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof firmRoleEnablement>>,
    { firmId: string; data: RoleEnablementRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return firmRoleEnablement(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type FirmRoleEnablementMutationResult = NonNullable<
  Awaited<ReturnType<typeof firmRoleEnablement>>
>
export type FirmRoleEnablementMutationBody = RoleEnablementRequestV1
export type FirmRoleEnablementMutationError = ErrorType<ErrorResponse>

/**
 * @summary Enable or Disable a Firm Role Or Roles
 */
export const useFirmRoleEnablement = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof firmRoleEnablement>>,
    TError,
    { firmId: string; data: RoleEnablementRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getFirmRoleEnablementMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Get a firm-specific role by it's ID
 * @summary Get Firm Role by ID
 */
export const getFirmRoleByIdV1 = (
  firmId: string,
  roleId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<RoleV1>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmRoleByIdV1QueryKey = (
  firmId: string,
  roleId: string
) => {
  return [`/iam/v1/firms/${firmId}/roles/${roleId}`] as const
}

export const getGetFirmRoleByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmRoleByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  roleId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRoleByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFirmRoleByIdV1QueryKey(firmId, roleId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFirmRoleByIdV1>>
  > = ({ signal }) => getFirmRoleByIdV1(firmId, roleId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(firmId && roleId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmRoleByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmRoleByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmRoleByIdV1>>
>
export type GetFirmRoleByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Firm Role by ID
 */
export const useGetFirmRoleByIdV1 = <
  TData = Awaited<ReturnType<typeof getFirmRoleByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  roleId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRoleByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmRoleByIdV1QueryOptions(firmId, roleId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:roles:write`

Update a firm-specific role
 * @summary Update Firm Role
 */
export const updateFirmRoleV1 = (
  firmId: string,
  roleId: string,
  firmRoleRequestV1: FirmRoleRequestV1
) => {
  return fetchInstance<RoleV1>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: firmRoleRequestV1,
  })
}

export const getUpdateFirmRoleV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmRoleV1>>,
    TError,
    { firmId: string; roleId: string; data: FirmRoleRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFirmRoleV1>>,
  TError,
  { firmId: string; roleId: string; data: FirmRoleRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFirmRoleV1>>,
    { firmId: string; roleId: string; data: FirmRoleRequestV1 }
  > = (props) => {
    const { firmId, roleId, data } = props ?? {}

    return updateFirmRoleV1(firmId, roleId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFirmRoleV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFirmRoleV1>>
>
export type UpdateFirmRoleV1MutationBody = FirmRoleRequestV1
export type UpdateFirmRoleV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update Firm Role
 */
export const useUpdateFirmRoleV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmRoleV1>>,
    TError,
    { firmId: string; roleId: string; data: FirmRoleRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFirmRoleV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:roles:write`

Delete a firm-specific role
 * @summary Delete Firm Role
 */
export const deleteFirmRoleV1 = (firmId: string, roleId: string) => {
  return fetchInstance<number>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}`,
    method: 'DELETE',
  })
}

export const getDeleteFirmRoleV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFirmRoleV1>>,
    TError,
    { firmId: string; roleId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFirmRoleV1>>,
  TError,
  { firmId: string; roleId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFirmRoleV1>>,
    { firmId: string; roleId: string }
  > = (props) => {
    const { firmId, roleId } = props ?? {}

    return deleteFirmRoleV1(firmId, roleId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFirmRoleV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFirmRoleV1>>
>

export type DeleteFirmRoleV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Firm Role
 */
export const useDeleteFirmRoleV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFirmRoleV1>>,
    TError,
    { firmId: string; roleId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFirmRoleV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Request a change to a firm-specific role
 * @summary Request Firm Role Change
 */
export const submitFirmRoleChangeV1 = (
  firmId: string,
  roleId: string,
  firmRoleChangeRequestV1: FirmRoleChangeRequestV1
) => {
  return fetchInstance<FirmRoleChangeResultV1>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/change-submissions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmRoleChangeRequestV1,
  })
}

export const getSubmitFirmRoleChangeV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFirmRoleChangeV1>>,
    TError,
    { firmId: string; roleId: string; data: FirmRoleChangeRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitFirmRoleChangeV1>>,
  TError,
  { firmId: string; roleId: string; data: FirmRoleChangeRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitFirmRoleChangeV1>>,
    { firmId: string; roleId: string; data: FirmRoleChangeRequestV1 }
  > = (props) => {
    const { firmId, roleId, data } = props ?? {}

    return submitFirmRoleChangeV1(firmId, roleId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SubmitFirmRoleChangeV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof submitFirmRoleChangeV1>>
>
export type SubmitFirmRoleChangeV1MutationBody = FirmRoleChangeRequestV1
export type SubmitFirmRoleChangeV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Request Firm Role Change
 */
export const useSubmitFirmRoleChangeV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFirmRoleChangeV1>>,
    TError,
    { firmId: string; roleId: string; data: FirmRoleChangeRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSubmitFirmRoleChangeV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:role-change-submissions:read`

Get a firm-specific role change submission by it's ID
 * @summary Get Firm Role Change Submission by ID
 */
export const getFirmRoleChangeSubmissionByIdV1 = (
  firmId: string,
  roleId: string,
  changeSubmissionId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<RoleChangeSubmissionV1>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/change-submissions/${changeSubmissionId}`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmRoleChangeSubmissionByIdV1QueryKey = (
  firmId: string,
  roleId: string,
  changeSubmissionId: string
) => {
  return [
    `/iam/v1/firms/${firmId}/roles/${roleId}/change-submissions/${changeSubmissionId}`,
  ] as const
}

export const getGetFirmRoleChangeSubmissionByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  roleId: string,
  changeSubmissionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFirmRoleChangeSubmissionByIdV1QueryKey(
      firmId,
      roleId,
      changeSubmissionId
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>
  > = ({ signal }) =>
    getFirmRoleChangeSubmissionByIdV1(
      firmId,
      roleId,
      changeSubmissionId,
      signal
    )

  return {
    queryKey,
    queryFn,
    enabled: !!(firmId && roleId && changeSubmissionId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmRoleChangeSubmissionByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>
>
export type GetFirmRoleChangeSubmissionByIdV1QueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get Firm Role Change Submission by ID
 */
export const useGetFirmRoleChangeSubmissionByIdV1 = <
  TData = Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  roleId: string,
  changeSubmissionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRoleChangeSubmissionByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmRoleChangeSubmissionByIdV1QueryOptions(
    firmId,
    roleId,
    changeSubmissionId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:role-change-submissions:approve`

Approve a role change request as the logged in user
 * @summary Approve Role Change Submission
 */
export const approveRoleChangeSubmissionV1 = (
  firmId: string,
  roleId: string,
  changeSubmissionId: string,
  roleChangeSubmissionActionInputV1: RoleChangeSubmissionActionInputV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/change-submissions/${changeSubmissionId}/approve`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleChangeSubmissionActionInputV1,
  })
}

export const getApproveRoleChangeSubmissionV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveRoleChangeSubmissionV1>>,
    TError,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof approveRoleChangeSubmissionV1>>,
  TError,
  {
    firmId: string
    roleId: string
    changeSubmissionId: string
    data: RoleChangeSubmissionActionInputV1
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approveRoleChangeSubmissionV1>>,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    }
  > = (props) => {
    const { firmId, roleId, changeSubmissionId, data } = props ?? {}

    return approveRoleChangeSubmissionV1(
      firmId,
      roleId,
      changeSubmissionId,
      data
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type ApproveRoleChangeSubmissionV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof approveRoleChangeSubmissionV1>>
>
export type ApproveRoleChangeSubmissionV1MutationBody =
  RoleChangeSubmissionActionInputV1
export type ApproveRoleChangeSubmissionV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Approve Role Change Submission
 */
export const useApproveRoleChangeSubmissionV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveRoleChangeSubmissionV1>>,
    TError,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    },
    TContext
  >
}) => {
  const mutationOptions =
    getApproveRoleChangeSubmissionV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:role-change-submissions:approve`

Reject a role change submission as the logged in user
 * @summary Reject Role Change Submission
 */
export const rejectRoleChangeSubmissionV1 = (
  firmId: string,
  roleId: string,
  changeSubmissionId: string,
  roleChangeSubmissionActionInputV1: RoleChangeSubmissionActionInputV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/change-submissions/${changeSubmissionId}/reject`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleChangeSubmissionActionInputV1,
  })
}

export const getRejectRoleChangeSubmissionV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectRoleChangeSubmissionV1>>,
    TError,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectRoleChangeSubmissionV1>>,
  TError,
  {
    firmId: string
    roleId: string
    changeSubmissionId: string
    data: RoleChangeSubmissionActionInputV1
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectRoleChangeSubmissionV1>>,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    }
  > = (props) => {
    const { firmId, roleId, changeSubmissionId, data } = props ?? {}

    return rejectRoleChangeSubmissionV1(
      firmId,
      roleId,
      changeSubmissionId,
      data
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type RejectRoleChangeSubmissionV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectRoleChangeSubmissionV1>>
>
export type RejectRoleChangeSubmissionV1MutationBody =
  RoleChangeSubmissionActionInputV1
export type RejectRoleChangeSubmissionV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Reject Role Change Submission
 */
export const useRejectRoleChangeSubmissionV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectRoleChangeSubmissionV1>>,
    TError,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    },
    TContext
  >
}) => {
  const mutationOptions =
    getRejectRoleChangeSubmissionV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:role-change-submissions:cancel`

Cancel a role change request as the logged in user
 * @summary Cancel Role Change Request
 */
export const cancelRoleChangeRequestV1 = (
  firmId: string,
  roleId: string,
  changeSubmissionId: string,
  roleChangeSubmissionActionInputV1: RoleChangeSubmissionActionInputV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/change-submissions/${changeSubmissionId}/cancel`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleChangeSubmissionActionInputV1,
  })
}

export const getCancelRoleChangeRequestV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelRoleChangeRequestV1>>,
    TError,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelRoleChangeRequestV1>>,
  TError,
  {
    firmId: string
    roleId: string
    changeSubmissionId: string
    data: RoleChangeSubmissionActionInputV1
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelRoleChangeRequestV1>>,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    }
  > = (props) => {
    const { firmId, roleId, changeSubmissionId, data } = props ?? {}

    return cancelRoleChangeRequestV1(firmId, roleId, changeSubmissionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CancelRoleChangeRequestV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelRoleChangeRequestV1>>
>
export type CancelRoleChangeRequestV1MutationBody =
  RoleChangeSubmissionActionInputV1
export type CancelRoleChangeRequestV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Cancel Role Change Request
 */
export const useCancelRoleChangeRequestV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelRoleChangeRequestV1>>,
    TError,
    {
      firmId: string
      roleId: string
      changeSubmissionId: string
      data: RoleChangeSubmissionActionInputV1
    },
    TContext
  >
}) => {
  const mutationOptions = getCancelRoleChangeRequestV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `TBC`

Request a recertification of a firm-specific role
 * @summary Request Firm Role Recertification
 */
export const submitFirmRoleRecertificationV1 = (
  firmId: string,
  firmRoleRecertificationRequestV1: FirmRoleRecertificationRequestV1
) => {
  return fetchInstance<FirmRoleRecertificationResultV1[]>({
    url: `/iam/v1/firms/${firmId}/roles/recertification`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmRoleRecertificationRequestV1,
  })
}

export const getSubmitFirmRoleRecertificationV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFirmRoleRecertificationV1>>,
    TError,
    { firmId: string; data: FirmRoleRecertificationRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitFirmRoleRecertificationV1>>,
  TError,
  { firmId: string; data: FirmRoleRecertificationRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitFirmRoleRecertificationV1>>,
    { firmId: string; data: FirmRoleRecertificationRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return submitFirmRoleRecertificationV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SubmitFirmRoleRecertificationV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof submitFirmRoleRecertificationV1>>
>
export type SubmitFirmRoleRecertificationV1MutationBody =
  FirmRoleRecertificationRequestV1
export type SubmitFirmRoleRecertificationV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Request Firm Role Recertification
 */
export const useSubmitFirmRoleRecertificationV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFirmRoleRecertificationV1>>,
    TError,
    { firmId: string; data: FirmRoleRecertificationRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getSubmitFirmRoleRecertificationV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:role-recertification:read`

Get a firm-specific role recertification by it's ID
 * @summary Get Firm Role Recertification by ID
 */
export const getFirmRoleRecertificationByIdV1 = (
  firmId: string,
  roleId: string,
  recertificationId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<RoleRecertificationV1>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/recertification/${recertificationId}`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmRoleRecertificationByIdV1QueryKey = (
  firmId: string,
  roleId: string,
  recertificationId: string
) => {
  return [
    `/iam/v1/firms/${firmId}/roles/${roleId}/recertification/${recertificationId}`,
  ] as const
}

export const getGetFirmRoleRecertificationByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  roleId: string,
  recertificationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFirmRoleRecertificationByIdV1QueryKey(
      firmId,
      roleId,
      recertificationId
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>
  > = ({ signal }) =>
    getFirmRoleRecertificationByIdV1(firmId, roleId, recertificationId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(firmId && roleId && recertificationId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmRoleRecertificationByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>
>
export type GetFirmRoleRecertificationByIdV1QueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get Firm Role Recertification by ID
 */
export const useGetFirmRoleRecertificationByIdV1 = <
  TData = Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  roleId: string,
  recertificationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmRoleRecertificationByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmRoleRecertificationByIdV1QueryOptions(
    firmId,
    roleId,
    recertificationId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:role-recertification:approve`

Approve a role recertification as the logged in user
 * @summary Approve Role Recertification
 */
export const approveRoleRecertificationV1 = (
  firmId: string,
  roleId: string,
  recertificationId: string,
  roleRecertificationActionInputV1: RoleRecertificationActionInputV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/recertification/${recertificationId}/approve`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleRecertificationActionInputV1,
  })
}

export const getApproveRoleRecertificationV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveRoleRecertificationV1>>,
    TError,
    {
      firmId: string
      roleId: string
      recertificationId: string
      data: RoleRecertificationActionInputV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof approveRoleRecertificationV1>>,
  TError,
  {
    firmId: string
    roleId: string
    recertificationId: string
    data: RoleRecertificationActionInputV1
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approveRoleRecertificationV1>>,
    {
      firmId: string
      roleId: string
      recertificationId: string
      data: RoleRecertificationActionInputV1
    }
  > = (props) => {
    const { firmId, roleId, recertificationId, data } = props ?? {}

    return approveRoleRecertificationV1(firmId, roleId, recertificationId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ApproveRoleRecertificationV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof approveRoleRecertificationV1>>
>
export type ApproveRoleRecertificationV1MutationBody =
  RoleRecertificationActionInputV1
export type ApproveRoleRecertificationV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Approve Role Recertification
 */
export const useApproveRoleRecertificationV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveRoleRecertificationV1>>,
    TError,
    {
      firmId: string
      roleId: string
      recertificationId: string
      data: RoleRecertificationActionInputV1
    },
    TContext
  >
}) => {
  const mutationOptions =
    getApproveRoleRecertificationV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:role-recertification:cancel`

Cancel a role recertification as the logged in user
 * @summary Cancel Role Recertification
 */
export const cancelRoleRecertificationV1 = (
  firmId: string,
  roleId: string,
  recertificationId: string,
  roleRecertificationActionInputV1: RoleRecertificationActionInputV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/roles/${roleId}/recertification/${recertificationId}/cancel`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: roleRecertificationActionInputV1,
  })
}

export const getCancelRoleRecertificationV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelRoleRecertificationV1>>,
    TError,
    {
      firmId: string
      roleId: string
      recertificationId: string
      data: RoleRecertificationActionInputV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelRoleRecertificationV1>>,
  TError,
  {
    firmId: string
    roleId: string
    recertificationId: string
    data: RoleRecertificationActionInputV1
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelRoleRecertificationV1>>,
    {
      firmId: string
      roleId: string
      recertificationId: string
      data: RoleRecertificationActionInputV1
    }
  > = (props) => {
    const { firmId, roleId, recertificationId, data } = props ?? {}

    return cancelRoleRecertificationV1(firmId, roleId, recertificationId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CancelRoleRecertificationV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelRoleRecertificationV1>>
>
export type CancelRoleRecertificationV1MutationBody =
  RoleRecertificationActionInputV1
export type CancelRoleRecertificationV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Cancel Role Recertification
 */
export const useCancelRoleRecertificationV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelRoleRecertificationV1>>,
    TError,
    {
      firmId: string
      roleId: string
      recertificationId: string
      data: RoleRecertificationActionInputV1
    },
    TContext
  >
}) => {
  const mutationOptions = getCancelRoleRecertificationV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:capabilities:write`

Replace all capabilities for a firm with a new capability list
 * @summary Replace Firm Capabilities
 */
export const replaceFirmCapabilitiesV1 = (
  firmId: string,
  capabilityIdsBody: CapabilityIdsBody
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/capabilities`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: capabilityIdsBody,
  })
}

export const getReplaceFirmCapabilitiesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceFirmCapabilitiesV1>>,
    TError,
    { firmId: string; data: CapabilityIdsBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof replaceFirmCapabilitiesV1>>,
  TError,
  { firmId: string; data: CapabilityIdsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replaceFirmCapabilitiesV1>>,
    { firmId: string; data: CapabilityIdsBody }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return replaceFirmCapabilitiesV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ReplaceFirmCapabilitiesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof replaceFirmCapabilitiesV1>>
>
export type ReplaceFirmCapabilitiesV1MutationBody = CapabilityIdsBody
export type ReplaceFirmCapabilitiesV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Replace Firm Capabilities
 */
export const useReplaceFirmCapabilitiesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceFirmCapabilitiesV1>>,
    TError,
    { firmId: string; data: CapabilityIdsBody },
    TContext
  >
}) => {
  const mutationOptions = getReplaceFirmCapabilitiesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:capabilities:write`

Add capability to a firm
 * @summary Add Firm Capability
 */
export const addFirmCapabilityV1 = (firmId: string, capabilityId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/capabilities/${capabilityId}`,
    method: 'PUT',
  })
}

export const getAddFirmCapabilityV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addFirmCapabilityV1>>,
    TError,
    { firmId: string; capabilityId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addFirmCapabilityV1>>,
  TError,
  { firmId: string; capabilityId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addFirmCapabilityV1>>,
    { firmId: string; capabilityId: string }
  > = (props) => {
    const { firmId, capabilityId } = props ?? {}

    return addFirmCapabilityV1(firmId, capabilityId)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddFirmCapabilityV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addFirmCapabilityV1>>
>

export type AddFirmCapabilityV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add Firm Capability
 */
export const useAddFirmCapabilityV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addFirmCapabilityV1>>,
    TError,
    { firmId: string; capabilityId: string },
    TContext
  >
}) => {
  const mutationOptions = getAddFirmCapabilityV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:capabilities:write`

Remove capability from a firm
 * @summary Remove Firm Capability
 */
export const removeFirmCapabilityV1 = (
  firmId: string,
  capabilityId: string
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/capabilities/${capabilityId}`,
    method: 'DELETE',
  })
}

export const getRemoveFirmCapabilityV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeFirmCapabilityV1>>,
    TError,
    { firmId: string; capabilityId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeFirmCapabilityV1>>,
  TError,
  { firmId: string; capabilityId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeFirmCapabilityV1>>,
    { firmId: string; capabilityId: string }
  > = (props) => {
    const { firmId, capabilityId } = props ?? {}

    return removeFirmCapabilityV1(firmId, capabilityId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemoveFirmCapabilityV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof removeFirmCapabilityV1>>
>

export type RemoveFirmCapabilityV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove Firm Capability
 */
export const useRemoveFirmCapabilityV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeFirmCapabilityV1>>,
    TError,
    { firmId: string; capabilityId: string },
    TContext
  >
}) => {
  const mutationOptions = getRemoveFirmCapabilityV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Find permissions that are available for the firm
 * @summary Get Firm Permissions
 */
export const getFirmPermissionsV1 = (firmId: string, signal?: AbortSignal) => {
  return fetchInstance<PermissionV1[]>({
    url: `/iam/v1/firms/${firmId}/permissions`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmPermissionsV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/permissions`] as const
}

export const getGetFirmPermissionsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmPermissionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmPermissionsV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFirmPermissionsV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFirmPermissionsV1>>
  > = ({ signal }) => getFirmPermissionsV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmPermissionsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmPermissionsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmPermissionsV1>>
>
export type GetFirmPermissionsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Firm Permissions
 */
export const useGetFirmPermissionsV1 = <
  TData = Awaited<ReturnType<typeof getFirmPermissionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmPermissionsV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmPermissionsV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Return all firms or a subset of firms using searching and filtering in a sorted and paged list
 * @summary Search Firms
 */
export const searchFirmsV1 = (firmSearchRequestV1: FirmSearchRequestV1) => {
  return fetchInstance<PagedFirmsV1>({
    url: `/iam/v1/firms/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmSearchRequestV1,
  })
}

export const getSearchFirmsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFirmsV1>>,
    TError,
    { data: FirmSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFirmsV1>>,
  TError,
  { data: FirmSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFirmsV1>>,
    { data: FirmSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchFirmsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFirmsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFirmsV1>>
>
export type SearchFirmsV1MutationBody = FirmSearchRequestV1
export type SearchFirmsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search Firms
 */
export const useSearchFirmsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFirmsV1>>,
    TError,
    { data: FirmSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchFirmsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Get a specific firm by its ID
 * @summary Get Firm by ID
 */
export const getFirmByIdV1 = (firmId: string, signal?: AbortSignal) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/firms/${firmId}`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmByIdV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}`] as const
}

export const getGetFirmByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFirmByIdV1QueryKey(firmId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFirmByIdV1>>> = ({
    signal,
  }) => getFirmByIdV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmByIdV1>>
>
export type GetFirmByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Firm by ID
 */
export const useGetFirmByIdV1 = <
  TData = Awaited<ReturnType<typeof getFirmByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmByIdV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:write`

Create or update a firm
 * @summary Update Firm by ID
 */
export const updateFirmByIdV1 = (
  firmId: string,
  firmRequestV1: FirmRequestV1
) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/firms/${firmId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: firmRequestV1,
  })
}

export const getUpdateFirmByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmByIdV1>>,
    TError,
    { firmId: string; data: FirmRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFirmByIdV1>>,
  TError,
  { firmId: string; data: FirmRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFirmByIdV1>>,
    { firmId: string; data: FirmRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return updateFirmByIdV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFirmByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFirmByIdV1>>
>
export type UpdateFirmByIdV1MutationBody = FirmRequestV1
export type UpdateFirmByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update Firm by ID
 */
export const useUpdateFirmByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmByIdV1>>,
    TError,
    { firmId: string; data: FirmRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFirmByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:delete`

Delete a firm by its ID
 * @summary Delete Firm by ID
 */
export const deleteFirmByIdV1 = (firmId: string) => {
  return fetchInstance<number>({
    url: `/iam/v1/firms/${firmId}`,
    method: 'DELETE',
  })
}

export const getDeleteFirmByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFirmByIdV1>>,
    TError,
    { firmId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFirmByIdV1>>,
  TError,
  { firmId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFirmByIdV1>>,
    { firmId: string }
  > = (props) => {
    const { firmId } = props ?? {}

    return deleteFirmByIdV1(firmId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFirmByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFirmByIdV1>>
>

export type DeleteFirmByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Firm by ID
 */
export const useDeleteFirmByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFirmByIdV1>>,
    TError,
    { firmId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFirmByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:write`

Partially update a firm using JsonPatch syntax
 * @summary Patch Update Firm
 */
export const patchUpdateFirmV1 = (
  firmId: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/firms/${firmId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getPatchUpdateFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateFirmV1>>,
    TError,
    { firmId: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUpdateFirmV1>>,
  TError,
  { firmId: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUpdateFirmV1>>,
    { firmId: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return patchUpdateFirmV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchUpdateFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUpdateFirmV1>>
>
export type PatchUpdateFirmV1MutationBody = PatchRequestBodyBody
export type PatchUpdateFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Patch Update Firm
 */
export const usePatchUpdateFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateFirmV1>>,
    TError,
    { firmId: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchUpdateFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Get the sso providers for a specific firm ID
 * @summary Get SSO Providers by Firm ID
 */
export const getSsoProviderByFirmIdV1 = (
  firmId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<SsoProviderV1[]>({
    url: `/iam/v1/firms/${firmId}/sso-provider`,
    method: 'GET',
    signal,
  })
}

export const getGetSsoProviderByFirmIdV1QueryKey = (firmId: string) => {
  return [`/iam/v1/firms/${firmId}/sso-provider`] as const
}

export const getGetSsoProviderByFirmIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetSsoProviderByFirmIdV1QueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>
  > = ({ signal }) => getSsoProviderByFirmIdV1(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSsoProviderByFirmIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>
>
export type GetSsoProviderByFirmIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get SSO Providers by Firm ID
 */
export const useGetSsoProviderByFirmIdV1 = <
  TData = Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSsoProviderByFirmIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSsoProviderByFirmIdV1QueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Get a list of all firm names that exist paired with their firmIds
 * @summary Search Firms Names
 */
export const searchFirmsNamesV1 = (
  params?: SearchFirmsNamesV1Params,
  signal?: AbortSignal
) => {
  return fetchInstance<IdNameTupleV1[]>({
    url: `/iam/v1/firms/names`,
    method: 'GET',
    params,
    signal,
  })
}

export const getSearchFirmsNamesV1QueryKey = (
  params?: SearchFirmsNamesV1Params
) => {
  return [`/iam/v1/firms/names`, ...(params ? [params] : [])] as const
}

export const getSearchFirmsNamesV1QueryOptions = <
  TData = Awaited<ReturnType<typeof searchFirmsNamesV1>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: SearchFirmsNamesV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFirmsNamesV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchFirmsNamesV1QueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchFirmsNamesV1>>
  > = ({ signal }) => searchFirmsNamesV1(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchFirmsNamesV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchFirmsNamesV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFirmsNamesV1>>
>
export type SearchFirmsNamesV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Search Firms Names
 */
export const useSearchFirmsNamesV1 = <
  TData = Awaited<ReturnType<typeof searchFirmsNamesV1>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: SearchFirmsNamesV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFirmsNamesV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchFirmsNamesV1QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:write`

Replace all policies for a firm with a new policy list
 * @summary Replace Firm Policies
 */
export const replaceFirmPoliciesV1 = (
  firmId: string,
  saveEntityPolicyRequestsBody: SaveEntityPolicyRequestsBody
) => {
  return fetchInstance<void>({
    url: `/iam/v1/firms/${firmId}/policies`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: saveEntityPolicyRequestsBody,
  })
}

export const getReplaceFirmPoliciesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceFirmPoliciesV1>>,
    TError,
    { firmId: string; data: SaveEntityPolicyRequestsBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof replaceFirmPoliciesV1>>,
  TError,
  { firmId: string; data: SaveEntityPolicyRequestsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replaceFirmPoliciesV1>>,
    { firmId: string; data: SaveEntityPolicyRequestsBody }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return replaceFirmPoliciesV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ReplaceFirmPoliciesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof replaceFirmPoliciesV1>>
>
export type ReplaceFirmPoliciesV1MutationBody = SaveEntityPolicyRequestsBody
export type ReplaceFirmPoliciesV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Replace Firm Policies
 */
export const useReplaceFirmPoliciesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof replaceFirmPoliciesV1>>,
    TError,
    { firmId: string; data: SaveEntityPolicyRequestsBody },
    TContext
  >
}) => {
  const mutationOptions = getReplaceFirmPoliciesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:write`

Utility endpoint which allows adding a firm with a specified ID (in place of a generated ID). Only exists in LOCAL, DEV and QA envs.
 * @summary Create Firm with ID
 */
export const createFirmWithIdV1 = (
  firmId: string,
  firmRequestV1: FirmRequestV1
) => {
  return fetchInstance<FirmV1>({
    url: `/iam/v1/test-utils/firms/${firmId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmRequestV1,
  })
}

export const getCreateFirmWithIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFirmWithIdV1>>,
    TError,
    { firmId: string; data: FirmRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFirmWithIdV1>>,
  TError,
  { firmId: string; data: FirmRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFirmWithIdV1>>,
    { firmId: string; data: FirmRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createFirmWithIdV1(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFirmWithIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createFirmWithIdV1>>
>
export type CreateFirmWithIdV1MutationBody = FirmRequestV1
export type CreateFirmWithIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Firm with ID
 */
export const useCreateFirmWithIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFirmWithIdV1>>,
    TError,
    { firmId: string; data: FirmRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateFirmWithIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:capabilities:write`

Utility endpoint which allows adding the CAIS capability to a firm. Only exists in LOCAL, DEV and QA envs.
 * @summary Add CAIS Capability to Firm
 */
export const addCaisCapabilityToFirmV1 = (firmId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/test-utils/firms/${firmId}/capabilities/cais`,
    method: 'PUT',
  })
}

export const getAddCaisCapabilityToFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCaisCapabilityToFirmV1>>,
    TError,
    { firmId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addCaisCapabilityToFirmV1>>,
  TError,
  { firmId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addCaisCapabilityToFirmV1>>,
    { firmId: string }
  > = (props) => {
    const { firmId } = props ?? {}

    return addCaisCapabilityToFirmV1(firmId)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddCaisCapabilityToFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addCaisCapabilityToFirmV1>>
>

export type AddCaisCapabilityToFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add CAIS Capability to Firm
 */
export const useAddCaisCapabilityToFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCaisCapabilityToFirmV1>>,
    TError,
    { firmId: string },
    TContext
  >
}) => {
  const mutationOptions = getAddCaisCapabilityToFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Utility endpoint to delete a user regardless of status
 * @summary Force Delete User
 */
export const forceDeleteUserV1 = (userId: string) => {
  return fetchInstance<number>({
    url: `/iam/v1/test-utils/users/${userId}`,
    method: 'DELETE',
  })
}

export const getForceDeleteUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forceDeleteUserV1>>,
    TError,
    { userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof forceDeleteUserV1>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forceDeleteUserV1>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return forceDeleteUserV1(userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type ForceDeleteUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof forceDeleteUserV1>>
>

export type ForceDeleteUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Force Delete User
 */
export const useForceDeleteUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forceDeleteUserV1>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const mutationOptions = getForceDeleteUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:users:write`

Utility endpoint to force change a user status
 * @summary Force Update User Status
 */
export const forceUpdateUserStatusV1 = (
  userId: string,
  status: UserStatusV1
) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/test-utils/users/${userId}/status/${status}`,
    method: 'PUT',
  })
}

export const getForceUpdateUserStatusV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forceUpdateUserStatusV1>>,
    TError,
    { userId: string; status: UserStatusV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof forceUpdateUserStatusV1>>,
  TError,
  { userId: string; status: UserStatusV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forceUpdateUserStatusV1>>,
    { userId: string; status: UserStatusV1 }
  > = (props) => {
    const { userId, status } = props ?? {}

    return forceUpdateUserStatusV1(userId, status)
  }

  return { mutationFn, ...mutationOptions }
}

export type ForceUpdateUserStatusV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof forceUpdateUserStatusV1>>
>

export type ForceUpdateUserStatusV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Force Update User Status
 */
export const useForceUpdateUserStatusV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forceUpdateUserStatusV1>>,
    TError,
    { userId: string; status: UserStatusV1 },
    TContext
  >
}) => {
  const mutationOptions = getForceUpdateUserStatusV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:teams:write`

Utility endpoint to delete a team regardless of state
 * @summary Force Delete Team
 */
export const forceDeleteTeamV1 = (teamId: string) => {
  return fetchInstance<number>({
    url: `/iam/v1/test-utils/teams/${teamId}`,
    method: 'DELETE',
  })
}

export const getForceDeleteTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forceDeleteTeamV1>>,
    TError,
    { teamId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof forceDeleteTeamV1>>,
  TError,
  { teamId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forceDeleteTeamV1>>,
    { teamId: string }
  > = (props) => {
    const { teamId } = props ?? {}

    return forceDeleteTeamV1(teamId)
  }

  return { mutationFn, ...mutationOptions }
}

export type ForceDeleteTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof forceDeleteTeamV1>>
>

export type ForceDeleteTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Force Delete Team
 */
export const useForceDeleteTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forceDeleteTeamV1>>,
    TError,
    { teamId: string },
    TContext
  >
}) => {
  const mutationOptions = getForceDeleteTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Utility endpoint to run data load simulation
 * @summary Run Data Simulation
 */
export const runDataSimulationV1 = (
  simulation: SimulationV1,
  dataSimulationRequestV1: DataSimulationRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/test-utils/simulations/${simulation}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: dataSimulationRequestV1,
  })
}

export const getRunDataSimulationV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runDataSimulationV1>>,
    TError,
    { simulation: SimulationV1; data: DataSimulationRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof runDataSimulationV1>>,
  TError,
  { simulation: SimulationV1; data: DataSimulationRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runDataSimulationV1>>,
    { simulation: SimulationV1; data: DataSimulationRequestV1 }
  > = (props) => {
    const { simulation, data } = props ?? {}

    return runDataSimulationV1(simulation, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type RunDataSimulationV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof runDataSimulationV1>>
>
export type RunDataSimulationV1MutationBody = DataSimulationRequestV1
export type RunDataSimulationV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Run Data Simulation
 */
export const useRunDataSimulationV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runDataSimulationV1>>,
    TError,
    { simulation: SimulationV1; data: DataSimulationRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getRunDataSimulationV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Utility endpoint to delete test data
 * @summary Delete Test Data
 */
export const deleteTestDataV1 = (
  deleteTestDataRequestV1: DeleteTestDataRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/v1/test-utils/delete-test-data`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: deleteTestDataRequestV1,
  })
}

export const getDeleteTestDataV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTestDataV1>>,
    TError,
    { data: DeleteTestDataRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTestDataV1>>,
  TError,
  { data: DeleteTestDataRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTestDataV1>>,
    { data: DeleteTestDataRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return deleteTestDataV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteTestDataV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTestDataV1>>
>
export type DeleteTestDataV1MutationBody = DeleteTestDataRequestV1
export type DeleteTestDataV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Test Data
 */
export const useDeleteTestDataV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTestDataV1>>,
    TError,
    { data: DeleteTestDataRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getDeleteTestDataV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Create a new policy
 * @summary Create Policy
 */
export const createPolicyV1 = (
  createPolicyRequestV1: CreatePolicyRequestV1
) => {
  return fetchInstance<PolicyV1>({
    url: `/iam/v1/policy`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPolicyRequestV1,
  })
}

export const getCreatePolicyV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPolicyV1>>,
    TError,
    { data: CreatePolicyRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPolicyV1>>,
  TError,
  { data: CreatePolicyRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPolicyV1>>,
    { data: CreatePolicyRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createPolicyV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePolicyV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createPolicyV1>>
>
export type CreatePolicyV1MutationBody = CreatePolicyRequestV1
export type CreatePolicyV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Policy
 */
export const useCreatePolicyV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPolicyV1>>,
    TError,
    { data: CreatePolicyRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreatePolicyV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Get a policy by its ID
 * @summary Get Policy by ID
 */
export const getPolicyByIdV1 = (id: string, signal?: AbortSignal) => {
  return fetchInstance<PolicyV1>({
    url: `/iam/v1/policy/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetPolicyByIdV1QueryKey = (id: string) => {
  return [`/iam/v1/policy/${id}`] as const
}

export const getGetPolicyByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getPolicyByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPolicyByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPolicyByIdV1QueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPolicyByIdV1>>> = ({
    signal,
  }) => getPolicyByIdV1(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPolicyByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPolicyByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getPolicyByIdV1>>
>
export type GetPolicyByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Policy by ID
 */
export const useGetPolicyByIdV1 = <
  TData = Awaited<ReturnType<typeof getPolicyByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPolicyByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPolicyByIdV1QueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Delete a policy by its ID
 * @summary Delete Policy by ID
 */
export const deletePolicyByIdV1 = (id: string) => {
  return fetchInstance<void>({ url: `/iam/v1/policy/${id}`, method: 'DELETE' })
}

export const getDeletePolicyByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyByIdV1>>,
    TError,
    { id: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePolicyByIdV1>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePolicyByIdV1>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return deletePolicyByIdV1(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePolicyByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePolicyByIdV1>>
>

export type DeletePolicyByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete Policy by ID
 */
export const useDeletePolicyByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePolicyByIdV1>>,
    TError,
    { id: string },
    TContext
  >
}) => {
  const mutationOptions = getDeletePolicyByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:tech-ops:admin`

Add a new Terms and Conditions to the system
 * @summary Add Terms and Conditions
 */
export const addTermsAndConditionsV1 = (
  createTermsAndConditionsRequestV1: CreateTermsAndConditionsRequestV1
) => {
  return fetchInstance<TermsAndConditionsV1>({
    url: `/iam/v1/terms`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTermsAndConditionsRequestV1,
  })
}

export const getAddTermsAndConditionsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTermsAndConditionsV1>>,
    TError,
    { data: CreateTermsAndConditionsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addTermsAndConditionsV1>>,
  TError,
  { data: CreateTermsAndConditionsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addTermsAndConditionsV1>>,
    { data: CreateTermsAndConditionsRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return addTermsAndConditionsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddTermsAndConditionsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addTermsAndConditionsV1>>
>
export type AddTermsAndConditionsV1MutationBody =
  CreateTermsAndConditionsRequestV1
export type AddTermsAndConditionsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add Terms and Conditions
 */
export const useAddTermsAndConditionsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTermsAndConditionsV1>>,
    TError,
    { data: CreateTermsAndConditionsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getAddTermsAndConditionsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get current users permissions for an IAM resource
 * @summary Get Resource Permissions
 */
export const getResourcePermissionsV1 = (
  resourceType: string,
  resourceId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<PolicyResultV1>({
    url: `/iam/v1/resource-permissions/${resourceType}/${resourceId}`,
    method: 'GET',
    signal,
  })
}

export const getGetResourcePermissionsV1QueryKey = (
  resourceType: string,
  resourceId: string
) => {
  return [`/iam/v1/resource-permissions/${resourceType}/${resourceId}`] as const
}

export const getGetResourcePermissionsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getResourcePermissionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  resourceType: string,
  resourceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getResourcePermissionsV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetResourcePermissionsV1QueryKey(resourceType, resourceId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getResourcePermissionsV1>>
  > = ({ signal }) => getResourcePermissionsV1(resourceType, resourceId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(resourceType && resourceId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getResourcePermissionsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetResourcePermissionsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getResourcePermissionsV1>>
>
export type GetResourcePermissionsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Resource Permissions
 */
export const useGetResourcePermissionsV1 = <
  TData = Awaited<ReturnType<typeof getResourcePermissionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  resourceType: string,
  resourceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getResourcePermissionsV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetResourcePermissionsV1QueryOptions(
    resourceType,
    resourceId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get current users permissions for a list of IAM resources
 * @summary Get Resources Permissions
 */
export const getResourcesPermissionsV1 = (
  resourceType: string,
  params: GetResourcesPermissionsV1Params,
  signal?: AbortSignal
) => {
  return fetchInstance<GetResourcesPermissionsV1200>({
    url: `/iam/v1/resource-permissions/${resourceType}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetResourcesPermissionsV1QueryKey = (
  resourceType: string,
  params: GetResourcesPermissionsV1Params
) => {
  return [
    `/iam/v1/resource-permissions/${resourceType}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetResourcesPermissionsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getResourcesPermissionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  resourceType: string,
  params: GetResourcesPermissionsV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getResourcesPermissionsV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetResourcesPermissionsV1QueryKey(resourceType, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getResourcesPermissionsV1>>
  > = ({ signal }) => getResourcesPermissionsV1(resourceType, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!resourceType,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getResourcesPermissionsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetResourcesPermissionsV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getResourcesPermissionsV1>>
>
export type GetResourcesPermissionsV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Resources Permissions
 */
export const useGetResourcesPermissionsV1 = <
  TData = Awaited<ReturnType<typeof getResourcesPermissionsV1>>,
  TError = ErrorType<ErrorResponse>
>(
  resourceType: string,
  params: GetResourcesPermissionsV1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getResourcesPermissionsV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetResourcesPermissionsV1QueryOptions(
    resourceType,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Trigger migration of all portal firms
 * @summary Migrate All Firms
 */
export const migrateAllFirmsV1 = (params?: MigrateAllFirmsV1Params) => {
  return fetchInstance<void>({
    url: `/iam/v1/migration/firms`,
    method: 'POST',
    params,
  })
}

export const getMigrateAllFirmsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateAllFirmsV1>>,
    TError,
    { params?: MigrateAllFirmsV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof migrateAllFirmsV1>>,
  TError,
  { params?: MigrateAllFirmsV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof migrateAllFirmsV1>>,
    { params?: MigrateAllFirmsV1Params }
  > = (props) => {
    const { params } = props ?? {}

    return migrateAllFirmsV1(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type MigrateAllFirmsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof migrateAllFirmsV1>>
>

export type MigrateAllFirmsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Migrate All Firms
 */
export const useMigrateAllFirmsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateAllFirmsV1>>,
    TError,
    { params?: MigrateAllFirmsV1Params },
    TContext
  >
}) => {
  const mutationOptions = getMigrateAllFirmsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Trigger migration of all portal CAIS users
 * @summary Migrate CAIS Users
 */
export const migrateCaisUsersV1 = (params?: MigrateCaisUsersV1Params) => {
  return fetchInstance<void>({
    url: `/iam/v1/migration/cais-users`,
    method: 'POST',
    params,
  })
}

export const getMigrateCaisUsersV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateCaisUsersV1>>,
    TError,
    { params?: MigrateCaisUsersV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof migrateCaisUsersV1>>,
  TError,
  { params?: MigrateCaisUsersV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof migrateCaisUsersV1>>,
    { params?: MigrateCaisUsersV1Params }
  > = (props) => {
    const { params } = props ?? {}

    return migrateCaisUsersV1(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type MigrateCaisUsersV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof migrateCaisUsersV1>>
>

export type MigrateCaisUsersV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Migrate CAIS Users
 */
export const useMigrateCaisUsersV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateCaisUsersV1>>,
    TError,
    { params?: MigrateCaisUsersV1Params },
    TContext
  >
}) => {
  const mutationOptions = getMigrateCaisUsersV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Trigger migration of a single firm
 * @summary Migrate Firm
 */
export const migrateFirmV1 = (firmId: string, params?: MigrateFirmV1Params) => {
  return fetchInstance<void>({
    url: `/iam/v1/migration/firms/${firmId}`,
    method: 'POST',
    params,
  })
}

export const getMigrateFirmV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateFirmV1>>,
    TError,
    { firmId: string; params?: MigrateFirmV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof migrateFirmV1>>,
  TError,
  { firmId: string; params?: MigrateFirmV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof migrateFirmV1>>,
    { firmId: string; params?: MigrateFirmV1Params }
  > = (props) => {
    const { firmId, params } = props ?? {}

    return migrateFirmV1(firmId, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type MigrateFirmV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof migrateFirmV1>>
>

export type MigrateFirmV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Migrate Firm
 */
export const useMigrateFirmV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateFirmV1>>,
    TError,
    { firmId: string; params?: MigrateFirmV1Params },
    TContext
  >
}) => {
  const mutationOptions = getMigrateFirmV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Trigger migration of a single team
 * @summary Migrate Team
 */
export const migrateTeamV1 = (teamId: string, params?: MigrateTeamV1Params) => {
  return fetchInstance<void>({
    url: `/iam/v1/migration/teams/${teamId}`,
    method: 'POST',
    params,
  })
}

export const getMigrateTeamV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateTeamV1>>,
    TError,
    { teamId: string; params?: MigrateTeamV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof migrateTeamV1>>,
  TError,
  { teamId: string; params?: MigrateTeamV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof migrateTeamV1>>,
    { teamId: string; params?: MigrateTeamV1Params }
  > = (props) => {
    const { teamId, params } = props ?? {}

    return migrateTeamV1(teamId, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type MigrateTeamV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof migrateTeamV1>>
>

export type MigrateTeamV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Migrate Team
 */
export const useMigrateTeamV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateTeamV1>>,
    TError,
    { teamId: string; params?: MigrateTeamV1Params },
    TContext
  >
}) => {
  const mutationOptions = getMigrateTeamV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Trigger migration of a single user
 * @summary Migrate User
 */
export const migrateUserV1 = (userId: string, params?: MigrateUserV1Params) => {
  return fetchInstance<void>({
    url: `/iam/v1/migration/users/${userId}`,
    method: 'POST',
    params,
  })
}

export const getMigrateUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateUserV1>>,
    TError,
    { userId: string; params?: MigrateUserV1Params },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof migrateUserV1>>,
  TError,
  { userId: string; params?: MigrateUserV1Params },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof migrateUserV1>>,
    { userId: string; params?: MigrateUserV1Params }
  > = (props) => {
    const { userId, params } = props ?? {}

    return migrateUserV1(userId, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type MigrateUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof migrateUserV1>>
>

export type MigrateUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Migrate User
 */
export const useMigrateUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof migrateUserV1>>,
    TError,
    { userId: string; params?: MigrateUserV1Params },
    TContext
  >
}) => {
  const mutationOptions = getMigrateUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Upload pre-created user -> role mappings for migration
 * @summary Upload User Roles CSV
 */
export const uploadUserRolesCsvV1 = (
  uploadUserRolesCsvV1Body: UploadUserRolesCsvV1Body
) => {
  const formData = new FormData()
  if (uploadUserRolesCsvV1Body.file !== undefined) {
    formData.append('file', uploadUserRolesCsvV1Body.file)
  }

  return fetchInstance<void>({
    url: `/iam/v1/migration/data/user-roles`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getUploadUserRolesCsvV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadUserRolesCsvV1>>,
    TError,
    { data: UploadUserRolesCsvV1Body },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadUserRolesCsvV1>>,
  TError,
  { data: UploadUserRolesCsvV1Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadUserRolesCsvV1>>,
    { data: UploadUserRolesCsvV1Body }
  > = (props) => {
    const { data } = props ?? {}

    return uploadUserRolesCsvV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadUserRolesCsvV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadUserRolesCsvV1>>
>
export type UploadUserRolesCsvV1MutationBody = UploadUserRolesCsvV1Body
export type UploadUserRolesCsvV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Upload User Roles CSV
 */
export const useUploadUserRolesCsvV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadUserRolesCsvV1>>,
    TError,
    { data: UploadUserRolesCsvV1Body },
    TContext
  >
}) => {
  const mutationOptions = getUploadUserRolesCsvV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Upload pre-created custom firm roles for migration
 * @summary Upload Firm Roles CSV
 */
export const uploadFirmRolesCsvV1 = (
  uploadFirmRolesCsvV1Body: UploadFirmRolesCsvV1Body
) => {
  const formData = new FormData()
  if (uploadFirmRolesCsvV1Body.file !== undefined) {
    formData.append('file', uploadFirmRolesCsvV1Body.file)
  }

  return fetchInstance<void>({
    url: `/iam/v1/migration/data/firm-roles`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getUploadFirmRolesCsvV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFirmRolesCsvV1>>,
    TError,
    { data: UploadFirmRolesCsvV1Body },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadFirmRolesCsvV1>>,
  TError,
  { data: UploadFirmRolesCsvV1Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadFirmRolesCsvV1>>,
    { data: UploadFirmRolesCsvV1Body }
  > = (props) => {
    const { data } = props ?? {}

    return uploadFirmRolesCsvV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadFirmRolesCsvV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadFirmRolesCsvV1>>
>
export type UploadFirmRolesCsvV1MutationBody = UploadFirmRolesCsvV1Body
export type UploadFirmRolesCsvV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Upload Firm Roles CSV
 */
export const useUploadFirmRolesCsvV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFirmRolesCsvV1>>,
    TError,
    { data: UploadFirmRolesCsvV1Body },
    TContext
  >
}) => {
  const mutationOptions = getUploadFirmRolesCsvV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:capabilities:write`

Get list of all available capabilities
 * @summary Get Available Capabilities
 */
export const getAvailableCapabilitiesV1 = (signal?: AbortSignal) => {
  return fetchInstance<CapabilityV1[]>({
    url: `/iam/v1/capabilities`,
    method: 'GET',
    signal,
  })
}

export const getGetAvailableCapabilitiesV1QueryKey = () => {
  return [`/iam/v1/capabilities`] as const
}

export const getGetAvailableCapabilitiesV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAvailableCapabilitiesV1QueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>
  > = ({ signal }) => getAvailableCapabilitiesV1(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAvailableCapabilitiesV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>
>
export type GetAvailableCapabilitiesV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Available Capabilities
 */
export const useGetAvailableCapabilitiesV1 = <
  TData = Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableCapabilitiesV1>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAvailableCapabilitiesV1QueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Get list of all available SSO Providers
 * @summary Get SSO Providers
 */
export const getSsoProvidersV1 = (signal?: AbortSignal) => {
  return fetchInstance<SsoProviderV1[]>({
    url: `/iam/v1/sso/providers`,
    method: 'GET',
    signal,
  })
}

export const getGetSsoProvidersV1QueryKey = () => {
  return [`/iam/v1/sso/providers`] as const
}

export const getGetSsoProvidersV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getSsoProvidersV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSsoProvidersV1>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSsoProvidersV1QueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSsoProvidersV1>>
  > = ({ signal }) => getSsoProvidersV1(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSsoProvidersV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSsoProvidersV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getSsoProvidersV1>>
>
export type GetSsoProvidersV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get SSO Providers
 */
export const useGetSsoProvidersV1 = <
  TData = Awaited<ReturnType<typeof getSsoProvidersV1>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSsoProvidersV1>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSsoProvidersV1QueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:read`

Create a new SSO Provider
 * @summary Create SSO Provider
 */
export const createSsoProviderV1 = (
  ssoProviderRequestV1: SsoProviderRequestV1
) => {
  return fetchInstance<SsoProviderV1>({
    url: `/iam/v1/sso/providers`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: ssoProviderRequestV1,
  })
}

export const getCreateSsoProviderV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSsoProviderV1>>,
    TError,
    { data: SsoProviderRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSsoProviderV1>>,
  TError,
  { data: SsoProviderRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSsoProviderV1>>,
    { data: SsoProviderRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createSsoProviderV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateSsoProviderV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createSsoProviderV1>>
>
export type CreateSsoProviderV1MutationBody = SsoProviderRequestV1
export type CreateSsoProviderV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create SSO Provider
 */
export const useCreateSsoProviderV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSsoProviderV1>>,
    TError,
    { data: SsoProviderRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateSsoProviderV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:read`

Get a SSO Provider by their Id
 * @summary Get SSO Provider by ID
 */
export const getSsoProviderByIdV1 = (
  ssoProviderId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<SsoProviderV1>({
    url: `/iam/v1/sso/providers/${ssoProviderId}`,
    method: 'GET',
    signal,
  })
}

export const getGetSsoProviderByIdV1QueryKey = (ssoProviderId: string) => {
  return [`/iam/v1/sso/providers/${ssoProviderId}`] as const
}

export const getGetSsoProviderByIdV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getSsoProviderByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  ssoProviderId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSsoProviderByIdV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetSsoProviderByIdV1QueryKey(ssoProviderId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSsoProviderByIdV1>>
  > = ({ signal }) => getSsoProviderByIdV1(ssoProviderId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!ssoProviderId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSsoProviderByIdV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSsoProviderByIdV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getSsoProviderByIdV1>>
>
export type GetSsoProviderByIdV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get SSO Provider by ID
 */
export const useGetSsoProviderByIdV1 = <
  TData = Awaited<ReturnType<typeof getSsoProviderByIdV1>>,
  TError = ErrorType<ErrorResponse>
>(
  ssoProviderId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSsoProviderByIdV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSsoProviderByIdV1QueryOptions(
    ssoProviderId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `iam:firms:write`

Update a SSO Provider
 * @summary Update SSO Provider
 */
export const updateSsoProviderV1 = (
  ssoProviderId: string,
  ssoProviderRequestV1: SsoProviderRequestV1
) => {
  return fetchInstance<SsoProviderV1>({
    url: `/iam/v1/sso/providers/${ssoProviderId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: ssoProviderRequestV1,
  })
}

export const getUpdateSsoProviderV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSsoProviderV1>>,
    TError,
    { ssoProviderId: string; data: SsoProviderRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSsoProviderV1>>,
  TError,
  { ssoProviderId: string; data: SsoProviderRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSsoProviderV1>>,
    { ssoProviderId: string; data: SsoProviderRequestV1 }
  > = (props) => {
    const { ssoProviderId, data } = props ?? {}

    return updateSsoProviderV1(ssoProviderId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateSsoProviderV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSsoProviderV1>>
>
export type UpdateSsoProviderV1MutationBody = SsoProviderRequestV1
export type UpdateSsoProviderV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Update SSO Provider
 */
export const useUpdateSsoProviderV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSsoProviderV1>>,
    TError,
    { ssoProviderId: string; data: SsoProviderRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateSsoProviderV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:firms:write`

Delete the SSO Provider with the provided ID
 * @summary Delete SSO Provider by ID
 */
export const deleteSsoProviderByIdV1 = (ssoProviderId: string) => {
  return fetchInstance<void>({
    url: `/iam/v1/sso/providers/${ssoProviderId}`,
    method: 'DELETE',
  })
}

export const getDeleteSsoProviderByIdV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSsoProviderByIdV1>>,
    TError,
    { ssoProviderId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSsoProviderByIdV1>>,
  TError,
  { ssoProviderId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSsoProviderByIdV1>>,
    { ssoProviderId: string }
  > = (props) => {
    const { ssoProviderId } = props ?? {}

    return deleteSsoProviderByIdV1(ssoProviderId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteSsoProviderByIdV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSsoProviderByIdV1>>
>

export type DeleteSsoProviderByIdV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete SSO Provider by ID
 */
export const useDeleteSsoProviderByIdV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSsoProviderByIdV1>>,
    TError,
    { ssoProviderId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteSsoProviderByIdV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Resolve SSO User to CAIS user. If user does not exist and self registration is allowed a new user will be created and mapped to the SSO user
 * @summary Resolve SSO User
 */
export const resolveSsoUserV1 = (
  ssoUserResolutionRequestV1: SsoUserResolutionRequestV1
) => {
  return fetchInstance<UserV1>({
    url: `/iam/v1/sso/resolve-user`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: ssoUserResolutionRequestV1,
  })
}

export const getResolveSsoUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveSsoUserV1>>,
    TError,
    { data: SsoUserResolutionRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof resolveSsoUserV1>>,
  TError,
  { data: SsoUserResolutionRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resolveSsoUserV1>>,
    { data: SsoUserResolutionRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return resolveSsoUserV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResolveSsoUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof resolveSsoUserV1>>
>
export type ResolveSsoUserV1MutationBody = SsoUserResolutionRequestV1
export type ResolveSsoUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Resolve SSO User
 */
export const useResolveSsoUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveSsoUserV1>>,
    TError,
    { data: SsoUserResolutionRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getResolveSsoUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:tech-ops:admin`

Refresh the snapshot of the IAM database
 * @summary Refresh Snapshots
 */
export const refreshSnapshotsV1 = () => {
  return fetchInstance<RefresherStatusV1>({
    url: `/iam/admin/snapshot/refresh`,
    method: 'PUT',
  })
}

export const getRefreshSnapshotsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshSnapshotsV1>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof refreshSnapshotsV1>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshSnapshotsV1>>,
    void
  > = () => {
    return refreshSnapshotsV1()
  }

  return { mutationFn, ...mutationOptions }
}

export type RefreshSnapshotsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshSnapshotsV1>>
>

export type RefreshSnapshotsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Refresh Snapshots
 */
export const useRefreshSnapshotsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshSnapshotsV1>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions = getRefreshSnapshotsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:tech-ops:admin`

Bulk refresh entities in the IAM database (firms, teams, users)
 * @summary Bulk Refresh
 */
export const bulkRefreshV1 = (bulkRefreshRequestV1: BulkRefreshRequestV1) => {
  return fetchInstance<BulkRefreshResponseV1>({
    url: `/iam/admin/bulk/refresh`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: bulkRefreshRequestV1,
  })
}

export const getBulkRefreshV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkRefreshV1>>,
    TError,
    { data: BulkRefreshRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkRefreshV1>>,
  TError,
  { data: BulkRefreshRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkRefreshV1>>,
    { data: BulkRefreshRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return bulkRefreshV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkRefreshV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkRefreshV1>>
>
export type BulkRefreshV1MutationBody = BulkRefreshRequestV1
export type BulkRefreshV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk Refresh
 */
export const useBulkRefreshV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkRefreshV1>>,
    TError,
    { data: BulkRefreshRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getBulkRefreshV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `iam:admin:read`

Get the authentication details for a user
 * @summary Get User Auth Details
 */
export const getAuthUserV1 = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<UserAuthDetailsV1[]>({
    url: `/iam/admin/users/${userId}/auth`,
    method: 'GET',
    signal,
  })
}

export const getGetAuthUserV1QueryKey = (userId: string) => {
  return [`/iam/admin/users/${userId}/auth`] as const
}

export const getGetAuthUserV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthUserV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuthUserV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAuthUserV1QueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthUserV1>>> = ({
    signal,
  }) => getAuthUserV1(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuthUserV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAuthUserV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuthUserV1>>
>
export type GetAuthUserV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get User Auth Details
 */
export const useGetAuthUserV1 = <
  TData = Awaited<ReturnType<typeof getAuthUserV1>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuthUserV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuthUserV1QueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `cais:tech-ops:admin`

Update the authentication details for a user
 * @summary Create User Auth Details
 */
export const createAuthUserV1 = (userId: string) => {
  return fetchInstance<UserAuthDetailsV1>({
    url: `/iam/admin/users/${userId}/auth`,
    method: 'PUT',
  })
}

export const getCreateAuthUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAuthUserV1>>,
    TError,
    { userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAuthUserV1>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAuthUserV1>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return createAuthUserV1(userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateAuthUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createAuthUserV1>>
>

export type CreateAuthUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Create User Auth Details
 */
export const useCreateAuthUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAuthUserV1>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const mutationOptions = getCreateAuthUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:tech-ops:admin`
    
Delete the authentication details for a user
 * @summary Delete User Auth Details
 */
export const deleteAuthUserV1 = (
  userId: string,
  connectionType: ConnectionTypeRequestV1
) => {
  return fetchInstance<void>({
    url: `/iam/admin/users/${userId}/auth/${connectionType}`,
    method: 'DELETE',
  })
}

export const getDeleteAuthUserV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthUserV1>>,
    TError,
    { userId: string; connectionType: ConnectionTypeRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAuthUserV1>>,
  TError,
  { userId: string; connectionType: ConnectionTypeRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAuthUserV1>>,
    { userId: string; connectionType: ConnectionTypeRequestV1 }
  > = (props) => {
    const { userId, connectionType } = props ?? {}

    return deleteAuthUserV1(userId, connectionType)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteAuthUserV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAuthUserV1>>
>

export type DeleteAuthUserV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete User Auth Details
 */
export const useDeleteAuthUserV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthUserV1>>,
    TError,
    { userId: string; connectionType: ConnectionTypeRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getDeleteAuthUserV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:tech-ops:admin`

Add an escalation approver for a firm
 * @summary Add Escalation Approver
 */
export const addEscalationApproverV1 = (firmId: string, userId: string) => {
  return fetchInstance<void>({
    url: `/iam/admin/role-change-submissions/escalation-approvers/${firmId}/${userId}`,
    method: 'PUT',
  })
}

export const getAddEscalationApproverV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addEscalationApproverV1>>,
    TError,
    { firmId: string; userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addEscalationApproverV1>>,
  TError,
  { firmId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addEscalationApproverV1>>,
    { firmId: string; userId: string }
  > = (props) => {
    const { firmId, userId } = props ?? {}

    return addEscalationApproverV1(firmId, userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddEscalationApproverV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof addEscalationApproverV1>>
>

export type AddEscalationApproverV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Add Escalation Approver
 */
export const useAddEscalationApproverV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addEscalationApproverV1>>,
    TError,
    { firmId: string; userId: string },
    TContext
  >
}) => {
  const mutationOptions = getAddEscalationApproverV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:tech-ops:admin`

Remove an escalation approver for a firm
 * @summary Remove Escalation Approver
 */
export const removeEscalationApproverV1 = (firmId: string, userId: string) => {
  return fetchInstance<void>({
    url: `/iam/admin/role-change-submissions/escalation-approvers/${firmId}/${userId}`,
    method: 'DELETE',
  })
}

export const getRemoveEscalationApproverV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeEscalationApproverV1>>,
    TError,
    { firmId: string; userId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeEscalationApproverV1>>,
  TError,
  { firmId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeEscalationApproverV1>>,
    { firmId: string; userId: string }
  > = (props) => {
    const { firmId, userId } = props ?? {}

    return removeEscalationApproverV1(firmId, userId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemoveEscalationApproverV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof removeEscalationApproverV1>>
>

export type RemoveEscalationApproverV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove Escalation Approver
 */
export const useRemoveEscalationApproverV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeEscalationApproverV1>>,
    TError,
    { firmId: string; userId: string },
    TContext
  >
}) => {
  const mutationOptions = getRemoveEscalationApproverV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:read`

Return all feature flags definition or a subset of them using searching and filtering in a sorted and paged list
 * @summary Search feature flags definitions
 */
export const searchFeatureFlagDefinitionsV1 = (
  featureFlagDefinitionSearchRequestV1: FeatureFlagDefinitionSearchRequestV1
) => {
  return fetchInstance<PagedFeatureFlagDefinitionsV1>({
    url: `/iam/v1/feature-flags/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: featureFlagDefinitionSearchRequestV1,
  })
}

export const getSearchFeatureFlagDefinitionsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFeatureFlagDefinitionsV1>>,
    TError,
    { data: FeatureFlagDefinitionSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFeatureFlagDefinitionsV1>>,
  TError,
  { data: FeatureFlagDefinitionSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFeatureFlagDefinitionsV1>>,
    { data: FeatureFlagDefinitionSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchFeatureFlagDefinitionsV1(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFeatureFlagDefinitionsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFeatureFlagDefinitionsV1>>
>
export type SearchFeatureFlagDefinitionsV1MutationBody =
  FeatureFlagDefinitionSearchRequestV1
export type SearchFeatureFlagDefinitionsV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Search feature flags definitions
 */
export const useSearchFeatureFlagDefinitionsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFeatureFlagDefinitionsV1>>,
    TError,
    { data: FeatureFlagDefinitionSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchFeatureFlagDefinitionsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:read`

Get a feature flag definition by id
 * @summary Get a feature flag definition by id
 */
export const getFeatureFlagDefinitionV1 = (
  featureFlagDefinitionId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<FeatureFlagDefinitionWithSummaryResponseV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}`,
    method: 'GET',
    signal,
  })
}

export const getGetFeatureFlagDefinitionV1QueryKey = (
  featureFlagDefinitionId: string
) => {
  return [`/iam/v1/feature-flags/${featureFlagDefinitionId}`] as const
}

export const getGetFeatureFlagDefinitionV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>,
  TError = ErrorType<ErrorResponse>
>(
  featureFlagDefinitionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFeatureFlagDefinitionV1QueryKey(featureFlagDefinitionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>
  > = ({ signal }) =>
    getFeatureFlagDefinitionV1(featureFlagDefinitionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!featureFlagDefinitionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFeatureFlagDefinitionV1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>
>
export type GetFeatureFlagDefinitionV1QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a feature flag definition by id
 */
export const useGetFeatureFlagDefinitionV1 = <
  TData = Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>,
  TError = ErrorType<ErrorResponse>
>(
  featureFlagDefinitionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFeatureFlagDefinitionV1>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFeatureFlagDefinitionV1QueryOptions(
    featureFlagDefinitionId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `cais:feature-flags:write`

Update existing feature flags audiences
 * @summary Update existing feature flags audiences
 */
export const updateFeatureFlagsForAudiencesV1 = (
  featureFlagDefinitionId: string,
  featureFlagsForAudiencesRequestV1: FeatureFlagsForAudiencesRequestV1
) => {
  return fetchInstance<FeatureFlagsForAudiencesResponseV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: featureFlagsForAudiencesRequestV1,
  })
}

export const getUpdateFeatureFlagsForAudiencesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFeatureFlagsForAudiencesV1>>,
    TError,
    {
      featureFlagDefinitionId: string
      data: FeatureFlagsForAudiencesRequestV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFeatureFlagsForAudiencesV1>>,
  TError,
  { featureFlagDefinitionId: string; data: FeatureFlagsForAudiencesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFeatureFlagsForAudiencesV1>>,
    { featureFlagDefinitionId: string; data: FeatureFlagsForAudiencesRequestV1 }
  > = (props) => {
    const { featureFlagDefinitionId, data } = props ?? {}

    return updateFeatureFlagsForAudiencesV1(featureFlagDefinitionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFeatureFlagsForAudiencesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFeatureFlagsForAudiencesV1>>
>
export type UpdateFeatureFlagsForAudiencesV1MutationBody =
  FeatureFlagsForAudiencesRequestV1
export type UpdateFeatureFlagsForAudiencesV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update existing feature flags audiences
 */
export const useUpdateFeatureFlagsForAudiencesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFeatureFlagsForAudiencesV1>>,
    TError,
    {
      featureFlagDefinitionId: string
      data: FeatureFlagsForAudiencesRequestV1
    },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateFeatureFlagsForAudiencesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:write`

Create new feature flags for a selected audiences
 * @summary Create new feature flags for a selected audiences
 */
export const createFeatureFlagsForAudiencesV1 = (
  featureFlagDefinitionId: string,
  featureFlagsForAudiencesRequestV1: FeatureFlagsForAudiencesRequestV1
) => {
  return fetchInstance<FeatureFlagsForAudiencesResponseV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: featureFlagsForAudiencesRequestV1,
  })
}

export const getCreateFeatureFlagsForAudiencesV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFeatureFlagsForAudiencesV1>>,
    TError,
    {
      featureFlagDefinitionId: string
      data: FeatureFlagsForAudiencesRequestV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFeatureFlagsForAudiencesV1>>,
  TError,
  { featureFlagDefinitionId: string; data: FeatureFlagsForAudiencesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFeatureFlagsForAudiencesV1>>,
    { featureFlagDefinitionId: string; data: FeatureFlagsForAudiencesRequestV1 }
  > = (props) => {
    const { featureFlagDefinitionId, data } = props ?? {}

    return createFeatureFlagsForAudiencesV1(featureFlagDefinitionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFeatureFlagsForAudiencesV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createFeatureFlagsForAudiencesV1>>
>
export type CreateFeatureFlagsForAudiencesV1MutationBody =
  FeatureFlagsForAudiencesRequestV1
export type CreateFeatureFlagsForAudiencesV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Create new feature flags for a selected audiences
 */
export const useCreateFeatureFlagsForAudiencesV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFeatureFlagsForAudiencesV1>>,
    TError,
    {
      featureFlagDefinitionId: string
      data: FeatureFlagsForAudiencesRequestV1
    },
    TContext
  >
}) => {
  const mutationOptions =
    getCreateFeatureFlagsForAudiencesV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:write`

Patch update existing feature flags definition.
Only the `enabled` and `fineGrainedConfig` can be updated.
 * @summary Patch Update Feature Flag Definition
 */
export const patchUpdateFeatureFlagDefinitionV1 = (
  featureFlagDefinitionId: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstance<FeatureFlagDefinitionResponseV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getPatchUpdateFeatureFlagDefinitionV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateFeatureFlagDefinitionV1>>,
    TError,
    { featureFlagDefinitionId: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUpdateFeatureFlagDefinitionV1>>,
  TError,
  { featureFlagDefinitionId: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUpdateFeatureFlagDefinitionV1>>,
    { featureFlagDefinitionId: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { featureFlagDefinitionId, data } = props ?? {}

    return patchUpdateFeatureFlagDefinitionV1(featureFlagDefinitionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchUpdateFeatureFlagDefinitionV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUpdateFeatureFlagDefinitionV1>>
>
export type PatchUpdateFeatureFlagDefinitionV1MutationBody =
  PatchRequestBodyBody
export type PatchUpdateFeatureFlagDefinitionV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Patch Update Feature Flag Definition
 */
export const usePatchUpdateFeatureFlagDefinitionV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdateFeatureFlagDefinitionV1>>,
    TError,
    { featureFlagDefinitionId: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions =
    getPatchUpdateFeatureFlagDefinitionV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:write`

Delete an existing firm feature flags audience
 * @summary Delete existing feature flags audience
 */
export const deleteFeatureFlagAudiencesForAudienceV1 = (
  featureFlagDefinitionId: string,
  featureFlagAudience: 'FIRMS' | 'TEAMS' | 'USERS',
  entityId: string
) => {
  return fetchInstance<void>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}/${featureFlagAudience}/${entityId}`,
    method: 'DELETE',
  })
}

export const getDeleteFeatureFlagAudiencesForAudienceV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFeatureFlagAudiencesForAudienceV1>>,
    TError,
    {
      featureFlagDefinitionId: string
      featureFlagAudience: 'FIRMS' | 'TEAMS' | 'USERS'
      entityId: string
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFeatureFlagAudiencesForAudienceV1>>,
  TError,
  {
    featureFlagDefinitionId: string
    featureFlagAudience: 'FIRMS' | 'TEAMS' | 'USERS'
    entityId: string
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFeatureFlagAudiencesForAudienceV1>>,
    {
      featureFlagDefinitionId: string
      featureFlagAudience: 'FIRMS' | 'TEAMS' | 'USERS'
      entityId: string
    }
  > = (props) => {
    const { featureFlagDefinitionId, featureFlagAudience, entityId } =
      props ?? {}

    return deleteFeatureFlagAudiencesForAudienceV1(
      featureFlagDefinitionId,
      featureFlagAudience,
      entityId
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFeatureFlagAudiencesForAudienceV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFeatureFlagAudiencesForAudienceV1>>
>

export type DeleteFeatureFlagAudiencesForAudienceV1MutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Delete existing feature flags audience
 */
export const useDeleteFeatureFlagAudiencesForAudienceV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFeatureFlagAudiencesForAudienceV1>>,
    TError,
    {
      featureFlagDefinitionId: string
      featureFlagAudience: 'FIRMS' | 'TEAMS' | 'USERS'
      entityId: string
    },
    TContext
  >
}) => {
  const mutationOptions =
    getDeleteFeatureFlagAudiencesForAudienceV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:read`

Return all firm feature flags or a subset of them using searching and filtering in a sorted and paged list
 * @summary Search firm feature flags
 */
export const searchFirmFeatureFlagsV1 = (
  featureFlagDefinitionId: string,
  firmFeatureFlagSearchRequestV1: FirmFeatureFlagSearchRequestV1
) => {
  return fetchInstance<PagedFirmFeatureFlagsV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}/firms/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmFeatureFlagSearchRequestV1,
  })
}

export const getSearchFirmFeatureFlagsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFirmFeatureFlagsV1>>,
    TError,
    { featureFlagDefinitionId: string; data: FirmFeatureFlagSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFirmFeatureFlagsV1>>,
  TError,
  { featureFlagDefinitionId: string; data: FirmFeatureFlagSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFirmFeatureFlagsV1>>,
    { featureFlagDefinitionId: string; data: FirmFeatureFlagSearchRequestV1 }
  > = (props) => {
    const { featureFlagDefinitionId, data } = props ?? {}

    return searchFirmFeatureFlagsV1(featureFlagDefinitionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFirmFeatureFlagsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFirmFeatureFlagsV1>>
>
export type SearchFirmFeatureFlagsV1MutationBody =
  FirmFeatureFlagSearchRequestV1
export type SearchFirmFeatureFlagsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search firm feature flags
 */
export const useSearchFirmFeatureFlagsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFirmFeatureFlagsV1>>,
    TError,
    { featureFlagDefinitionId: string; data: FirmFeatureFlagSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchFirmFeatureFlagsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:read`

Return all team feature flags or a subset of them using searching and filtering in a sorted and paged list
 * @summary Search team feature flags
 */
export const searchTeamFeatureFlagsV1 = (
  featureFlagDefinitionId: string,
  teamFeatureFlagSearchRequestV1: TeamFeatureFlagSearchRequestV1
) => {
  return fetchInstance<PagedTeamFeatureFlagsV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}/teams/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: teamFeatureFlagSearchRequestV1,
  })
}

export const getSearchTeamFeatureFlagsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTeamFeatureFlagsV1>>,
    TError,
    { featureFlagDefinitionId: string; data: TeamFeatureFlagSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchTeamFeatureFlagsV1>>,
  TError,
  { featureFlagDefinitionId: string; data: TeamFeatureFlagSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchTeamFeatureFlagsV1>>,
    { featureFlagDefinitionId: string; data: TeamFeatureFlagSearchRequestV1 }
  > = (props) => {
    const { featureFlagDefinitionId, data } = props ?? {}

    return searchTeamFeatureFlagsV1(featureFlagDefinitionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchTeamFeatureFlagsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchTeamFeatureFlagsV1>>
>
export type SearchTeamFeatureFlagsV1MutationBody =
  TeamFeatureFlagSearchRequestV1
export type SearchTeamFeatureFlagsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search team feature flags
 */
export const useSearchTeamFeatureFlagsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTeamFeatureFlagsV1>>,
    TError,
    { featureFlagDefinitionId: string; data: TeamFeatureFlagSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchTeamFeatureFlagsV1MutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `cais:feature-flags:read`

Return all user feature flags or a subset of them using searching and filtering in a sorted and paged list
 * @summary Search user feature flags
 */
export const searchUserFeatureFlagsV1 = (
  featureFlagDefinitionId: string,
  userFeatureFlagSearchRequestV1: UserFeatureFlagSearchRequestV1
) => {
  return fetchInstance<PagedUserFeatureFlagsV1>({
    url: `/iam/v1/feature-flags/${featureFlagDefinitionId}/users/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userFeatureFlagSearchRequestV1,
  })
}

export const getSearchUserFeatureFlagsV1MutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUserFeatureFlagsV1>>,
    TError,
    { featureFlagDefinitionId: string; data: UserFeatureFlagSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchUserFeatureFlagsV1>>,
  TError,
  { featureFlagDefinitionId: string; data: UserFeatureFlagSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchUserFeatureFlagsV1>>,
    { featureFlagDefinitionId: string; data: UserFeatureFlagSearchRequestV1 }
  > = (props) => {
    const { featureFlagDefinitionId, data } = props ?? {}

    return searchUserFeatureFlagsV1(featureFlagDefinitionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchUserFeatureFlagsV1MutationResult = NonNullable<
  Awaited<ReturnType<typeof searchUserFeatureFlagsV1>>
>
export type SearchUserFeatureFlagsV1MutationBody =
  UserFeatureFlagSearchRequestV1
export type SearchUserFeatureFlagsV1MutationError = ErrorType<ErrorResponse>

/**
 * @summary Search user feature flags
 */
export const useSearchUserFeatureFlagsV1 = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchUserFeatureFlagsV1>>,
    TError,
    { featureFlagDefinitionId: string; data: UserFeatureFlagSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchUserFeatureFlagsV1MutationOptions(options)

  return useMutation(mutationOptions)
}
