import { contentContainer } from '@cais-group/homepage/ui/components'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import type { Disclaimer as DisclaimerType } from '@cais-group/shared/util/graphql/mfe-contentful'

export const Disclaimer = ({
  title,
  body,
}: Pick<DisclaimerType, 'title' | 'body'>) => (
  <div
    className={`${contentContainer} mb-56`}
    data-testid={`${title}-disclaimer`}
  >
    <hr className="border-t-1 w-full border-solid border-neutral-200" />
    <h3 className="small-strong mb-8 w-full pt-56 text-neutral-900">{title}</h3>
    <RichText
      field={body}
      overrides={{
        p: {
          props: { className: 'text-neutral-600 small' },
        },
      }}
    />
  </div>
)
