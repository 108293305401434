import { match, P } from 'ts-pattern'

import {
  useGetFeaturedResearch,
  useGetFeaturedResearchContent,
} from '@cais-group/homepage/domain/contentful'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

import { FeaturedResearch } from './featured-research'

export function FeaturedResearchIndex() {
  const {
    pageData,
    error: featureError,
    loading: featureLoading,
  } = useGetFeaturedResearch()

  const content = useGetFeaturedResearchContent()

  // only throw page level error if all carousel content and featured content have errors

  const error =
    featureError &&
    content.press.error &&
    content.bands.every((item) => item.error === true)

  return (
    <Main>
      {match({ pageData, featureLoading, error })
        .with({ error: true }, () => <PageErrorSplash.General />)
        .with({ error: false, pageData: P.not(P.nullish) }, ({ pageData }) => (
          <>
            <DocumentTitle title="Featured Research" />
            <FeaturedResearch
              content={content}
              featured={pageData.featured}
              featureLoading={featureLoading}
              featureError={featureError}
            />
          </>
        ))
        .otherwise(() => null)}
    </Main>
  )
}
