import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import cx from 'classnames'

import { ButtonLink } from '@cais-group/equity/atoms/button'
import { Link } from '@cais-group/equity/atoms/link'
import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { prepareLinkProps } from '@cais-group/homepage/util/common'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { MaybeBodyEntry } from '@cais-group/shared/ui/contentful/rich-text-base'

import { CallToActionProps, ComponentSectionProps } from '../types'

import { SectionHeader as GridItemHeader } from './section-header'

const CallToAction = (props: CallToActionProps) => {
  const { title, buttonText, destination, description, classNames } = props
  const buttonLink = prepareLinkProps(destination)

  return (
    <div className={classNames?.marginLeft}>
      <header className="pb-24">
        <h2 className="headline-s-strong">{title}</h2>
      </header>
      <div
        className={`space-y-16 bg-neutral-100 p-32 text-neutral-900 ${classNames?.backgroundColor}`}
      >
        <p>{description}</p>

        {/**
         *  @todo HP-392  Below is likely not where we are navigating to but it's where the documents exist now
         */}
        <ButtonLink
          href={`${buttonLink?.href}`}
          openInNewWindow={buttonLink?.openInNewWindow}
          tracking={{
            content_type: 'Page',
            click_type: 'Content',
            item_name: buttonText || buttonLink?.text,
            sub_section: title || '',
          }}
        >
          {buttonText || buttonLink?.text}
        </ButtonLink>
      </div>
    </div>
  )
}

const ColTextContainer = (
  props: NonNullable<ComponentSectionProps['itemsCollection']> & {
    cols: number
    backgroundColor?: string
  }
) => {
  const { cols, items, backgroundColor } = props
  return (
    <div
      className={cx('grid gap-y-32 lg:gap-x-32', {
        'lg:grid-cols-2': cols === 2,
      })}
    >
      {items?.map((item, i) => {
        switch (item?.__typename) {
          case 'ComponentText': {
            //TODO HP-431  - Probably a better way to deal with ensuring the correct background and padding etc is applied Should we consider a new field from contentful to handle this?
            const colGridContainerclassName = cx({
              'lg:ml-auto max-w-prose': cols === 2 && i === 1,
            })

            const colGridItemClassName = cx({
              'bg-neutral-100 px-56 pt-8 pb-32': cols === 2 && i === 1,
              'space-y-24': cols === 2 && i === 0,
            })

            return (
              <div
                key={`${item.sys.id}-${i}`}
                className={colGridContainerclassName}
                {...mapTrackingKeysToDataAttributes({
                  sub_section: item.title || '',
                })}
              >
                <GridItemHeader title={item.title} />
                <div className={colGridItemClassName}>
                  <RichText
                    field={item?.text as MaybeBodyEntry}
                    renderNode={{
                      // TODO we can probably get rid of this first node override as we are using the same component for the header
                      [BLOCKS.HEADING_3]: (_node, children) => (
                        <header className="p-24">
                          <h2 className="headline-s-strong">{children}</h2>
                        </header>
                      ),
                      // TODO- HP-431 - seems like we are overriding often with the rich text component - I wonder if there is a simple way to just pass in a className to the rich text component and have it apply the correct styles
                      [BLOCKS.HEADING_5]: (_node, children) => (
                        <h5 className="overtext pt-24">{children}</h5>
                      ),
                      [INLINES.HYPERLINK]: (
                        node,
                        children: React.ReactNode
                      ) => (
                        <div className="py-12">
                          <Link href={node.data['uri']}>{children}</Link>
                        </div>
                      ),
                    }}
                  />
                </div>
              </div>
            )
          }
          case 'CallToAction': {
            const { description, title, destination, buttonText } = item
            const className = cx({
              'lg:ml-auto max-w-prose': cols === 2,
            })
            return (
              <CallToAction
                title={title || ''}
                description={description || ''}
                buttonText={buttonText || ''}
                destination={destination}
                key={item?.sys?.id}
                classNames={{
                  marginLeft: className,
                  backgroundColor: backgroundColor,
                }}
              />
            )
          }
          default:
            return null
        }
      })}
    </div>
  )
}

export const TextContainer = ({
  items,
  options,
}: {
  items: NonNullable<ComponentSectionProps['itemsCollection']>['items']
  options: {
    layout?: string
    backgroundColor?: string
  }
}) => {
  if (!items) {
    return null
  }
  switch (options.layout) {
    case '2col':
      return (
        <ColTextContainer
          items={items}
          cols={2}
          backgroundColor={options.backgroundColor}
        />
      )
    case '1col': {
      return (
        <ColTextContainer
          items={items}
          cols={1}
          backgroundColor={options.backgroundColor}
        />
      )
    }
    default:
      return (
        <ColTextContainer
          items={items}
          cols={2}
          backgroundColor={options.backgroundColor}
        />
      )
  }
}
