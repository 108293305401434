import type { ForwardedRef, PropsWithChildren } from 'react'
import { forwardRef } from 'react'

export const Cols3 = forwardRef(function Cols3(
  props: PropsWithChildren<{ testId?: string }>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const mobile =
    '[grid-template-areas:"sidebar2"_"sidebar1"_"main"] grid-cols-1'
  const tablet =
    'md:[grid-template-areas:"sidebar1_sidebar2"_"sidebar1_main"] md:grid-cols-[1fr,3fr]'
  const desktop =
    'lg:[grid-template-areas:"sidebar1_main_sidebar2"] lg:grid-cols-[2fr,7fr,3fr]'
  const { children, testId, ...rest } = props
  return (
    <div
      ref={ref}
      className={`grid gap-32 lg:gap-x-56 ${mobile} ${tablet} ${desktop}`}
      data-testid={testId}
      {...rest}
    >
      {children}
    </div>
  )
})

export function layoutCols3() {
  return {
    Layout: Cols3,
    slots: ['[grid-area:sidebar1]', '[grid-area:main]', '[grid-area:sidebar2]'],
  }
}
