import { useState } from 'react'
import { match, P } from 'ts-pattern'

import { useGetWebinars } from '@cais-group/homepage/domain/contentful'
import {
  topPaddedContentContainer,
  Main,
  PageErrorSplash,
} from '@cais-group/homepage/ui/components'
import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import { useMinimumDebouncedState } from '@cais-group/homepage/util/hook/use-minimum-debounced-state'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import { DocumentTitle } from '@cais-group/shared/util/document-title'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

import { HomepageSearchInput } from '../../../components/homepage-search-input'

import { Webinars } from './webinars'

export function WebinarsIndex() {
  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useMinimumDebouncedState(searchText, 600)
  const { pageData, error, loading } = useGetWebinars({
    date: today(),
    searchText: debouncedSearchText,
  })
  const hasFeatured = Boolean(pageData?.featured)

  const count = debouncedSearchText
    ? (pageData?.replays.length || 0) + (pageData?.upcoming.length || 0)
    : 0

  const size =
    (pageData?.replays?.length || pageData?.upcoming.length) &&
    hasFeatured &&
    debouncedSearchText
      ? 'medium'
      : (pageData?.replays?.length || pageData?.upcoming.length) && !hasFeatured
      ? 'small'
      : 'large'

  return (
    <>
      <ContentDisclaimer
        items={[
          ...(pageData?.replays || []),
          ...(pageData?.upcoming || []),
          pageData?.featured || ({} as ContentDataType),
        ]}
        className={topPaddedContentContainer}
      />
      <Main>
        {match({ error })
          .with(
            {
              error: false,
            },
            () => (
              <HomepageSearchInput
                searchText={searchText}
                onSearch={setSearchText}
                count={count}
                size={size}
              />
            )
          )
          .otherwise(() => null)}
        {match({ error, pageData, loading })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title="Webinars" />
                <Webinars
                  featured={pageData.featured}
                  upcoming={pageData.upcoming}
                  replays={pageData.replays}
                  debouncedSearchText={debouncedSearchText}
                  loading={loading}
                />
              </>
            )
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
