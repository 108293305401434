import type { PropsWithChildren } from 'react'

export const Letter = (props: PropsWithChildren<{ color?: string }>) => (
  <span
    className={`${
      props?.color || 'bg-primary-900'
    } text-neutral-0 grid aspect-square w-32 place-items-center`}
  >
    {props.children}
  </span>
)
