import { SearchInput } from '@cais-group/equity/atoms/text-input'

export function Search({
  searchText,
  onSearch,
  onClear,
}: {
  searchText: string
  onSearch: (text: string) => void
  onClear: () => void
}) {
  return (
    <SearchInput
      size="small"
      value={searchText}
      onChange={(event) => onSearch(event.target.value)}
      onClear={onClear}
      placeholder="Search by keyword"
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          onClear()
        }
      }}
    />
  )
}
