export enum APPS {
  ACCESS_MANAGER = 'Access Manager',
  ADS = 'ADS',
  CAIS_IQ = 'Cais IQ',
  CAIS_COMPASS = 'CAIS Compass',
  CONTAINER = 'Container',
  CUSTODIAN_MANAGER = 'Custodian Manager',
  DOCUMENT_LIBRARY = 'Document Library',
  FOCUS_PUBLIC_STRATEGIES = 'Focus Public Strategies',
  FUND_MANAGER = 'Fund Manager',
  FUNDS_PRE_TRADE = 'Funds Pre-Trade',
  FUNDS_PRODUCT_MASTER = 'Funds Product Master',
  FUNDS_TRADE = 'Funds Trade',
  HOME_OFFICE_INSIGHTS = 'Home Office Insights',
  HOMEPAGE = 'Homepage & Content',
  INVESTOR_PROFILE = 'Investor Profile',
  PRODUCT_MASTER = 'Product Master',
  STRUCTURED_PRODUCTS = 'Structured Products',
  SYSTEMS_MANAGER = 'Systems Manager',
  PRODUCT_PERMISSIONS = 'Product Permissions',
  MODEL_PORTFOLIO = 'Model Portfolio',
}

export enum APP_IDS {
  ACCESS_MANAGER = 'access-manager',
  ADS = 'ads',
  CAIS_IQ = 'cais-iq',
  CUSTODIAN_MANAGER = 'custodian-manager',
  CAIS_COMPASS = 'cais-compass',
  DOCUMENT_LIBRARY = 'document-library',
  FOCUS_PUBLIC_STRATEGIES = 'focus-public-strategies',
  FUND_MANAGER = 'fund-manager',
  FUNDS_TRADE = 'funds-trade',
  FUNDS_PRE_TRADE = 'funds-pre-trade',
  FUNDS_PRODUCT_MASTER = 'funds-product-master',
  HOME_OFFICE_INSIGHTS = 'home-office-insights',
  HOMEPAGE = 'homepage',
  INVESTOR_PROFILE = 'investor-profile',
  PRODUCT_MASTER = 'product-master',
  STRUCTURED_PRODUCTS = 'structured-investments',
  SYSTEMS_MANAGER = 'systems-manager',
  PRODUCT_PERMISSIONS = 'product-permissions',
  MODEL_PORTFOLIO = 'model-portfolios',
}

export const APP_PATHS = [
  'access-manager',
  'ads',
  'custodian-manager',
  'container',
  'document-library',
  'focus-public-strategies',
  'fund-manager',
  'funds-trade',
  'funds-pre-trade',
  'funds-product-master',
  'home-office-insights',
  'homepage',
  'investor-profile',
  'product-master',
  'structured-products',
  'structured-investments',
  'systems-manager',
  'product-permissions',
  'model-portfolios',
] as const

export const DEFEDERATED_APP_PATHS = [
  'systems-manager',
  'homepage',
  'fund-manager',
  'home-office-insights',
  'product-master',
  'investor-profile',
  'model-portfolios',
] as const
