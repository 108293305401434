import { ContentDisclaimer } from '@cais-group/homepage/ui/content-disclaimer'
import {
  FrontMatter,
  prepareFrontMatterProps,
} from '@cais-group/shared/ui/contentful/common/front-matter'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'
import { isBlankString } from '@cais-group/shared/util/js/common'
import type { TypeArticleData } from '@cais-group/shared/util/type/article-data'

import { Accordions } from './components/accordions'
import { CallToAction } from './components/call-to-action'
import { Footnotes } from './components/footnotes'
import { richTextIntroduction } from './components/introduction'
import { ArticleLayout } from './components/layout'
import { Sidebar } from './components/sidebar'

export function Article(props: TypeArticleData) {
  const {
    accordionsCollection,
    authorsCollection,
    callToAction,
    body,
    hero,
    introduction,
    introductionRichText,
    footnotes,
    urlPrefix,
    topicsCollection,
  } = props

  return (
    <ArticleLayout
      hero={
        hero?.url ? (
          <ContentfulImage
            priority
            background="auto"
            layout="fullWidth"
            src={hero.url}
            className="h-full"
          />
        ) : null
      }
      header={
        <FrontMatter
          {...prepareFrontMatterProps<TypeArticleData>(props)}
          eyebrow="Article"
        />
      }
      body={
        <>
          <ContentDisclaimer items={[props]} />
          {introductionRichText?.json ? (
            <RichText
              field={introductionRichText}
              renderNode={richTextIntroduction}
              urlPrefix={urlPrefix}
            />
          ) : (
            !isBlankString(introduction) && (
              <p className="headline-s">{introduction}</p>
            )
          )}
          {body?.json && <RichText field={body} urlPrefix={urlPrefix} />}
          {accordionsCollection && accordionsCollection.items.length > 0 ? (
            <Accordions accordions={accordionsCollection.items} />
          ) : null}
        </>
      }
      sidebar={
        <Sidebar
          tags={topicsCollection?.items}
          authors={authorsCollection?.items}
        />
      }
      footer={
        <>
          {callToAction && <CallToAction {...callToAction} />}
          {footnotes && footnotes.json && <Footnotes footnotes={footnotes} />}
        </>
      }
    />
  )
}
