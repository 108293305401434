import { isExternalLink } from '@cais-group/equity/atoms/link'
import type { IconType } from '@cais-group/equity/particles/icons'
import { APP_URL, prepareLinkProps } from '@cais-group/homepage/util/common'
import {
  ContentDataType,
  EventType,
  CuratedContentDataType,
} from '@cais-group/homepage/util/types'
import { cloudinaryUrl } from '@cais-group/shared/ui/cloudinary-image'
import {
  CaisiqVideo,
  CaisiqVideoPlaylist,
  ExternalContent,
  type Link,
  type Pdf,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { formatNumberWithCommas } from '@cais-group/shared/util/number'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import {
  GridItemProps,
  PageTypes,
  contentNames,
  externalContentNames,
  CardProps,
  type CtaAndIconType,
} from './types'

export const getCtaIcon = (
  props: {
    __typename?: string
    href?: string | null
  } | null
): IconType => {
  if (!props) {
    return 'ArrowRight'
  }
  if (
    props.__typename === 'ExternalContent' ||
    props.__typename === 'Event' ||
    props.__typename === 'Link'
  ) {
    if (isExternalLink(props.href || '')) {
      return 'Launch'
    }
  }
  if (props.__typename === 'Pdf') {
    return 'FileDownload'
  }
  return 'ArrowRight'
}

export const getIconType = (type: CtaAndIconType): IconType => {
  switch (type) {
    case 'Video':
    case 'CaisiqVideo':
    case 'CaisiqVideoPlaylist':
      return 'Video'
    case 'Event':
      return 'Event'
    case 'CmsPdf':
    case 'PDF':
    case 'Whitepaper':
      return 'PdfLibrary'
    case 'Fund':
      return 'Product'
    case 'Article':
    case 'CaisiqArticle':
    case 'CmsDocument':
    default:
      return 'Document'
  }
}
export const getContentName = (type: PageTypes | string) =>
  contentNames[type] ?? type

export const getType = (content: ContentDataType | CuratedContentDataType) =>
  isExternalContent(content) ? content.type : content.__typename

export const getPageType = (
  props: Pick<CardProps, '__typename' | 'eventType' | 'status' | 'type'>
) => {
  if (props.__typename === 'Event' && props.eventType) {
    return props.eventType
  } else if (props.__typename === 'ExternalContent' && props.type) {
    return externalContentNames[props.type]
  } else {
    return getContentName(props.__typename)
  }
}

// Helper function that returns 'CAIS' if content is owned by CAIS, otherwise the owner firm UUID.
export function getContentOwner<
  T extends {
    byCais?: boolean | null
    contentOwnershipTag?: {
      name?: string
      id?: string
    }
  }
>(item: T) {
  if (item.byCais === false) {
    return item?.contentOwnershipTag?.id
  }

  return 'CAIS'
}

// TODO need to handle pdf and link once queries are updated
export const getHref = (
  urlPrefix: string,
  contentType: ContentDataType | CuratedContentDataType
) => {
  const type = getContentName(contentType.__typename || '')
  /**
   * @todo HP - this is just temporary for now, until we get rid of homepage index
   * TODO HP-? consolidate link creation using page types in @cais-group/homepage/util/common
   */
  const href = `${urlPrefix}/${type.toLowerCase()}s/`

  if (isPdf(contentType)) {
    return contentType?.pdf?.url
  }

  if (isLink(contentType)) {
    return prepareLinkProps(contentType)?.href
  }

  if (isExternalContent(contentType)) {
    const externalType = contentType.type
    if (externalType === 'Fund') {
      return `/funds-pre-trade/${contentType.type?.toLowerCase()}s/${
        contentType.externalId
      }`
    }
    return contentType.externalLink ?? APP_URL
  }

  if (isArticle(contentType) || isWhitepaper(contentType)) {
    return `${href}${contentType.slug}`
  }

  if (isVideo(contentType)) {
    return `${href}${contentType.sys.id}`
  }

  if (isVideoPlaylist(contentType)) {
    const firstVideo = contentType.videosCollection?.items?.[0]
    return `${href}${contentType.sys.id}/${firstVideo?.sys.id}`
  }
  return ''
}

export const DEFAULT_CTA_TEXT = 'Read More'

export const ctaText: Record<CtaAndIconType, string> = {
  CaisiqArticle: DEFAULT_CTA_TEXT,
  CaisiqVideo: 'Watch Now',
  CaisiqVideoPlaylist: 'Watch Now',
  Event: 'Register Now',
  Event_Webinar: 'Register Now',
  'Event_In-Person_Almost-full': 'Register Now',
  'Event_In-Person_Open': 'Register Now',
  'Event_In-Person_Upcoming': 'Coming Soon',
  'Event_In-Person_Full': DEFAULT_CTA_TEXT,
  Whitepaper: DEFAULT_CTA_TEXT,
  Fund: 'View More',
  Video: 'Watch Now',
  PDF: DEFAULT_CTA_TEXT,
  Article: DEFAULT_CTA_TEXT,
  CmsDocument: 'Download',
  CmsPdf: 'Download',
  Pdf: 'Download',
  ComponentText: '',
} as const

export const getCtaText = (type: CtaAndIconType) =>
  ctaText[type] || DEFAULT_CTA_TEXT

export function posterOrThumbnail(item?: CaisiqVideo | null) {
  if (
    Array.isArray(item?.poster) &&
    item?.poster.length &&
    cloudinaryUrl(item.poster[0].public_id)
  ) {
    return {
      ...item.poster[0],
      url: item.poster[0].public_id,
    }
  }
  return item?.thumbnail
}

export const getThumbnail = (
  contentType: ContentDataType | ExternalContent
) => {
  if (isVideoPlaylist(contentType)) {
    const video = contentType.videosCollection?.items?.[0]
    return posterOrThumbnail(video)
  } else if (isEvent(contentType)) {
    return contentType.eventThumbnail
  } else if (isVideo(contentType)) {
    return posterOrThumbnail(contentType)
  }

  return contentType.thumbnail
}

export function isVideo(
  contentType: ContentDataType | CuratedContentDataType
): contentType is CaisiqVideo {
  return contentType.__typename === 'CaisiqVideo'
}

export function isWhitepaper(
  contentType: ContentDataType | CuratedContentDataType
): contentType is WhitepaperData {
  return contentType.__typename === 'Whitepaper'
}

export function isArticle(
  contentType: ContentDataType | CuratedContentDataType
): contentType is TypeArticleData {
  return contentType.__typename === 'CaisiqArticle'
}

export function isVideoPlaylist(
  contentType: ContentDataType | CuratedContentDataType
): contentType is CaisiqVideoPlaylist {
  return contentType.__typename === 'CaisiqVideoPlaylist'
}

export function isExternalContent(
  contentType: ContentDataType | CuratedContentDataType
): contentType is ExternalContent {
  return contentType.__typename === 'ExternalContent'
}

export function isEvent(
  contentType: ContentDataType | CuratedContentDataType
): contentType is EventType {
  return contentType.__typename === 'Event'
}

export function isPdf(
  contentType: ContentDataType | CuratedContentDataType
): contentType is Pdf {
  return contentType.__typename === 'Pdf'
}

export function isLink(
  contentType: ContentDataType | CuratedContentDataType
): contentType is Link {
  return contentType.__typename === 'Link'
}

export function getExternalDescriptionDetails(
  props: ExternalContent
): GridItemProps[] | null {
  const { strategy, subStrategy, minimumInitialInvestment, eligibility, type } =
    props
  if (type === 'Fund') {
    return [
      { label: 'Strategy', description: strategy },
      {
        label: 'Sub Strategy',
        description: subStrategy,
      },
      {
        label: 'Minimum Investment',
        description: `$${formatNumberWithCommas(
          minimumInitialInvestment || 0
        )}`,
      },
      {
        label: 'Eligibility',
        description: eligibility,
      },
    ]
  }
  return null
}

export type ContentOwnershipTag = {
  name: string
  id: string
}

export const getContentOwnershipTag = (
  item: ContentDataType | CuratedContentDataType
) => {
  const hasFirmPermissionRequirement = Boolean(
    item.firmsCollection?.items.length && !item.byCais
  )

  return hasFirmPermissionRequirement
    ? item.firmsCollection?.items[0]
    : undefined
}

function upperCaseWords(str: string) {
  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ')
}

// Elsewhere within the MFE apps, we have the following for the same data we will be using this on

export function toMapping(mapping: Record<string, string>) {
  return Object.values(mapping).reduce((acc, value) => {
    acc[value] = upperCaseWords(value)
    return acc
  }, {} as Record<string, string>)
}
