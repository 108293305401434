import { filterAllowedPermissionData } from '@cais-group/homepage/domain/contentful'
import type { ContentPermissionsData } from '@cais-group/shared/domain/contentful/api'

import { canShowAnnouncement } from './helpers'
import type { AnnouncementSet } from './use-get-announcements'

const HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE =
  'HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE'
const HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION =
  'HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION'

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm'

export type DismissedAnnouncementsStorage = {
  announcement: Record<
    string,
    {
      dismissed: boolean
      dateTimeEnd: string
    }
  >
  allowSingleDismissalPerSession?: boolean
}
let _data: AnnouncementSet | undefined
let _permissions: Pick<ContentPermissionsData, 'firmIds' | 'fundProductIds'>
let _userId: string

export const announcementsService = {
  initiateAnnouncements: (
    permissions: Pick<ContentPermissionsData, 'firmIds' | 'fundProductIds'>,
    data?: AnnouncementSet,
    userId?: string
  ) => {
    _permissions = permissions
    _data = data
    _userId = userId || 'unknown-user'
  },
  get announcementsDismissed() {
    return JSON.parse(
      localStorage.getItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`
      ) || '{}'
    ) as DismissedAnnouncementsStorage['announcement']
  },
  get announcementDefault() {
    return _data?.announcementDefault &&
      canShowAnnouncement(
        _data.announcementDefault.dateTimeStart,
        _data.announcementDefault.dateTimeEnd,
        _data.announcementDefault.sys.id
      )
      ? _data.announcementDefault
      : null
  },
  get announcementOptions() {
    return _data?.announcementOptionsCollection
      ? filterAllowedPermissionData(
          _data.announcementOptionsCollection,
          _permissions
        )
      : []
  },
  get announcement() {
    const { announcementDefault, announcementOptions } = announcementsService

    const optionalAnnouncement = announcementOptions.find(
      ({ dateTimeEnd, dateTimeStart, sys }) =>
        canShowAnnouncement(dateTimeStart, dateTimeEnd, sys.id)
    )

    return optionalAnnouncement || announcementDefault
  },
  get hasDismissed() {
    return JSON.parse(
      localStorage.getItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION}-${_userId}`
      ) || 'false'
    ) as DismissedAnnouncementsStorage['allowSingleDismissalPerSession']
  },
  setAnnouncementsDismissed({
    announcement,
    allowSingleDismissalPerSession,
  }: DismissedAnnouncementsStorage) {
    const { announcementsDismissed } = announcementsService
    localStorage.setItem(
      `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`,
      JSON.stringify({ ...announcementsDismissed, ...announcement })
    )
    if (allowSingleDismissalPerSession) {
      localStorage.setItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION}-${_userId}`,
        JSON.stringify(allowSingleDismissalPerSession)
      )
    }
  },
  removeAnnouncementDismissed(id: string) {
    const { announcementsDismissed } = announcementsService
    delete announcementsDismissed[id]
    localStorage.setItem(
      `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`,
      JSON.stringify(announcementsDismissed)
    )
  },
  clearHasDismissed() {
    localStorage.removeItem(
      `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION}-${_userId}`
    )
  },
}
