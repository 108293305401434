import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import {
  GetFaQsQuery,
  useGetFaQsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export type FAQType = NonNullable<GetFaQsQuery['faqCollection']>['items'][0]

export type FAQsBySearch = {
  faqs: NonNullable<Exclude<FAQType, 'id'> & { id: string }>[]
}

export const useGetFAQs = (searchTerm?: string) => {
  const response = useReactQueryResultAsApiState<GetFaQsQuery, FAQsBySearch>(
    useGetFaQsQuery(
      {
        searchTerm,
      },
      {
        enabled: Boolean(searchTerm),
        refetchOnWindowFocus: false,
      }
    ),
    selectFAQs,
    'Could not load FAQs by search term'
  )
  return compileResults(response)
}

const compileResults = (data: FAQsBySearch | ApiError | ApiStateEnum) => {
  return {
    pageData: isData(data) ? data : null,
    error: isError(data),
    loading: data === ApiStateEnum.LOADING,
  }
}
const selectFAQs = (data: GetFaQsQuery) =>
  ({
    faqs: data.faqCollection?.items,
  } as FAQsBySearch)
