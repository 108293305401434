import { avatarStyles } from './shared'

export function CaisAvatar() {
  return (
    <span
      className={`${avatarStyles} bg-accent-700 grid min-h-[56px] place-items-center`}
    >
      <span className="small-strong text-neutral-0 tracking-[2px]">CAIS</span>
    </span>
  )
}
