import cx from 'classnames'

import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { contentContainer } from '@cais-group/homepage/ui/components'
import type { HomepageContentSection } from '@cais-group/shared/util/graphql/mfe-contentful'

import { ComponentSection } from './components/component-section'
import type { ComponentSectionProps } from './types'

export type LandingPageComponentProps = {
  section: HomepageContentSection
}

export function LandingPageComponents({ section }: LandingPageComponentProps) {
  if (!section) {
    return null
  }

  const sectionId = section.sys.id || 'unknown'
  const removeTopPadding = section.removeTopPadding

  return (
    <section
      key={sectionId}
      className={cx('bg-neutral-0 py-56 [&:nth-child(3)]:bg-neutral-100', {
        'pt-0': removeTopPadding,
      })}
      {...mapTrackingKeysToDataAttributes({
        sub_section: (section as ComponentSectionProps).title || sectionId,
      })}
    >
      <div className={contentContainer}>
        <ComponentSection
          key={`${sectionId}-ComponentSection`}
          {...(section as ComponentSectionProps)}
        />
      </div>
    </section>
  )
}
