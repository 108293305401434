import { InfoTag } from '@cais-group/equity/atoms/info-tag'
import type { TopicFragment } from '@cais-group/shared/util/graphql/mfe-contentful'

type TagProps = TopicFragment

export type TagsProps = Array<TagProps>

export function TagCollection({ tags }: { tags: TagsProps }) {
  return tags?.length > 0 ? (
    <div className="space-y-32">
      <p className="small flex items-center gap-x-24 border-y border-neutral-200 py-16 tracking-widest text-neutral-600">
        Tags
      </p>
      <ol className="flex flex-wrap items-center gap-8">
        {tags.map((tag) => {
          return tag.label ? (
            <li key={tag.id || tag.label}>
              <InfoTag size="regular">{tag.label}</InfoTag>
            </li>
          ) : null
        })}
      </ol>
    </div>
  ) : null
}
