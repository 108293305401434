import { CollapsibleSection } from '@cais-group/equity/labs/disclosure'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import type { RichTextBaseProps } from '@cais-group/shared/ui/contentful/rich-text-base'

import type { ComponentSectionProps } from '../types'

// TODO HP-? try and avoid casting

// TODO HP-393 create a reusable type?
type ContainerProps = {
  items?: NonNullable<ComponentSectionProps['itemsCollection']>['items']
}

export function CollapsibleSectionContainer({ items = [] }: ContainerProps) {
  const itemsSplit5050 = [
    items.slice(0, Math.ceil(items.length / 2)),
    items.slice(Math.ceil(items.length / 2)),
  ]

  return (
    <div className="grid gap-16 md:gap-24 lg:grid-cols-2 lg:gap-32">
      {itemsSplit5050.map((col, index) => (
        <div
          key={`col-${index + 1}`}
          className="space-y-16 md:space-y-24 lg:space-y-32"
        >
          {col.map((item) => renderAccordionItem(item))}
        </div>
      ))}
    </div>
  )
}

type Item = NonNullable<ContainerProps['items']>[0]

function renderAccordionItem(item: Item) {
  if (item?.__typename === 'ComponentText') {
    return (
      <CollapsibleSection key={item?.sys.id} title={item.title || ''}>
        {item.text ? (
          <div className="p-24">
            <RichText field={item.text as RichTextBaseProps['field']} />
          </div>
        ) : null}
      </CollapsibleSection>
    )
  }
  return null
}
