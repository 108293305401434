import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetHomepageLandingPageQuery,
  HomepageLandingPage,
  useGetHomepageLandingPageQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export const useGetHomepageLandingPage = ({ slug }: { slug: string }) => {
  const result = useReactQueryResultAsApiState<
    GetHomepageLandingPageQuery,
    HomepageLandingPage
  >(
    useGetHomepageLandingPageQuery(
      {
        preview: previewService.enabled,
        slug: slug,
      },
      {
        refetchOnWindowFocus: false,
      }
    ),
    selectLandingPageResource,
    'Could not load homepage landing page'
  )
  return compileResults(
    result,
    result === ApiStateEnum.LOADING,
    isError(result)
  )
}
const selectLandingPageResource = (data: GetHomepageLandingPageQuery) =>
  data?.homepageLandingPageCollection?.items?.[0] as HomepageLandingPage

const compileResults = (
  data: HomepageLandingPage | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}
