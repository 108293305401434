export const AToZ = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
export const AtoZNavGroups = [
  {
    start: 'A',
    stop: 'E',
  },
  {
    start: 'F',
    stop: 'J',
  },
  {
    start: 'K',
    stop: 'O',
  },
  {
    start: 'P',
    stop: 'T',
  },
  {
    start: 'U',
    stop: 'Z',
  },
]
export const Prefix = 'aToZ'
