import cx from 'classnames'
import { ReactNode } from 'react'

const styles = {
  base: {
    rows: 'grid-rows-[var(--row-spacer-m)_auto_calc(var(--row-spacer-l))_auto_auto]',
    variables:
      '[--gutter:32px] [--body-width:100%_-_2_*_var(--gutter)] [--max-width:1660px] [--min-width:256px] [--row-spacer-m:24px] [--row-spacer-l:56px]',
  },
  singleColumn: {
    areas:
      '[grid-template-areas:"._._."_"._header_."_"._._."_"._body_."_"._sidebar_."_"._footer_."]',
    cols: 'grid-cols-[1fr_clamp(var(--min-width),var(--body-width),var(--max-width))_1fr]',
  },
  multiColumn: {
    areas:
      'lg:[grid-template-areas:"._._._._."_"._._._header_."_"._._._._."_"._sidebar_._body_."_"._sidebar_._footer_."]',
    cols: 'lg:grid-cols-[1fr_var(--sidebar-width)_var(--col-spacer)_clamp(var(--min-width),calc(clamp(var(--min-width),var(--body-width),var(--max-width))_-_calc(var(--sidebar-width)_+_var(--col-spacer)_+_2_*_var(--gutter))),1150px)_1fr]',
    variables: 'lg:[--sidebar-width:295px] lg:[--col-spacer:88px]',
  },
  richText: 'space-y-32 max-w-prose [&>:is(h2,h3,figure)]:!mt-56',
  // page-resource-body and page-resource-intro should only be used in the PageResource component as the spacing is specific to that component
  pageResource:
    '[&_.page-resource-intro_:is(p,h1,h2,h3,h4,h5,h6):first-child]:!mb-56 [&>.page-resource-body_p:first-of-type]:!mb-32 [&>.page-resource-body_p:first-of-type]:!mt-16  [&_:is(.page-resource-body,.page-resource-intro)_a]:!my-8 [&_:is(.page-resource-intro)]:!mb-56',
}

const baseStyles = `${styles.base.rows} ${styles.base.variables}`
const singleColumnStyles = `${styles.singleColumn.areas} ${styles.singleColumn.cols}`
const multiColumnStyles = `${styles.multiColumn.areas} ${styles.multiColumn.cols} ${styles.multiColumn.variables}`

export type ArticleLayoutProps = {
  body: ReactNode
  header: ReactNode
  sidebar?: ReactNode
  footer?: ReactNode
  hero?: ReactNode
}

export function ArticleLayout(props: ArticleLayoutProps) {
  const { body, footer, header, hero, sidebar } = props
  return (
    <article
      className={cx(
        'pb-88 isolate grid gap-0',
        `${baseStyles} ${singleColumnStyles} ${multiColumnStyles}`,
        {
          'article-hero': Boolean(props.hero),
        }
      )}
    >
      {header ? (
        <div
          className="[.article-hero_&]:pt-144 [.article-hero_&_:is(h1,p)]:text-neutral-0 flex items-end [grid-area:header]"
          data-testid="header"
        >
          {header}
        </div>
      ) : null}

      {body ? (
        <div
          className={`[grid-area:body] [&>:first-child:is(section)]:mb-56 ${styles.richText} ${styles.pageResource}`}
          data-testid="body"
        >
          {body}
        </div>
      ) : null}

      {sidebar ? (
        <div
          className="max-w-prose pt-56 [grid-area:sidebar] lg:pt-0"
          data-testid="sidebar"
        >
          {sidebar}
        </div>
      ) : null}

      {footer ? (
        <div
          className="max-w-prose space-y-56 pt-56 [grid-area:footer]"
          data-testid="footer"
        >
          {footer}
        </div>
      ) : null}

      {hero ? (
        <div
          className="relative z-[-1] col-span-full row-start-1 row-end-3"
          data-testid="hero"
        >
          {hero}
        </div>
      ) : null}
    </article>
  )
}
