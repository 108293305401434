import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useGetWhitepaperBySlug } from '@cais-group/homepage/domain/contentful'
import { Main, PageErrorSplash } from '@cais-group/homepage/ui/components'
import { APP_URL } from '@cais-group/homepage/util/common'
import { WithAugmentedPageTracking } from '@cais-group/homepage/util/tracking'
import { Whitepaper } from '@cais-group/shared/ui/contentful/article'
import { ContentfulBreadcrumbs as Breadcrumbs } from '@cais-group/shared/ui/contentful/breadcrumb'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

export function Whitepapers() {
  const { slug } = useParams<{ slug: string }>()
  const { pageData, error, loading, existsWithNoAccess, notFound } =
    useGetWhitepaperBySlug(slug)

  const getFundProductIds = useCallback(
    () => ({
      fund_ids: pageData?.fundProductIds?.fundProductIds,
    }),
    [pageData]
  )

  return (
    <>
      {match({ pageData, error, existsWithNoAccess, notFound })
        .with(
          {
            error: false,
            existsWithNoAccess: false,
            notFound: false,
            pageData: P.not(P.nullish),
          },
          ({ pageData }) => (
            <Breadcrumbs
              baseUrl={APP_URL}
              category={pageData?.category || ''}
              currentPage={pageData?.title}
            />
          )
        )
        .otherwise(() => null)}

      <Main fullWidth>
        {match({ pageData, error, loading, existsWithNoAccess, notFound })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with({ loading: true, error: false }, () => (
            <LoadingContainer
              state={LoadingState.LOADING}
              type="large"
              coverPage="FULL_SCREEN_WITH_HEADER"
            />
          ))
          .with({ notFound: true, loading: false }, () => (
            <PageErrorSplash.NotReached />
          ))
          .with({ existsWithNoAccess: true, loading: false }, () => (
            <PageErrorSplash.NoAccess />
          ))

          .with(
            { error: false, pageData: P.not(P.nullish) },
            ({ pageData }) => (
              <>
                <DocumentTitle title={pageData.title} />
                <WithAugmentedPageTracking getData={getFundProductIds} />
                <Whitepaper {...pageData} urlPrefix={APP_URL} />
              </>
            )
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
