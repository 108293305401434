import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetGlossaryQuery,
  useGetGlossaryQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import type { GlossaryEntryType } from './types'

export const useGetGlossary = () => {
  const response = useReactQueryResultAsApiState<
    GetGlossaryQuery,
    GlossaryEntryType[]
  >(
    useGetGlossaryQuery(
      { preview: previewService.enabled },
      { refetchOnWindowFocus: false }
    ),
    selectGlossary,
    'Could not load "getGlossary query"'
  )
  const loading = response === ApiStateEnum.LOADING
  const error = isError(response)

  return compileResults(response, loading, error)
}

const compileResults = (
  data: GlossaryEntryType[] | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

export const selectGlossary = (data: GetGlossaryQuery) =>
  (data.glossaryTermCollection?.items ?? []) as GlossaryEntryType[]
